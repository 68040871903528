import { PropsWithoutRef } from "react";
import { Link, LinkProps } from "react-router-dom";

const mobileMenuLinkClassNames =
  "flex items-center text-left w-full px-3 py-2 text-base font-medium text-gray-900 rounded-md hover:bg-gray-100";

export const MobileNavLink = ({
  children,
  ...props
}: Omit<LinkProps, "className">) => (
  <Link {...props} className={mobileMenuLinkClassNames}>
    {children}
  </Link>
);

export const MobileNavButton = ({
  children,
  ...props
}: Omit<PropsWithoutRef<JSX.IntrinsicElements["button"]>, "className">) => (
  <button {...props} className={mobileMenuLinkClassNames}>
    {children}
  </button>
);
