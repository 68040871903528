import { forwardRef, PropsWithoutRef } from "react";
import { FieldProps } from "types";
import { FieldGroup } from "./FieldGroup";

export type TextAreaFieldProps = Omit<
  PropsWithoutRef<JSX.IntrinsicElements["textarea"]>,
  "className" | "children"
> &
  FieldProps;

export const TextAreaField = forwardRef<HTMLTextAreaElement, TextAreaFieldProps>(
  ({ required, optional, ...props }, ref) => (
    <FieldGroup required={required} optional={optional} {...props}>
      {(computedProps) => (
        <textarea ref={ref} {...computedProps} {...props} />
      )}
    </FieldGroup>
  )
);
