import { Fragment, ReactNode, Suspense, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";

import {
  CalendarIcon,
  ClockIcon,
  CogIcon,
  LogoutIcon,
  MenuIcon,
  UserIcon,
  XIcon,
} from "@heroicons/react/outline";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";
import { Placeholder } from "./Placeholder";
import { AccountDropdownMenu } from "./AccountDropdownMenu";
import { MobileNavLink } from "./MobileNavLink";
import { AccountMobileNav } from "./AccountMobileNav";
import { TeamDropdownMenu } from "./TeamDropdownMenu";
import { TeamMobileNav } from "./TeamMobileNav";
import { InvitationsLink } from "./InvitationsLink";
import { VerifyEmail } from "./VerifyEmail";
import { useUser } from "hooks/use-user";
import { useLogout } from "hooks/use-logout";
import { LoadingSpinner } from "./LoadingSpinner";

const MobileNavSection = ({ children }: { children: ReactNode }) => (
  <div className="px-2 py-3 sm:px-4">{children}</div>
);

const MobileNavDivider = () => <div className="border-t border-gray-200" />;

const LogoutButton = () => {
  const { logoutMutation } = useLogout();

  useUser();

  return (
    <button
      onClick={() => logoutMutation.mutateAsync()}
      className="inline-flex items-center justify-center flex-shrink-0 text-gray-400 rounded-lg h-14 w-14 hover:bg-gray-700 focus:outline-none"
    >
      <LogoutIcon className="w-6 h-6" aria-hidden="true" />
    </button>
  );
};

export const Layout = ({ children }: { children: ReactNode }) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const { t } = useTranslation();

  const sidebarNavigation = [
    { name: t("Calendar"), href: "/calendar", icon: CalendarIcon },
    { name: t("Clients"), href: "/clients", icon: UserIcon },
  ];

  const secondarySidebarNavigation = [
    { name: t("Schedule"), href: "/schedule", icon: ClockIcon },
    { name: t("Team Settings"), href: "/teams/edit", icon: CogIcon },
  ];

  const location = useLocation();

  return (
    <div className="flex flex-col h-screen overflow-hidden bg-gray-100">
      <Suspense fallback={null}>
        <VerifyEmail />
      </Suspense>
      <header className="relative flex items-center flex-shrink-0 h-16 bg-white">
        <div className="absolute inset-y-0 left-0 md:static md:flex-shrink-0">
          <a
            href="/"
            className="flex items-center justify-center w-16 h-16 bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-600 md:w-20"
          >
            <img
              className="w-auto h-8"
              src="https://tailwindui.com/img/logos/workflow-mark.svg?color=white"
              alt="Workflow"
            />
          </a>
        </div>

        <div className="absolute inset-y-0 right-0 flex items-center pr-4 sm:pr-6 md:hidden">
          <button
            type="button"
            className="inline-flex items-center justify-center p-2 -mr-2 text-gray-400 rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-600"
            onClick={() => setMobileMenuOpen(true)}
          >
            <MenuIcon className="block w-6 h-6" aria-hidden="true" />
          </button>
        </div>

        <div className="hidden md:min-w-0 md:flex-1 md:flex md:items-center md:justify-end">
          <div className="flex items-center flex-shrink-0 pr-4 ml-10 space-x-10">
            <div className="flex items-center space-x-8">
              <Suspense
                fallback={
                  <>
                    <Placeholder widthClassName="w-32" heightClassName="h-8" />
                    <Placeholder widthClassName="w-32" heightClassName="h-8" />
                  </>
                }
              >
                <InvitationsLink />
                <TeamDropdownMenu />
                <AccountDropdownMenu />
              </Suspense>
            </div>
          </div>
        </div>

        <Transition.Root show={mobileMenuOpen} as={Fragment}>
          <Dialog
            as="div"
            static
            className="fixed inset-0 z-40 md:hidden"
            open={mobileMenuOpen}
            onClose={setMobileMenuOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="hidden sm:block sm:fixed sm:inset-0 sm:bg-gray-600 sm:bg-opacity-75" />
            </Transition.Child>

            <Transition.Child
              as={Fragment}
              enter="transition ease-out duration-150 sm:ease-in-out sm:duration-300"
              enterFrom="transform opacity-0 scale-110 sm:translate-x-full sm:scale-100 sm:opacity-100"
              enterTo="transform opacity-100 scale-100  sm:translate-x-0 sm:scale-100 sm:opacity-100"
              leave="transition ease-in duration-150 sm:ease-in-out sm:duration-300"
              leaveFrom="transform opacity-100 scale-100 sm:translate-x-0 sm:scale-100 sm:opacity-100"
              leaveTo="transform opacity-0 scale-110  sm:translate-x-full sm:scale-100 sm:opacity-100"
            >
              <nav
                className="fixed inset-0 z-40 w-full h-full overflow-auto bg-white sm:inset-y-0 sm:left-auto sm:right-0 sm:max-w-sm sm:w-full sm:shadow-lg"
                aria-label="Global"
              >
                <div className="flex items-center justify-between h-16 px-4 sm:px-6">
                  <a href="/">
                    <img
                      className="block w-auto h-8"
                      src="https://tailwindui.com/img/logos/workflow-mark.svg?color=indigo&shade=500"
                      alt="Workflow"
                    />
                  </a>
                  <button
                    type="button"
                    className="inline-flex items-center justify-center p-2 -mr-2 text-gray-400 rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-600"
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    <XIcon className="block w-6 h-6" aria-hidden="true" />
                  </button>
                </div>
                <MobileNavSection>
                  {sidebarNavigation.map((item) => (
                    <MobileNavLink
                      key={item.name}
                      to={item.href}
                      onClick={() => setMobileMenuOpen(false)}
                    >
                      {item.name}
                    </MobileNavLink>
                  ))}
                </MobileNavSection>
                <MobileNavDivider />
                <MobileNavSection>
                  <Suspense
                    fallback={
                      <div className="flex flex-col px-4 space-y-4 sm:px-6">
                        <Placeholder
                          widthClassName="w-full"
                          heightClassName="h-8"
                        />
                        <Placeholder
                          widthClassName="w-32"
                          heightClassName="h-6"
                        />
                        <Placeholder
                          widthClassName="w-20"
                          heightClassName="h-6"
                        />
                      </div>
                    }
                  >
                    <AccountMobileNav
                      onNavigate={() => setMobileMenuOpen(false)}
                    />
                  </Suspense>
                </MobileNavSection>
                <MobileNavDivider />
                <MobileNavSection>
                  <Suspense
                    fallback={
                      <div className="flex flex-col px-4 space-y-4 sm:px-6">
                        <Placeholder
                          widthClassName="w-full"
                          heightClassName="h-8"
                        />
                        <Placeholder
                          widthClassName="w-32"
                          heightClassName="h-6"
                        />
                        <Placeholder
                          widthClassName="w-20"
                          heightClassName="h-6"
                        />
                      </div>
                    }
                  >
                    <TeamMobileNav
                      onNavigate={() => setMobileMenuOpen(false)}
                    />
                  </Suspense>
                </MobileNavSection>
              </nav>
            </Transition.Child>
          </Dialog>
        </Transition.Root>
      </header>

      <div className="flex flex-1 min-h-0 overflow-hidden">
        <nav
          aria-label="Sidebar"
          className="hidden md:block md:flex-shrink-0 md:bg-gray-800 md:overflow-y-auto"
        >
          <div className="flex flex-col justify-between h-full space-y-3">
            <div className="relative flex flex-col w-20 p-3 space-y-3">
              {sidebarNavigation.map((item) => (
                <NavLink
                  key={item.name}
                  to={item.href}
                  className={classNames(
                    location.pathname.startsWith(item.href)
                      ? "bg-gray-900 text-white"
                      : "text-gray-400 hover:bg-gray-700",
                    "flex-shrink-0 inline-flex items-center justify-center h-14 w-14 rounded-lg"
                  )}
                >
                  <item.icon className="w-6 h-6" aria-hidden="true" />
                </NavLink>
              ))}
            </div>
            <div className="relative flex flex-col w-20 p-3 space-y-3">
              {secondarySidebarNavigation.map((item) => (
                <NavLink
                  key={item.name}
                  to={item.href}
                  className={classNames(
                    location.pathname.startsWith(item.href)
                      ? "bg-gray-900 text-white"
                      : "text-gray-400 hover:bg-gray-700",
                    "flex-shrink-0 inline-flex items-center justify-center h-14 w-14 rounded-lg"
                  )}
                >
                  <item.icon className="w-6 h-6" aria-hidden="true" />
                </NavLink>
              ))}
              <Suspense
                fallback={
                  <div className="inline-flex items-center justify-center flex-shrink-0 h-14 w-14">
                    <LoadingSpinner className="w-4 h-4 text-white" />
                  </div>
                }
              >
                <LogoutButton />
              </Suspense>
            </div>
          </div>
        </nav>

        <main className="flex-1 min-w-0 overflow-auto border-t border-gray-200">
          {children}
        </main>
      </div>
    </div>
  );
};
