import { Unauthenticated } from "errors/Unauthenticated";
import { Unauthorized } from "errors/Unauthorized";
import { NotFound as NotFoundException } from "errors/NotFound";
import { useLogout } from "hooks/use-logout";
import { ReactNode, useEffect } from "react";
import { FallbackProps } from "react-error-boundary";
import { useTranslation } from "react-i18next";
import { Button } from "./Button";
import { NotFound } from "./NotFound";

const Wrapper = ({ children }: { children: ReactNode }) => (
  <div className="fixed inset-0 flex flex-col items-center justify-center w-full h-full">
    {children}
  </div>
);

export const ErrorBoundary = ({ resetErrorBoundary, error }: FallbackProps) => {
  const { t } = useTranslation();

  const { redirectToLogin } = useLogout();

  useEffect(() => {
    if (error instanceof Unauthenticated) {
      redirectToLogin();
    }
  }, [error, redirectToLogin]);

  if (error instanceof Unauthenticated) {
    return null;
  }

  const getTitle = (error: Error) => {
    if (error instanceof Unauthorized) {
      return t("You are not allowed to perform this action.");
    }

    return t("Something went wrong.");
  };

  if (error instanceof NotFoundException) {
    return (
      <Wrapper>
        <NotFound resetErrorBoundary={resetErrorBoundary} />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <h3 className="mb-2 text-lg font-medium text-gray-900">
        {getTitle(error)}
      </h3>
      <Button variant="white" onClick={resetErrorBoundary}>
        {t("Try again")}
      </Button>
    </Wrapper>
  );
};
