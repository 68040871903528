import { NotFound } from "errors/NotFound";
import { Unauthenticated } from "errors/Unauthenticated";
import { Unauthorized } from "errors/Unauthorized";
import { ValidationFailed } from "errors/ValidationFailed";
import { CreateApiClient } from "types";

export const createApiClient: CreateApiClient =
  (resolver) => async (options) => {
    try {
      const { data, status, statusText, headers } = await resolver({
        ...options,
        url: process.env.REACT_APP_API_URL + options?.url,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          ...options?.headers,
        },
      });

      return { data, status, statusText, headers };
    } catch (error) {
      switch (error?.response?.status) {
        case 401: {
          throw new Unauthenticated();
        }
        case 403: {
          throw new Unauthorized();
        }
        case 404: {
          throw new NotFound();
        }
        case 422: {
          throw new ValidationFailed(error?.response?.data?.errors || {});
        }
        default: {
          throw error;
        }
      }
    }
  };
