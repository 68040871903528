import { Card } from "components/Card";
import { LoadingTableHeader } from "components/LoadingTableHeader";
import { NegateCardPadding } from "components/NegateCardPadding";
import { Pagination } from "components/Pagination";
import { Table } from "components/Table";
import { TableCell } from "components/TableCell";
import { usePagination } from "hooks/use-pagination";
import { useContextService } from "hooks/use-context-service";
import { useUser } from "hooks/use-user";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { CellProps } from "react-table";
import currency from "currency.js";
import { getTransactions } from "../services/transactions";
import { Badge } from "components/Badge";
import { Link } from "react-router-dom";
import { getTextButtonClassNames } from "components/TextButton";
import { format, parseISO } from "date-fns";

export const TRANSACTIONS_QUERY = "transactions";

export const TransactionsTable = () => {
  const { page, nextPage, previousPage } = usePagination();

  const getTransactionsService = useContextService(getTransactions);

  const { data: userResponse } = useUser();

  const {
    data: transactionsResponse,
    isFetching,
    isPreviousData,
  } = useQuery(
    [TRANSACTIONS_QUERY, userResponse?.data?.data?.currentTeam.id!, page],
    () => getTransactionsService({ page }),
    {
      keepPreviousData: true,
    }
  );

  const { t } = useTranslation();

  const columns = useMemo(
    () => [
      {
        Header: t("ID"),
        Cell: ({
          row,
        }: CellProps<App.Domain.API.Transactions.Resources.TransactionResource>) => (
          <TableCell widthClassName="w-32" variant="secondary">
            {row.original.id}
          </TableCell>
        ),
      },
      {
        Header: t("Client"),
        Cell: ({
          row,
        }: CellProps<App.Domain.API.Transactions.Resources.TransactionResource>) => (
          <TableCell widthClassName="w-64">
            {row.original.client && (
              <div className="-ml-1">
                <Link
                  to={`/clients/${row.original.client.id}`}
                  className={getTextButtonClassNames()}
                >
                  {row.original.client.fullName}
                </Link>
              </div>
            )}
          </TableCell>
        ),
      },
      {
        Header: t("Total"),
        Cell: ({
          row,
        }: CellProps<App.Domain.API.Transactions.Resources.TransactionResource>) => (
          <TableCell widthClassName="w-48">
            {currency(row.original.total, {
              fromCents: true,
              symbol: "€",
            }).format()}
          </TableCell>
        ),
      },
      {
        Header: t("Date"),
        Cell: ({
          row,
        }: CellProps<App.Domain.API.Transactions.Resources.TransactionResource>) => (
          <TableCell widthClassName="w-48" variant="secondary">
            {format(parseISO(row.original.createdAt), "yyyy-MM-dd kk:mm")}
          </TableCell>
        ),
      },
      {
        Header: t("Status"),
        Cell: ({
          row,
        }: CellProps<App.Domain.API.Transactions.Resources.TransactionResource>) => (
          <TableCell widthClassName="w-48">
            <Badge variant="success">{t("Paid")}</Badge>
          </TableCell>
        ),
      },
      {
        id: "actions",
        Header: <LoadingTableHeader loading={isFetching} />,
      },
    ],
    [t, isFetching]
  );

  return (
    <Card>
      <NegateCardPadding>
        <Table columns={columns} data={transactionsResponse?.data?.data!} />
        <Pagination
          page={page}
          isPreviousData={isPreviousData}
          hasMorePages={transactionsResponse?.data?.meta.hasMorePages}
          loading={isFetching}
          onNext={nextPage}
          onPrevious={previousPage}
        />
      </NegateCardPadding>
    </Card>
  );
};
