import { useTranslation } from "react-i18next";
import { useContextService } from "hooks/use-context-service";
import { showNotification as showNotificationService } from "services/notification";
import { useMutation } from "react-query";
import { updateClient } from "../services/client";
import { queryClient } from "query";
import { ServiceReturnType } from "types";
import { ClientForm } from "domain/clients/components/ClientForm";
import { CLIENT_QUERY, useClient } from "../../../hooks/use-client";
import { getClient } from "../../../services/client";
import { CLIENTS_QUERY } from "domain/clients/domain/list/components/ClientsTable";

export const EditClientForm = () => {
  const { t } = useTranslation();

  const showNotification = useContextService(showNotificationService);

  const { data: clientResponse } = useClient();

  const updateClientMutation = useMutation(useContextService(updateClient), {
    onSuccess: (response) => {
      queryClient.setQueryData(
        [CLIENT_QUERY, clientResponse?.data?.data?.id],
        (old): ServiceReturnType<typeof getClient> => {
          const oldCache = old as ServiceReturnType<typeof getClient>;

          return {
            ...oldCache,
            data: {
              ...oldCache.data!,
              data: {
                ...oldCache.data?.data!,
                ...response.data?.data,
              },
            },
          };
        }
      );

      queryClient.resetQueries(CLIENTS_QUERY);

      showNotification({
        title: t("Successfully updated"),
        message: t("You updated the client."),
      });
    },
  });

  return (
    <ClientForm
      withCard={false}
      defaultValues={clientResponse?.data?.data}
      onSubmit={(data) =>
        updateClientMutation.mutateAsync({
          id: clientResponse?.data?.data?.id!,
          data,
        })
      }
    />
  );
};
