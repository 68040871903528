import { useTranslation } from "react-i18next";
import { FormSection } from "components/FormSection";
import { useContextService } from "hooks/use-context-service";
import { showNotification as showNotificationService } from "services/notification";
import { useUser } from "hooks/use-user";
import { TEAM_QUERY, useTeam } from "../hooks/use-team";
import { useMutation } from "react-query";
import { revokeInvitation } from "../services/team";
import { queryClient } from "query";
import { ValidationFailed } from "errors/ValidationFailed";
import { TextButton } from "components/TextButton";
import { InvitationItem } from "domain/teams/components/InvitationItem";
import { useRoles } from "../hooks/use-roles";

const Invitation = ({
  invitation,
  teamId,
}: {
  invitation: App.Domain.API.Teams.Resources.InvitationResource;
  teamId: string;
}) => {
  const { t } = useTranslation();

  const showNotification = useContextService(showNotificationService);

  const revokeInvitationMutation = useMutation(useContextService(revokeInvitation), {
    onSuccess: (response) => {
      queryClient.setQueryData([TEAM_QUERY, teamId], response);

      showNotification({
        title: t("Successfully revoked"),
        message: t("You revoked the invitation."),
      });
    },
    onError: (error) => {
      if (error instanceof ValidationFailed) {
        queryClient.invalidateQueries([TEAM_QUERY, teamId]);

        return new Promise(() => {});
      }
    },
  });

  const roles = useRoles();

  return (
    <InvitationItem
      actions={
        <TextButton
          variant="danger"
          disabled={revokeInvitationMutation.isLoading}
          loading={revokeInvitationMutation.isLoading}
          onClick={() =>
            revokeInvitationMutation.mutate({
              id: teamId,
              data: invitation,
            })
          }
        >
          {t("Revoke invitation")}
        </TextButton>
      }
    >
      <div>
        <p className="text-base font-medium text-gray-800">
          {invitation.email}
        </p>
        {invitation.role && (
          <p className="text-sm font-medium text-gray-500 truncate">
            {roles.labels[invitation.role]}
          </p>
        )}
      </div>
    </InvitationItem>
  );
};

export const ManageInvitations = () => {
  const { t } = useTranslation();

  const { data: userResponse } = useUser();

  const { data: teamResponse } = useTeam(
    userResponse?.data?.data?.currentTeam.id
  );

  return (
    <FormSection
      title={t("Pending Invitations")}
      description={t("Manage your pending team invitations.")}
    >
      {teamResponse?.data?.data?.invitations &&
      teamResponse?.data?.data?.invitations.length > 0 ? (
        <div className="-my-4 divide-y">
          {teamResponse?.data?.data?.invitations.map((item) => (
            <Invitation
              key={item.email}
              teamId={teamResponse.data?.data?.id!}
              invitation={item}
            />
          ))}
        </div>
      ) : (
        <p className="text-sm text-gray-700">
          {t("There are no pending invitations.")}
        </p>
      )}
    </FormSection>
  );
};
