import { Heading } from "components/Heading";
import { useTranslation } from "react-i18next";
import { Suspense } from "react";
import { TransactionsTable } from "./TransactionsTable";
import { ContentWrapper } from "components/ContentWrapper";
import { TablePlaceholder } from "components/TablePlaceholder";
import { Card } from "components/Card";
import { useUser } from "hooks/use-user";
import { HeadingWrapper } from "components/HeadingWrapper";
import { NegateCardPadding } from "components/NegateCardPadding";

export const Content = () => {
  const { data: userResponse } = useUser();

  return <TransactionsTable key={userResponse?.data?.data?.currentTeam.id!} />;
};

export const Container = () => {
  const { t } = useTranslation();

  return (
    <ContentWrapper>
      <HeadingWrapper>
        <Heading>{t("Transactions")}</Heading>
      </HeadingWrapper>
      <Suspense
        fallback={
          <Card>
            <NegateCardPadding>
              <TablePlaceholder />
            </NegateCardPadding>
          </Card>
        }
      >
        <Content />
      </Suspense>
    </ContentWrapper>
  );
};
