import { USER_QUERY, useUser } from "hooks/use-user";
import { useTranslation } from "react-i18next";
import { SelectorIcon } from "@heroicons/react/solid";
import { Menu, MenuButton, MenuDivider, MenuLabel, MenuLink } from "./Menu";
import { CheckCircleIcon } from "@heroicons/react/outline";
import { useMutation } from "react-query";
import { useContextService } from "hooks/use-context-service";
import { switchTeam } from "services/team";
import { queryClient } from "query";
import { LoadingSpinner } from "./LoadingSpinner";
import classNames from "classnames";
import { useTeam } from "domain/teams/domain/edit/hooks/use-team";

export const TeamDropdownMenu = () => {
  const { data: userResponse } = useUser();

  const { data: teamResponse } = useTeam(
    userResponse?.data?.data?.currentTeam.id
  );

  const { t } = useTranslation();

  const switchTeamMutation = useMutation(useContextService(switchTeam), {
    onSuccess: (response) => {
      queryClient.setQueryData(USER_QUERY, response);
    },
  });

  return (
    <Menu
      content={
        <>
          <MenuLabel>{t("Manage Team")}</MenuLabel>
          <MenuLink to="/teams/edit">{t("Team settings")}</MenuLink>
          <MenuLink to="/teams/create">{t("Create new team")}</MenuLink>
          {teamResponse?.data?.data?.owner.id ===
            userResponse?.data?.data?.id && (
            <MenuLink to="/transactions">{t("Transactions")}</MenuLink>
          )}
          {(teamResponse?.data?.data?.owner.id ===
            userResponse?.data?.data?.id ||
            userResponse?.data?.data?.currentTeam.role === "ADMIN") && (
            <MenuLink to="/services">{t("Services")}</MenuLink>
          )}
          <MenuLink to="/schedule">{t("Schedule")}</MenuLink>
          <MenuDivider />
          <MenuLabel>{t("Switch Team")}</MenuLabel>
          {userResponse?.data?.data?.teams.map((item) => (
            <MenuButton
              key={item.id}
              onClick={() => {
                if (item.id !== userResponse.data?.data?.currentTeam.id) {
                  switchTeamMutation.mutate({ teamId: item.id });
                }
              }}
            >
              {item.id === userResponse?.data?.data?.currentTeam.id && (
                <div className="w-4 mr-1">
                  <CheckCircleIcon className="w-4 text-green-500" />
                </div>
              )}
              <span className="overflow-hidden overflow-ellipsis">
                {item.name}
              </span>
            </MenuButton>
          ))}
        </>
      }
    >
      <button
        disabled={switchTeamMutation.isLoading}
        className={classNames(
          "flex items-center text-sm font-medium text-gray-900 bg-white focus:outline-none",
          {
            "cursor-wait": switchTeamMutation.isLoading,
          }
        )}
      >
        <span className="max-w-[14rem] overflow-hidden overflow-ellipsis mr-1">
          {userResponse?.data?.data?.currentTeam.name}
        </span>

        <span className="inline-block w-5">
          {switchTeamMutation.isLoading ? (
            <LoadingSpinner className="w-3 mx-auto text-indigo-900" />
          ) : (
            <SelectorIcon className="w-5 text-gray-500" />
          )}
        </span>
      </button>
    </Menu>
  );
};
