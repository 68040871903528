import { PropsWithoutRef } from "react";
import { Avatar } from "./Avatar";
import { FieldLabel } from "./FieldLabel";

export interface UserInfoFormHintProps {
  name: string;
  email: string;
  label: string;
  avatar?: string | null;
}

export const UserInfoFormHint = ({
  name,
  avatar,
  email,
  label,
}: PropsWithoutRef<UserInfoFormHintProps>) => (
  <div>
    <FieldLabel htmlFor={name}>{label}</FieldLabel>
    <div aria-describedby={name} className="flex items-center">
      <div className="flex-shrink-0">
        <Avatar size="large" name={name} src={avatar} />
      </div>
      <div className="flex-1 min-w-0 ml-3">
        <div className="text-base font-medium text-gray-800 truncate">
          {name}
        </div>
        <div className="text-sm font-medium text-gray-500 truncate">
          {email}
        </div>
      </div>
    </div>
  </div>
);
