import { forwardRef, PropsWithoutRef, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { FieldProps } from "types";
import { getFieldIds } from "utils/field";
import { FieldError } from "./FieldError";
import { FieldLabel } from "./FieldLabel";

export type FileFieldProps = Omit<
  PropsWithoutRef<JSX.IntrinsicElements["input"]>,
  "className" | "children" | "type" | "onDragOver" | "onDrop" | "accept"
> &
  FieldProps & {
    mimes: string[];
    maxFileSize: number;
    preview?: ReactNode;
    onDrop: (files: FileList) => void;
  };

export const FileField = forwardRef<HTMLInputElement, FileFieldProps>(
  (
    {
      required,
      optional,
      label,
      id,
      name,
      error,
      mimes,
      maxFileSize,
      preview,
      onDrop,
      ...props
    },
    ref
  ) => {
    const { computedId, errorId } = getFieldIds({ id, name });

    const { t } = useTranslation();

    const accept = mimes.map((item) => `.${item.toLowerCase()}`).join(", ");

    const mimesLabel = mimes.join(", ");

    return (
      <div
        onDrop={(e) => {
          e.preventDefault();

          e.stopPropagation();

          if (onDrop) {
            onDrop(e.dataTransfer.files);
          }
        }}
        onDragOver={(e) => {
          e.stopPropagation();

          e.preventDefault();
        }}
      >
        {label && <FieldLabel htmlFor={computedId}>{label}</FieldLabel>}
        <div className="flex justify-center px-6 pt-5 pb-6 mt-1 border-2 border-gray-300 border-dashed rounded-md">
          <div className="space-y-1 text-center">
            {preview ? (
              <div className="flex items-center justify-center">{preview}</div>
            ) : (
              <svg
                className="w-12 h-12 mx-auto text-gray-400"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 48 48"
                aria-hidden="true"
              >
                <path
                  d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )}
            <div className="flex text-sm text-gray-600">
              <label
                htmlFor={computedId}
                className="relative font-medium text-indigo-600 bg-white rounded-md cursor-pointer hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
              >
                <span>{t("Upload a file")}</span>
                <input
                  ref={ref}
                  type="file"
                  className="sr-only"
                  id={computedId}
                  name={name}
                  accept={accept}
                  {...props}
                />
              </label>
              <p className="pl-1">{t("or drag and drop")}</p>
            </div>
            <p className="text-sm text-gray-500">
              {t("{{mimes}} up to {{maxFileSize}}", {
                mimes: mimesLabel,
                maxFileSize: `${maxFileSize / 1024 / 1024} MB`,
              })}
            </p>
            {error && <FieldError id={errorId}>{error.message}</FieldError>}
          </div>
        </div>
      </div>
    );
  }
);
