import { Context, ResponsePayload } from "types";

export const updateClient =
  ({ apiClient }: Context) =>
  ({
    id,
    data,
  }: {
    id: string | number;
    data: App.Domain.API.Clients.Resources.SaveClientResource;
  }) =>
    apiClient<ResponsePayload<App.Domain.API.Clients.Resources.ClientResource>>(
      {
        url: `/clients/${id}`,
        method: "PUT",
        data,
      }
    );
