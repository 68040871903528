import FullCalendar, {
  DateSelectArg,
  EventApi,
  EventInput,
} from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import "styles/calendar.css";
import {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useContextService } from "hooks/use-context-service";
import { InfiniteData, useInfiniteQuery, useMutation } from "react-query";
import {
  completeAppointment,
  confirmAppointment,
  createAppointment,
  deleteAllAppointments,
  deleteAppointment,
  getAppointments,
  updateAppointment,
} from "../services/appointments";
import { setHours, setMinutes } from "date-fns";
import { useUser } from "hooks/use-user";
import { useCalendarDates } from "../hooks/use-calendar-dates";
import { useCalendarView } from "../hooks/use-calendar-view";
import { useTeam } from "domain/teams/domain/edit/hooks/use-team";
import { Header } from "./Header";
import { queryClient } from "query";
import { ServiceReturnType } from "types";
import { Modal } from "components/Modal";
import { ModalHeader } from "components/ModalHeader";
import { useModal } from "hooks/use-modal";
import { AppointmentForm } from "./AppointmentForm";
import { useTranslation } from "react-i18next";
import currency from "currency.js";
import { PromptModalContent } from "components/PromptModalContent";
import { Button } from "components/Button";
import { Placeholder } from "components/Placeholder";
import { parseInterval } from "utils/time";

const APPOINTMENTS_QUERY = "appointments";

type ExtendedAppointment =
  App.Domain.API.Appointments.Resources.AppointmentResource & {
    userId: string | null;
    serviceId: string | null;
  };

const eventColors: Record<
  App.States.AppointmentState.AppointmentState,
  string
> = {
  PENDING_PAYMENT: "#9CA3AF",
  PENDING_CONFIRMATION: "#CA8A04",
  PENDING_CLIENT_CONFIRMATION: "#F97316",
  PAID_PENDING_CLIENT_CONFIRMATION: "#F97316",
  CONFIRMED: "#6366F1",
  COMPLETED: "#10B981",
};

export const Calendar = () => {
  const { t } = useTranslation();

  const calendarRef = useRef<FullCalendar | null>(null);

  const { startDate, endDate, todayNotInView } = useCalendarDates(calendarRef);

  const { view } = useCalendarView(calendarRef);

  const { data: userResponse } = useUser();

  const { data: teamResponse } = useTeam(
    userResponse?.data?.data?.currentTeam.id
  );

  const [selectedUserId, setSelectedUserId] = useState(
    userResponse?.data?.data?.id
  );

  const selectedUser = teamResponse?.data?.data?.members.find(
    (item) => item.id === selectedUserId
  );

  useEffect(() => {
    setSelectedUserId(userResponse?.data?.data?.id);
  }, [userResponse?.data?.data?.currentTeam.id, userResponse?.data?.data?.id]);

  const getAppointmentsService = useContextService(getAppointments);

  const queryKey = [
    APPOINTMENTS_QUERY,
    teamResponse?.data?.data?.id,
    selectedUserId,
    { startDate, endDate },
  ];

  const { data, isFetchingNextPage, fetchNextPage, hasNextPage } =
    useInfiniteQuery(
      queryKey,
      ({ pageParam }) =>
        getAppointmentsService({
          page: pageParam,
          startDate: startDate!,
          endDate: endDate!,
          userId: selectedUserId!,
        }),
      {
        suspense: false,
        enabled: !!calendarRef && !!startDate && !!endDate && !!selectedUserId,
        getNextPageParam: (lastPage) =>
          lastPage.data?.meta.hasMorePages
            ? lastPage.data!.meta.currentPage + 1
            : undefined,
        notifyOnChangeProps: "tracked",
        // refetchOnWindowFocus: false,
      }
    );

  useEffect(() => {
    if (!isFetchingNextPage && hasNextPage) {
      fetchNextPage();
    }
  }, [isFetchingNextPage, hasNextPage, fetchNextPage]);

  const {
    modalVisible: formModalVisible,
    openModal: openFormModal,
    closeModal: closeFormModal,
  } = useModal({
    onClose: () => {
      calendarRef.current?.getApi().unselect();

      if (selectedEvent) {
        setTimeout(() => {
          setSelectedEvent(undefined);
        }, 250);
      }
    },
  });

  const [positiveEditAnswer, setPositiveEditAnswer] = useState<Boolean>();

  const {
    modalVisible: promptEditModalVisible,
    openModal: openPromptEditModal,
    closeModal: closePromptEditModal,
  } = useModal({
    onClose: () => {
      if (!positiveEditAnswer) {
        promptEditData?.revert();
      } else {
        updateAppointmentMutation.mutateAsync({
          id: (promptEditData!.event.extendedProps as ExtendedAppointment).id,
          data: {
            ...(promptEditData!.event.extendedProps as ExtendedAppointment),
            startDate: promptEditData!.event.start!.toISOString(),
            endDate: promptEditData!.event.end!.toISOString(),
          },
        });
      }

      setPositiveEditAnswer(undefined);

      setTimeout(() => {
        setPromptEditData(undefined);
      }, 250);
    },
  });

  useEffect(() => {
    if (positiveEditAnswer) {
      closePromptEditModal();
    }
  }, [positiveEditAnswer, closePromptEditModal]);

  const updateAppointmentService = useContextService(updateAppointment);

  const updateAppointmentMutation = useMutation(updateAppointmentService, {
    onMutate: async ({ id, data: payload }) => {
      await queryClient.cancelQueries(queryKey);

      queryClient.setQueryData(
        queryKey,
        (data): InfiniteData<ServiceReturnType<typeof getAppointments>> => {
          const oldData = data as InfiniteData<
            ServiceReturnType<typeof getAppointments>
          >;

          return {
            ...oldData,
            pages: oldData.pages.map((page) => ({
              ...page,
              data: {
                ...page.data!,
                data:
                  payload.userId === selectedUserId
                    ? page.data!.data.map((item) => {
                        return item.id === id
                          ? {
                              ...item,
                              ...payload,
                              ...(item.startDate !== payload.startDate ||
                              item.endDate !== payload.endDate
                                ? {
                                    state:
                                      item.state === "PENDING_CONFIRMATION" ||
                                      item.state ===
                                        "PAID_PENDING_CLIENT_CONFIRMATION" ||
                                      item.state === "CONFIRMED"
                                        ? "PAID_PENDING_CLIENT_CONFIRMATION"
                                        : "PENDING_CLIENT_CONFIRMATION",
                                  }
                                : {}),
                            }
                          : item;
                      })
                    : page.data!.data.filter(
                        (item) => item.user?.id === payload.userId
                      ),
              },
            })),
          };
        }
      );

      closeFormModal();
    },
  });

  const createAppointmentMutation = useMutation(
    useContextService(createAppointment),
    {
      onSuccess: async (response) => {
        await queryClient.cancelQueries(queryKey);

        if (
          selectedUserId === response.data?.data?.user?.id &&
          response.data?.data
        ) {
          queryClient.setQueryData(
            queryKey,
            (data): InfiniteData<ServiceReturnType<typeof getAppointments>> => {
              const oldData = data as InfiniteData<
                ServiceReturnType<typeof getAppointments>
              >;

              return {
                ...oldData,
                pages: oldData.pages.map((page) => ({
                  ...page,
                  data: {
                    ...page.data!,
                    data: [...page.data!.data, response.data!.data!],
                  },
                })),
              };
            }
          );
        }

        queryClient.refetchQueries(queryKey);

        closeFormModal();
      },
    }
  );

  const deleteAppointmentMutation = useMutation(
    useContextService(deleteAppointment),
    {
      onMutate: async (id) => {
        await queryClient.cancelQueries(queryKey);

        queryClient.setQueryData(
          queryKey,
          (data): InfiniteData<ServiceReturnType<typeof getAppointments>> => {
            const oldData = data as InfiniteData<
              ServiceReturnType<typeof getAppointments>
            >;

            return {
              ...oldData,
              pages: oldData.pages.map((page) => ({
                ...page,
                data: {
                  ...page.data!,
                  data: page.data!.data.filter((item) => item.id !== id),
                },
              })),
            };
          }
        );

        closeFormModal();
      },
    }
  );

  const deleteAllAppointmentsMutation = useMutation(
    useContextService(deleteAllAppointments),
    {
      onMutate: async ({ id, batchId }) => {
        await queryClient.cancelQueries(queryKey);

        queryClient.setQueryData(
          queryKey,
          (data): InfiniteData<ServiceReturnType<typeof getAppointments>> => {
            const oldData = data as InfiniteData<
              ServiceReturnType<typeof getAppointments>
            >;

            return {
              ...oldData,
              pages: oldData.pages.map((page) => ({
                ...page,
                data: {
                  ...page.data!,
                  data: page.data!.data.filter(
                    (item) =>
                      item.batchId !== batchId || item.state === "COMPLETED"
                  ),
                },
              })),
            };
          }
        );

        closeFormModal();
      },
    }
  );

  const confirmAppointmentMutation = useMutation(
    useContextService(confirmAppointment),
    {
      onMutate: async (id) => {
        await queryClient.cancelQueries(queryKey);

        queryClient.setQueryData(
          queryKey,
          (data): InfiniteData<ServiceReturnType<typeof getAppointments>> => {
            const oldData = data as InfiniteData<
              ServiceReturnType<typeof getAppointments>
            >;

            return {
              ...oldData,
              pages: oldData.pages.map((page) => ({
                ...page,
                data: {
                  ...page.data!,
                  data: page.data!.data.map((item) =>
                    item.id === id ? { ...item, state: "CONFIRMED" } : item
                  ),
                },
              })),
            };
          }
        );

        closeFormModal();
      },
    }
  );

  const completeAppointmentMutation = useMutation(
    useContextService(completeAppointment),
    {
      onMutate: async (id) => {
        await queryClient.cancelQueries(queryKey);

        queryClient.setQueryData(
          queryKey,
          (data): InfiniteData<ServiceReturnType<typeof getAppointments>> => {
            const oldData = data as InfiniteData<
              ServiceReturnType<typeof getAppointments>
            >;

            return {
              ...oldData,
              pages: oldData.pages.map((page) => ({
                ...page,
                data: {
                  ...page.data!,
                  data: page.data!.data.map((item) =>
                    item.id === id ? { ...item, state: "COMPLETED" } : item
                  ),
                },
              })),
            };
          }
        );

        closeFormModal();
      },
    }
  );

  const [selectedRange, setSelectedRange] = useState<[Date, Date]>();

  const rangeSelectHandler = (data: DateSelectArg) => {
    setSelectedRange([data.start, data.end]);

    openFormModal();
  };

  const [selectedEvent, setSelectedEvent] =
    useState<App.Domain.API.Appointments.Resources.AppointmentResource>();

  const [promptEditData, setPromptEditData] = useState<{
    event: EventApi;
    revert: () => void;
    data: App.Domain.API.Appointments.Resources.SaveAppointmentResource;
  }>();

  const eventClickHandler = (event: EventApi) => {
    setSelectedEvent(event.extendedProps as ExtendedAppointment);

    openFormModal();
  };

  const eventUpdateHandler = useCallback(
    ({ event, revert }: { event: EventApi; revert: () => void }) => {
      setPromptEditData({
        event,
        revert,
        data: {
          ...(event.extendedProps as ExtendedAppointment),
          startDate: event.start!.toISOString(),
          endDate: event.end!.toISOString(),
        },
      });

      openPromptEditModal();
    },
    [setPromptEditData, openPromptEditModal]
  );

  const events = useMemo(
    () =>
      data?.pages.reduce(
        (res, page) => [
          ...res,
          ...page.data!.data.map((item) => ({
            start: item.startDate,
            end: item.endDate,
            title: item.client.fullName,
            backgroundColor: eventColors[item.state],
            borderColor: eventColors[item.state],
            editable: item.state !== "COMPLETED",
            extendedProps: {
              ...item,
              userId: item.user?.id || null,
              serviceId: item.service?.id || null,
            },
          })),
        ],
        [] as (EventInput & { extendedProps: ExtendedAppointment })[]
      ),
    [data?.pages]
  );

  return (
    <>
      <Modal visible={promptEditModalVisible} onClose={closePromptEditModal}>
        <PromptModalContent
          variant="primary"
          title={t("Confirm your action")}
          description={
            (promptEditData?.event?.extendedProps as ExtendedAppointment)
              ?.recurring
              ? t("You're about to edit the appointment. Are you sure?")
              : t(
                  "You're about to edit the appointment. An email notification with a confirmation link will be sent to the client."
                )
          }
          actions={
            <>
              <Button variant="white" onClick={closePromptEditModal}>
                {t("Cancel")}
              </Button>
              <Button onClick={() => setPositiveEditAnswer(true)}>
                {t("Confirm")}
              </Button>
            </>
          }
        />
      </Modal>
      <Modal
        align="top"
        mobileAlign="top"
        visible={formModalVisible}
        onClose={closeFormModal}
      >
        <ModalHeader onClose={closeFormModal}>
          {selectedEvent && selectedEvent.state === "COMPLETED" ? (
            t("Appointment Details")
          ) : (
            <>{selectedEvent ? t("Edit Appointment") : t("New Appointment")}</>
          )}
        </ModalHeader>
        <Suspense
          fallback={
            <div className="space-y-4">
              {[...Array(8)].map((item, index) => (
                <Placeholder
                  key={index}
                  widthClassName="w-full"
                  heightClassName="h-8"
                />
              ))}
            </div>
          }
        >
          <AppointmentForm
            key={selectedUserId}
            onCancel={closeFormModal}
            onDelete={() =>
              deleteAppointmentMutation.mutateAsync(selectedEvent!.id)
            }
            onDeleteAll={() =>
              deleteAllAppointmentsMutation.mutateAsync({
                id: selectedEvent!.id,
                batchId: selectedEvent!.batchId!,
              })
            }
            onCofirm={() =>
              confirmAppointmentMutation.mutateAsync(selectedEvent!.id)
            }
            onComplete={() =>
              completeAppointmentMutation.mutateAsync(selectedEvent!.id)
            }
            onSubmit={(data) => {
              const startHours = Number.parseInt(data.startTime.split(":")[0]);

              const startMinutes = Number.parseInt(
                data.startTime.split(":")[1]
              );

              const endHours = Number.parseInt(data.endTime.split(":")[0]);

              const endMinutes = Number.parseInt(data.endTime.split(":")[1]);

              const payload = {
                ...data,
                startDate: setHours(
                  setMinutes(data.date, startMinutes),
                  startHours
                ).toISOString(),
                endDate: setHours(
                  setMinutes(data.date, endMinutes),
                  endHours
                ).toISOString(),
                price: currency(data.price).intValue,
                clientId: data.client.id,
              };

              return selectedEvent
                ? updateAppointmentMutation.mutateAsync({
                    id: selectedEvent.id,
                    data: payload,
                  })
                : createAppointmentMutation.mutateAsync({
                    ...payload,
                    recurringAppointments:
                      payload.recurringAppointments?.map((item) =>
                        item.toISOString()
                      ) || [],
                  });
            }}
            mode={selectedEvent ? "edit" : "create"}
            appointment={
              selectedEvent || {
                user: {
                  id: selectedUserId,
                },
                startDate: selectedRange
                  ? selectedRange[0].toISOString()
                  : undefined,
                endDate: selectedRange
                  ? selectedRange[1].toISOString()
                  : undefined,
              }
            }
          />
        </Suspense>
      </Modal>
      <div className="flex flex-col h-full max-h-screen">
        {!!startDate && !!endDate && !!selectedUserId && (
          <Header
            startDate={startDate}
            endDate={endDate}
            selectedUserId={selectedUserId}
            todayNotInView={todayNotInView}
            onUserSelect={setSelectedUserId}
            onToday={() => calendarRef.current?.getApi().today()}
            onPrev={() => calendarRef.current?.getApi().prev()}
            onNext={() => calendarRef.current?.getApi().next()}
          />
        )}
        <div className="flex-1 overflow-auto">
          <FullCalendar
            ref={calendarRef}
            initialView={view}
            allDaySlot={false}
            events={events}
            selectable
            selectMirror
            editable
            plugins={[timeGridPlugin, interactionPlugin]}
            firstDay={1}
            eventDrop={({ event, revert }) =>
              eventUpdateHandler({ event, revert })
            }
            eventResize={({ event, revert }) =>
              eventUpdateHandler({ event, revert })
            }
            eventClick={({ event }) => eventClickHandler(event)}
            select={rangeSelectHandler}
            unselectCancel="[role='dialog']"
            slotLabelFormat={{
              hour12: false,
              hour: "2-digit",
              minute: "2-digit",
              meridiem: "short",
            }}
            eventTimeFormat={{
              hour12: false,
              hour: "2-digit",
              minute: "2-digit",
            }}
            eventConstraint={{
              startTime: "00:00",
              endTime: "23:00",
            }}
            selectConstraint={{
              startTime: "00:00",
              endTime: "23:00",
            }}
            headerToolbar={{
              start: "",
              end: "",
            }}
            locale="en-GB"
            businessHours={
              selectedUser
                ? selectedUser.schedules.map((item) => ({
                    daysOfWeek: [[1, 2, 3, 4, 5, 6, 0][item.day]],
                    startTime: parseInterval(item.from).formattedTime,
                    endTime: parseInterval(item.to).formattedTime,
                  }))
                : []
            }
            snapDuration="00:05:00"
          />
        </div>
      </div>
    </>
  );
};
