import { RouteSwitch } from "components/RouteSwitch";
import { Route, useRouteMatch } from "react-router-dom";
import { CreateClient } from "../domain/create";
import { ClientProfile } from "../domain/profile";
import { ClientsList } from "../domain/list";

export const Container = () => {
  const match = useRouteMatch();

  return (
    <RouteSwitch>
      <Route exact path={match.path + '/create'}>
        <CreateClient />
      </Route>
      <Route path={match.path + '/:id'}>
        <ClientProfile />
      </Route>
      <Route exact path={match.path}>
        <ClientsList />
      </Route>
    </RouteSwitch>
  );
};
