import { useTranslation } from "react-i18next";
import { useContextService } from "hooks/use-context-service";
import { showNotification as showNotificationService } from "services/notification";
import { useMutation } from "react-query";
import { editService } from "../services/services";
import { queryClient } from "query";
import { useUser } from "hooks/use-user";
import { ServiceReturnType } from "types";
import { ServiceForm } from "domain/services/components/ServiceForm";
import { getServices } from "services/services";
import { SERVICES_QUERY } from "hooks/use-services";
import { Button } from "components/Button";

export const EditServiceForm = ({
  service,
  onSubmit,
  onCancel,
}: {
  service: App.Domain.API.Services.Resources.ServiceResource;
  onSubmit: () => void;
  onCancel: () => void;
}) => {
  const { t } = useTranslation();

  const showNotification = useContextService(showNotificationService);

  const { data: userResponse } = useUser();

  const updateServiceMutation = useMutation(useContextService(editService), {
    onSuccess: (response) => {
      queryClient.setQueryData(
        [SERVICES_QUERY, userResponse?.data?.data?.currentTeam.id!],
        (old): ServiceReturnType<typeof getServices> => {
          const oldCache = old as ServiceReturnType<typeof getServices>;

          return {
            ...oldCache,
            data: {
              ...oldCache.data!,
              data: oldCache.data!.data.map((item) =>
                item.id === response.data?.data?.id ? response.data!.data : item
              ),
            },
          };
        }
      );

      showNotification({
        title: t("Successfully updated"),
        message: t("You edited the service."),
      });

      onSubmit();
    },
  });

  return (
    <ServiceForm
      variant="vertical"
      withCard={false}
      actions={
        <Button type="button" variant="white" onClick={onCancel}>
          {t("Cancel")}
        </Button>
      }
      service={service}
      onSubmit={(data) =>
        updateServiceMutation.mutateAsync({
          id: service.id,
          data,
        })
      }
    />
  );
};
