import { Context, ResponsePayload } from "types";

export const getAccount =
  ({ apiClient }: Pick<Context, 'apiClient'>) =>
  () =>
    apiClient<ResponsePayload<App.Domain.API.Account.Resources.UserResource>>({
      url: "/account",
    });

export const logout =
  ({ apiClient }: Context) =>
  () =>
    apiClient({
      url: "/logout",
      method: "POST",
    });
