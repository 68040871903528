import { HomeIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";

export const Breadcrumbs = ({
  pages,
}: {
  pages: { name: string; href: string; current?: boolean }[];
}) => (
  <nav
    className="flex bg-white border-b border-gray-200"
    aria-label="Breadcrumb"
  >
    <ol className="flex w-full px-4 mx-auto space-x-4 overflow-auto max-w-screen-2xl sm:px-6 lg:px-8">
      <li className="flex">
        <div className="flex items-center">
          <Link to="/" className="text-gray-400 hover:text-gray-500">
            <HomeIcon className="flex-shrink-0 w-5 h-5" aria-hidden="true" />
            <span className="sr-only">Home</span>
          </Link>
        </div>
      </li>
      {pages.map((page) => (
        <li key={page.name} className="flex">
          <div className="flex items-center">
            <svg
              className="flex-shrink-0 w-6 h-full text-gray-200"
              viewBox="0 0 24 44"
              preserveAspectRatio="none"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
            >
              <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
            </svg>
            <Link
              to={page.href}
              className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700 whitespace-nowrap"
              aria-current={page.current ? "page" : undefined}
            >
              {page.name}
            </Link>
          </div>
        </li>
      ))}
    </ol>
  </nav>
);
