import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { Button } from "components/Button";
import { FormSection } from "components/FormSection";
import { useContextService } from "hooks/use-context-service";
import { showNotification as showNotificationService } from "services/notification";
import { USER_QUERY, useUser } from "hooks/use-user";
import { useMutation } from "react-query";
import { leaveTeam } from "../services/team";
import { queryClient } from "query";
import { useHistory } from "react-router";
import { TextField } from "components/TextField";
import { Unauthorized } from "errors/Unauthorized";
import { pipe } from "fp-ts/lib/function";
import { useStringMax, useStringRequired } from "hooks/use-validation-input";

const useFormSchema = () =>
  z.object({
    password: pipe(z.string(), useStringMax(250), useStringRequired()),
  });

type FormSchema = z.infer<ReturnType<typeof useFormSchema>>;

export const LeaveTeamForm = () => {
  const { t } = useTranslation();

  const { data: userResponse } = useUser();

  const {
    handleSubmit,
    register,
    reset,
    setError,
    setFocus,
    formState: { isSubmitting, errors },
  } = useForm<FormSchema>({
    resolver: zodResolver(useFormSchema()),
  });

  const showNotification = useContextService(showNotificationService);

  const history = useHistory();

  const leaveTeamMutation = useMutation(useContextService(leaveTeam), {
    onSuccess: (response) => {
      queryClient.setQueryData(USER_QUERY, response);

      showNotification({
        title: t("Successfully left"),
        message: t("You left the team."),
      });

      history.push("/");
    },
    onError: (error: any) => {
      if (error instanceof Unauthorized) {
        reset();

        setError("password", {
          message: t("Incorrect password"),
        });

        setFocus("password");

        return new Promise(() => {});
      }
    },
  });

  return (
    <form
      onSubmit={handleSubmit((data) =>
        leaveTeamMutation.mutateAsync({
          id: userResponse?.data?.data?.currentTeam.id!,
          data,
        })
      )}
    >
      <FormSection
        title={t("Leave team")}
        description={t("Permanently leave this team.")}
      >
        <div className="max-w-xl">
          <p className="text-sm text-gray-700">
            {t(
              "Once you leave a team, you will have to be invited again before you can access its resources again."
            )}
          </p>
        </div>
        <div className="grid gap-4 lg:grid-cols-2">
          <TextField
            {...register("password")}
            label={t("Password")}
            error={errors.password}
            required
            type="password"
          />
        </div>
        <Button
          type="submit"
          variant="danger"
          disabled={isSubmitting}
          loading={isSubmitting}
        >
          {t("Leave team")}
        </Button>
      </FormSection>
    </form>
  );
};
