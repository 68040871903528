import { Heading } from "components/Heading";
import { useTranslation } from "react-i18next";
import { CreateServiceForm } from "./CreateServiceForm";
import { Suspense } from "react";
import { FormSectionPlaceholder } from "components/FormSectionPlaceholder";
import { ContentWrapper } from "components/ContentWrapper";
import { HeadingWrapper } from "components/HeadingWrapper";
import { Breadcrumbs } from "components/Breadcrumbs";

export const Container = () => {
  const { t } = useTranslation();

  return (
    <>
      <Breadcrumbs
        pages={[
          {
            name: t("Services"),
            href: "/services",
          },
          {
            name: t("New service"),
            href: "/services/create",
            current: true,
          },
        ]}
      />
      <ContentWrapper>
        <HeadingWrapper>
          <Heading>{t("Create New Service")}</Heading>
        </HeadingWrapper>
        <Suspense fallback={<FormSectionPlaceholder />}>
          <CreateServiceForm />
        </Suspense>
      </ContentWrapper>
    </>
  );
};
