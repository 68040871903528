import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { Button } from "components/Button";
import { FormSection } from "components/FormSection";
import { useContextService } from "hooks/use-context-service";
import { showNotification as showNotificationService } from "services/notification";
import { USER_QUERY, useUser } from "hooks/use-user";
import { useMutation } from "react-query";
import { deleteTeam } from "../services/team";
import { queryClient } from "query";
import { useHistory } from "react-router";
import { pipe } from "fp-ts/lib/function";
import { useStringMax, useStringRequired } from "hooks/use-validation-input";
import { TextField } from "components/TextField";
import { Unauthorized } from "errors/Unauthorized";

const useFormSchema = () =>
  z.object({
    password: pipe(z.string(), useStringMax(250), useStringRequired()),
  });

type FormSchema = z.infer<ReturnType<typeof useFormSchema>>;

export const DeleteTeamForm = () => {
  const { t } = useTranslation();

  const { data: userResponse } = useUser();

  const {
    register,
    handleSubmit,
    reset,
    setError,
    setFocus,
    formState: { isSubmitting, errors },
  } = useForm<FormSchema>({
    resolver: zodResolver(useFormSchema()),
  });

  const showNotification = useContextService(showNotificationService);

  const history = useHistory();

  const deleteTeamMutation = useMutation(useContextService(deleteTeam), {
    onSuccess: (response) => {
      queryClient.setQueryData(USER_QUERY, response);

      showNotification({
        title: t("Successfully deleted"),
        message: t("Your team was deleted."),
      });

      history.push("/");
    },
    onError: (error: any) => {
      if (error instanceof Unauthorized) {
        reset();

        setError("password", {
          message: t("Incorrect password"),
        });

        setFocus("password");

        return new Promise(() => {});
      }
    },
  });

  return (
    <form
      onSubmit={handleSubmit((data) =>
        deleteTeamMutation.mutateAsync({
          id: userResponse?.data?.data?.currentTeam.id!,
          data,
        })
      )}
    >
      <FormSection
        title={t("Delete team")}
        description={t("Permanently delete this team.")}
      >
        <div className="max-w-xl">
          <p className="text-sm text-gray-700">
            {t(
              "Once a team is deleted, all of its resources and data will be permanently deleted. Before deleting this team, please download any data or information regarding this team that you wish to retain."
            )}
          </p>
        </div>
        <div className="grid gap-4 lg:grid-cols-2">
          <TextField
            {...register("password")}
            label={t("Password")}
            error={errors.password}
            required
            type="password"
          />
        </div>
        <Button
          type="submit"
          variant="danger"
          disabled={isSubmitting}
          loading={isSubmitting}
        >
          {t("Delete team")}
        </Button>
      </FormSection>
    </form>
  );
};
