import { zodResolver } from "@hookform/resolvers/zod";
import { Button, getButtonClassNames } from "components/Button";
import { Card } from "components/Card";
import { NegateHorizontalCardPadding } from "components/NegateCardPadding";
import { Header } from "domain/online-appointments/components/Header";
import { Wrapper } from "domain/online-appointments/components/Wrapper";
import { useContextService } from "hooks/use-context-service";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import {
  editAppointment,
  getAppointment,
} from "../services/online-appointment";
import { z } from "zod";
import { pipe } from "fp-ts/lib/function";
import { useStringRequired } from "hooks/use-validation-input";
import { DateForm } from "domain/online-appointments/components/DateForm";
import { ServiceReturnType } from "types";
import { queryClient } from "query";
import { APPOINTMENT_PAY_QUERY } from "../../pay/components/Details";
import { getAppointment as getAppointmentPayment } from "../../pay/services/online-appointment";

const APPOINTMENT_EDIT_QUERY = "appointment-edit";

const useFormSchema = () =>
  z.object({
    date: z.date(),
    slot: pipe(z.string(), useStringRequired()),
    serviceId: pipe(z.string(), useStringRequired()),
    member: z.object({
      id: pipe(z.string(), useStringRequired()),
      workingDays: z.array(z.number()),
    }),
  });

type FormSchema = z.infer<ReturnType<typeof useFormSchema>>;

export const Details = ({
  onSuccess,
}: {
  onSuccess: (data: ServiceReturnType<typeof editAppointment>) => void;
}) => {
  const { appointmentId } = useParams<{ appointmentId: string }>();

  const getAppointmentService = useContextService(getAppointment);

  const { data: appointmentResponse } = useQuery(
    [APPOINTMENT_EDIT_QUERY, appointmentId],
    () => getAppointmentService(appointmentId)
  );

  const { t } = useTranslation();

  const form = useForm<FormSchema>({
    resolver: zodResolver(useFormSchema()),
    defaultValues: {
      member: appointmentResponse?.data?.data?.member,
      serviceId: appointmentResponse?.data?.data?.serviceId,
    },
  });

  const formValues = form.watch();

  const updateAppointmentMutation = useMutation(
    useContextService(editAppointment),
    {
      onSuccess: (response) => {
        if (queryClient.getQueryData([APPOINTMENT_PAY_QUERY, response.data?.data?.id])) {
          queryClient.setQueryData(
            [APPOINTMENT_PAY_QUERY, response.data?.data?.id],
            (old): ServiceReturnType<typeof getAppointmentPayment> => {
              const oldCache = old as ServiceReturnType<
                typeof getAppointmentPayment
              >;

              return {
                ...oldCache,
                data: {
                  ...oldCache.data!,
                  data: { ...oldCache.data!.data!, ...response.data?.data! },
                },
              };
            }
          );
        }

        onSuccess(response);
      },
    }
  );

  return (
    <Wrapper>
      <div className="w-full max-w-xl">
        <Card>
          <Header
            title={t("Edit Appointment")}
            description={t("Request a new date or time.")}
          />
          <form
            onSubmit={form.handleSubmit((data) =>
              updateAppointmentMutation.mutateAsync({
                id: appointmentId,
                data: {
                  ...data,
                  date: data.date.toISOString(),
                },
              })
            )}
          >
            <FormProvider {...form}>
              <div className="mt-4">
                <NegateHorizontalCardPadding>
                  <div className="pb-5 border-b">
                    <DateForm withHeader={false} withActions={false} />
                  </div>
                </NegateHorizontalCardPadding>
              </div>
              <div className="flex flex-col mt-5 space-y-4 sm:mt-6">
                <Button
                  loading={updateAppointmentMutation.isLoading}
                  disabled={
                    !formValues.date ||
                    !formValues.slot ||
                    updateAppointmentMutation.isLoading
                  }
                  withSpinner
                >
                  {t("Submit")}
                </Button>
                {appointmentResponse?.data?.data?.state &&
                  (appointmentResponse?.data?.data?.state ===
                    "PENDING_CLIENT_CONFIRMATION" ||
                    appointmentResponse?.data?.data?.state ===
                      "PENDING_PAYMENT") && (
                    <Link
                      to={`/online-appointment/pay/${appointmentResponse?.data?.data?.id}`}
                      className={getButtonClassNames({ variant: "white" })}
                    >
                      {t("Back to payment")}
                    </Link>
                  )}
              </div>
            </FormProvider>
          </form>
        </Card>
      </div>
    </Wrapper>
  );
};
