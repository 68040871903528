import { useContextService } from "hooks/use-context-service";
import { useUser } from "hooks/use-user";
import { useQuery } from "react-query";
import { getSchedules } from "../services/schedules";
import { DayOfWeek } from "./DayOfWeek";

export const SCHEDULES_QUERY = "schedules";

export const WeekSchedule = () => {
  const { data: userResponse } = useUser();

  const { data: schedulesResponse } = useQuery(
    [SCHEDULES_QUERY, userResponse?.data?.data?.currentTeam.id!],
    useContextService(getSchedules)
  );

  return (
    <>
      {Array.from(Array(7).keys()).map((day) => (
        <DayOfWeek
          key={day}
          schedules={schedulesResponse!.data!.data.filter(
            (item) => item.day === day
          )}
          day={day}
        />
      ))}
    </>
  );
};
