import { ReactNode } from "react";
import { Dialog } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { useTranslation } from "react-i18next";

export interface ModalHeaderProps {
  children: ReactNode;
  onClose: () => void;
}

export const ModalHeader = ({ children, onClose }: ModalHeaderProps) => {
  const { t } = useTranslation();

  return (
    <div className="flex items-center justify-between mb-4">
      <Dialog.Title
        as="h3"
        className="w-full text-lg font-medium leading-6 text-gray-900 break-words whitespace-pre-wrap"
      >
        {children}
      </Dialog.Title>
      <div className="absolute top-0 right-0 pt-4 pr-4">
        <button
          type="button"
          className="text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          onClick={onClose}
        >
          <span className="sr-only">{t("Close")}</span>
          <XIcon className="w-6 h-6" aria-hidden="true" />
        </button>
      </div>
    </div>
  );
};
