import { PropsWithoutRef } from "react";

export interface HeadingProps
  extends Omit<PropsWithoutRef<JSX.IntrinsicElements["h1"]>, "className"> {}

export const Heading = ({ children, ...props }: HeadingProps) => (
  <h1 {...props} className="text-3xl font-extrabold break-words text-blue-gray-900">
    {children}
  </h1>
);
