import { Fragment, PropsWithChildren, PropsWithoutRef, ReactNode } from "react";
import { Menu as HeadlessMenu, Transition } from "@headlessui/react";
import classNames from "classnames";
import { Link, LinkProps } from "react-router-dom";

const itemPaddingClassName = "px-4 py-2";

const getItemClassName = (active: boolean) =>
  classNames(
    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
    "flex w-full text-left text-sm focus:outline-none items-center",
    itemPaddingClassName
  );

export const MenuLabel = ({
  children,
  ...props
}: Omit<PropsWithoutRef<JSX.IntrinsicElements["div"]>, "className">) => (
  <div
    {...props}
    className={classNames("text-xs text-gray-400", itemPaddingClassName)}
  >
    {children}
  </div>
);

export const MenuDivider = () => <div className="border-t" />;

export const MenuLink = ({
  children,
  ...props
}: Omit<LinkProps, "className">) => (
  <HeadlessMenu.Item>
    {({ active }) => (
      <Link {...props} className={getItemClassName(active)}>
        {children}
      </Link>
    )}
  </HeadlessMenu.Item>
);

export const MenuButton = ({
  children,
  ...props
}: Omit<PropsWithoutRef<JSX.IntrinsicElements["button"]>, "className">) => (
  <HeadlessMenu.Item>
    {({ active }) => (
      <button {...props} className={getItemClassName(active)}>
        {children}
      </button>
    )}
  </HeadlessMenu.Item>
);

export type MenuProps = PropsWithChildren<
  PropsWithoutRef<{
    content: ReactNode;
  }>
>;

export const Menu = ({ children, content }: MenuProps) => (
  <HeadlessMenu as="div" className="relative inline-block text-left">
    {({ open }) => (
      <>
        <HeadlessMenu.Button as={Fragment}>{children}</HeadlessMenu.Button>
        <Transition
          show={open}
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <HeadlessMenu.Items
            static
            className="absolute right-0 z-50 w-56 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
          >
            <div className="py-1">{content}</div>
          </HeadlessMenu.Items>
        </Transition>
      </>
    )}
  </HeadlessMenu>
);
