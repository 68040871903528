import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { pipe } from "fp-ts/lib/function";
import { Button } from "components/Button";
import { FormSection } from "components/FormSection";
import { TextField } from "components/TextField";
import { useStringMax, useStringRequired } from "hooks/use-validation-input";
import { useContextService } from "hooks/use-context-service";
import { showNotification as showNotificationService } from "services/notification";
import { USER_QUERY, useUser } from "hooks/use-user";
import { useMutation } from "react-query";
import { createTeam } from "../services/team";
import { queryClient } from "query";
import { UserInfoFormHint } from "components/UserInfoFormHint";
import { useHistory } from "react-router";
import { ValidationFailed } from "errors/ValidationFailed";

const useFormSchema = () =>
  z.object({
    name: pipe(z.string(), useStringMax(50), useStringRequired()),
  });

type FormSchema = z.infer<ReturnType<typeof useFormSchema>>;

export const CreateTeamForm = () => {
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    reset,
    setError,
    setFocus,
    formState: { errors, isSubmitting },
  } = useForm<FormSchema>({
    resolver: zodResolver(useFormSchema()),
  });

  const { data: userResponse } = useUser();

  const showNotification = useContextService(showNotificationService);

  const history = useHistory();

  const createTeamMutation = useMutation(useContextService(createTeam), {
    onSuccess: (response) => {
      queryClient.setQueryData(USER_QUERY, response);

      showNotification({
        title: t("Successfully created"),
        message: t("Your new team is ready."),
      });

      history.push("/teams/edit");
    },
    onError: (error) => {
      if (error instanceof ValidationFailed) {
        reset();

        setError("name", {
          message: error.getMessages()?.name[0] || t("Something went wrong"),
        });

        setFocus("name");

        return new Promise(() => {});
      }
    },
  });

  return (
    <form
      onSubmit={handleSubmit((data) => createTeamMutation.mutateAsync(data))}
    >
      <FormSection
        title={t("Team details")}
        description={t("Create a new team to collaborate with others.")}
        footer={
          <Button type="submit" disabled={isSubmitting} loading={isSubmitting}>
            {t("Create")}
          </Button>
        }
      >
        <div className="max-w-xl space-y-4">
          {userResponse?.data?.data && (
            <UserInfoFormHint
              label={t("Team owner")}
              name={userResponse?.data?.data?.fullName}
              avatar={userResponse?.data?.data?.avatar}
              email={userResponse?.data?.data?.email}
            />
          )}
        </div>
        <div className="grid gap-4 lg:grid-cols-2">
          <TextField
            {...register("name")}
            label={t("Team name")}
            error={errors.name}
            required
          />
        </div>
      </FormSection>
    </form>
  );
};
