import classNames from "classnames";
import { PropsWithoutRef } from "react";
import { useTranslation } from "react-i18next";

type Variant = "primary" | "secondary" | "actions";

export interface TableCellProps
  extends Omit<PropsWithoutRef<JSX.IntrinsicElements["div"]>, "className"> {
  variant?: Variant;
  widthClassName?: string;
}

const variantClassNames: Record<Variant, string> = {
  primary: "font-medium text-gray-900",
  secondary: "text-gray-500",
  actions: "text-right space-x-4 ml-auto",
};

export const TableCell = ({
  children,
  widthClassName,
  variant = "primary",
}: TableCellProps) => {
  const { t } = useTranslation();

  return (
    <div
      className={classNames(
        "px-6 py-4 text-sm break-words",
        variantClassNames[variant],
        widthClassName
      )}
    >
      {children || <span className="text-gray-400">{t("N/A")}</span>}
    </div>
  );
};
