import { useQuery } from "react-query";
import { getAccount } from "services/user";
import { useContextService } from "./use-context-service";

export const USER_QUERY = "user";

export const useUser = () =>
  useQuery(USER_QUERY, useContextService(getAccount), {
    notifyOnChangeProps: "tracked",
  });
