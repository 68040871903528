import { Context, ResponsePayload } from "types";

export const getAppointment =
  ({ apiClient }: Context) =>
  (id: string) =>
    apiClient<
      ResponsePayload<App.Domain.API.OnlineAppointments.Resources.AppointmentPaymentResource>
    >({
      url: `/online-appointment/pay/${id}`,
      method: "GET",
    });

