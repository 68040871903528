import { useTranslation } from "react-i18next";
import { useContextService } from "hooks/use-context-service";
import { showNotification as showNotificationService } from "services/notification";
import { useMutation } from "react-query";
import { deleteClient } from "../services/client";
import { queryClient } from "query";
import { CLIENT_QUERY, useClient } from "../../../hooks/use-client";
import { CLIENTS_QUERY } from "domain/clients/domain/list/components/ClientsTable";
import { useHistory } from "react-router";
import { FormSection } from "components/FormSection";
import { Button } from "components/Button";
import { Modal } from "components/Modal";
import { PromptModalContent } from "components/PromptModalContent";
import { useModal } from "hooks/use-modal";
import { APPOINTMENT_CLIENTS_QUERY } from "domain/calendar/components/ClientSelectForm";

export const DeleteClientForm = () => {
  const { t } = useTranslation();

  const showNotification = useContextService(showNotificationService);

  const { data: clientResponse } = useClient();

  const history = useHistory();

  const { modalVisible, openModal, closeModal } = useModal();

  const deleteClientMutation = useMutation(useContextService(deleteClient), {
    onSuccess: () => {
      closeModal();

      history.push("/clients");

      setTimeout(() => {
        queryClient.resetQueries(CLIENT_QUERY);

        queryClient.resetQueries(CLIENTS_QUERY);

        queryClient.resetQueries(APPOINTMENT_CLIENTS_QUERY);
      });

      showNotification({
        title: t("Successfully deleted"),
        message: t("You deleted the client."),
      });
    },
  });

  return (
    <>
      <Modal visible={modalVisible} onClose={closeModal}>
        <PromptModalContent
          title={t("Are you sure?")}
          description={t("You are about to delete {{name}}.", {
            name: clientResponse?.data?.data?.fullName,
          })}
          actions={
            <>
              <Button
                variant="white"
                onClick={closeModal}
                disabled={deleteClientMutation.isLoading}
                loading={deleteClientMutation.isLoading}
              >
                {t("Cancel")}
              </Button>
              <Button
                variant="danger"
                onClick={() =>
                  deleteClientMutation.mutateAsync(
                    clientResponse?.data?.data?.id!
                  )
                }
                disabled={deleteClientMutation.isLoading}
                loading={deleteClientMutation.isLoading}
                withSpinner
              >
                {t("Delete")}
              </Button>
            </>
          }
        />
      </Modal>
      <FormSection
        withCard={false}
        title={t("Delete Client")}
        description={t("Permanently delete this client.")}
      >
        <div className="max-w-xl mb-4">
          <p className="text-sm text-gray-700">
            {t(
              "Once a client is deleted, all of its resources and data will be permanently deleted. Before deleting this client, please download any data or information regarding this client that you wish to retain."
            )}
          </p>
        </div>
        <Button
          variant="danger"
          disabled={deleteClientMutation.isLoading}
          loading={deleteClientMutation.isLoading}
          onClick={openModal}
          withSpinner
        >
          {t("Delete client")}
        </Button>
      </FormSection>
    </>
  );
};
