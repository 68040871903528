import { useMemo } from "react";
import { Column } from "react-table";
import { Placeholder } from "./Placeholder";
import { Table } from "./Table";
import { TableCell } from "./TableCell";

const placeholderWidthClassNames = [
  "w-32 lg:w-3/4",
  "w-16 lg:w-2/3",
  "w-40 lg:w-1/2",
];

export const TablePlaceholder = () => {
  const columns = useMemo<Column[]>(
    () =>
      [...Array(5)].map((item, index) => ({
        id: index.toString(),
        Header: (
          <Placeholder
            widthClassName={
              placeholderWidthClassNames[
                Math.floor(Math.random() * placeholderWidthClassNames.length)
              ]
            }
            heightClassName="h-4"
          />
        ),
        Cell: () => (
          <TableCell>
            <Placeholder
              widthClassName={
                placeholderWidthClassNames[
                  Math.floor(Math.random() * placeholderWidthClassNames.length)
                ]
              }
              heightClassName="h-4"
              bgClassName="bg-gray-100"
            />
          </TableCell>
        ),
      })),
    []
  );

  return <Table columns={columns} data={[...Array(15)].fill({})} />;
};
