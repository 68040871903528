import { Button } from "components/Button";
import { ClientForm } from "domain/clients/components/ClientForm";
import { createClient } from "domain/clients/domain/create/services/client";
import { CLIENTS_QUERY } from "domain/clients/domain/list/components/ClientsTable";
import { getClients } from "domain/clients/domain/list/services/clients";
import { useContextService } from "hooks/use-context-service";
import { useUser } from "hooks/use-user";
import { queryClient } from "query";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { ServiceReturnType } from "types";
import { BackToDetailsHeading } from "./BackToDetailsHeading";
import { APPOINTMENT_CLIENTS_QUERY } from "./ClientSelectForm";

export const CreateClientForm = ({
  onSucess,
  onCancel,
}: {
  onSucess: (data: ServiceReturnType<typeof createClient>) => void;
  onCancel: () => void;
}) => {
  const { t } = useTranslation();

  const { data: userResponse } = useUser();

  const createClientMutation = useMutation(useContextService(createClient), {
    onSuccess: (response) => {
      const clientsQueryKey = [
        CLIENTS_QUERY,
        userResponse?.data?.data?.currentTeam.id!,
        1,
      ];

      const appointmentClientsQueryKey = [
        APPOINTMENT_CLIENTS_QUERY,
        userResponse?.data?.data?.currentTeam.id!,
        "",
      ];

      if (queryClient.getQueryData(clientsQueryKey)) {
        queryClient.refetchQueries(clientsQueryKey);
      }

      if (queryClient.getQueryData(appointmentClientsQueryKey)) {
        queryClient.setQueryData(
          appointmentClientsQueryKey,
          (old): ServiceReturnType<typeof getClients> => {
            const oldCache = old as ServiceReturnType<typeof getClients>;

            return {
              ...oldCache,
              data: {
                ...oldCache.data!,
                data: [response.data!.data!, ...oldCache.data!.data],
              },
            };
          }
        );
      }

      onSucess(response);
    },
  });

  return (
    <div className="space-y-4">
      <BackToDetailsHeading
        disabled={createClientMutation.isLoading}
        loading={createClientMutation.isLoading}
        onClick={onCancel}
      />
      <ClientForm
        variant="vertical"
        withCard={false}
        onSubmit={createClientMutation.mutateAsync}
        actions={
          <Button
            type="button"
            variant="white"
            disabled={createClientMutation.isLoading}
            loading={createClientMutation.isLoading}
            onClick={onCancel}
          >
            {t("Back to details")}
          </Button>
        }
      />
    </div>
  );
};
