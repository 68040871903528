import { AppContext } from "context";
import { useCallback, useContext } from "react";
import { Context } from "types";

export const useContextService = <T extends (...args: any[]) => any>(
  service: (context: Context) => T
) => {
  const context = useContext(AppContext);

  const serviceInstance = service(context);

  return useCallback(serviceInstance, [serviceInstance]);
};
