import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { ClockIcon, TrashIcon } from "@heroicons/react/outline";
import { Button } from "components/Button";
import { SelectField } from "components/SelectField";
import { useTranslation } from "react-i18next";
import { parseInterval } from "utils/time";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useContextService } from "hooks/use-context-service";
import {
  deleteSchedule,
  getSchedules,
  updateSchedule,
} from "../services/schedules";
import { queryClient } from "query";
import { SCHEDULES_QUERY } from "./WeekSchedule";
import { useUser } from "hooks/use-user";
import { ServiceReturnType } from "types";
import { ChangeEvent } from "react";

const hours = [...new Array(23)]
  .reduce((result: number[]) => [...result, result[result.length - 1] + 1], [0])
  .map((item) => parseInterval(item * 60));

const minutes = [0, 15, 30].map(parseInterval);

const useFormSchema = () =>
  z.object({
    fromHours: z.string(),
    fromMinutes: z.string(),
    toHours: z.string(),
    toMinutes: z.string(),
  });

type FormSchema = z.infer<ReturnType<typeof useFormSchema>>;

export const ScheduleItem = ({
  schedule,
}: {
  schedule: App.Domain.API.Schedules.Resources.ScheduleResource;
}) => {
  const { t } = useTranslation();

  const { data: userResponse } = useUser();

  const from = parseInterval(schedule.from);

  const to = parseInterval(schedule.to);

  const {
    register,
    getValues,
    watch,
    formState: { errors },
  } = useForm<FormSchema>({
    resolver: zodResolver(useFormSchema()),
    defaultValues: {
      fromHours: from.hoursInterval.toString(),
      fromMinutes: from.minutes.toString(),
      toHours: to.hoursInterval.toString(),
      toMinutes: to.minutes.toString(),
    },
  });

  const fromHours = Number.parseInt(watch("fromHours"));

  const toHours = Number.parseInt(watch("toHours"));

  const deleteScheduleMutation = useMutation(
    useContextService(deleteSchedule),
    {
      onMutate: (id) => {
        queryClient.setQueryData(
          [SCHEDULES_QUERY, userResponse?.data?.data?.currentTeam.id!],
          (data): ServiceReturnType<typeof getSchedules> => {
            const oldData = data as ServiceReturnType<typeof getSchedules>;

            return {
              ...oldData,
              data: {
                ...oldData.data,
                data: oldData.data!.data.filter((item) => item.id !== id),
              },
            };
          }
        );
      },
    }
  );

  const updateScheduleMutation = useMutation(
    useContextService(updateSchedule),
    {
      onMutate: ({ id, data: payload }) => {
        queryClient.setQueryData(
          [SCHEDULES_QUERY, userResponse?.data?.data?.currentTeam.id!],
          (data): ServiceReturnType<typeof getSchedules> => {
            const oldData = data as ServiceReturnType<typeof getSchedules>;

            return {
              ...oldData,
              data: {
                ...oldData.data,
                data: oldData.data!.data.map((item) =>
                  item.id === id ? { ...item, ...payload } : item
                ),
              },
            };
          }
        );
      },
    }
  );

  const registerSelect = (fieldName: keyof FormSchema) => {
    const field = register(fieldName);

    return {
      ...field,
      onChange: (e: ChangeEvent<HTMLSelectElement>) => {
        field.onChange(e);

        const values = getValues();

        updateScheduleMutation.mutateAsync({
          id: schedule.id,
          data: {
            from:
              Number.parseInt(values.fromHours) +
              Number.parseInt(values.fromMinutes),
            to:
              Number.parseInt(values.toHours) +
              Number.parseInt(values.toMinutes),
          },
        });
      },
    };
  };

  return (
    <div className="flex flex-col justify-between pb-4 border-b lg:items-center lg:flex-row lg:pb-0 lg:border-b-0">
      <div className="flex">
        <div className="flex">
          <SelectField
            {...registerSelect("fromHours")}
            label={t("From")}
            required
            options={hours.map((item) => ({
              label: item.formattedHours,
              value: item.interval,
              disabled: item.interval >= toHours,
            }))}
            error={errors.fromHours}
          />
        </div>
        <div className="flex items-end">
          <div className="flex items-center">
            <span className="inline-block mx-2">:</span>
            <SelectField
              {...registerSelect("fromMinutes")}
              required
              options={minutes.map((item) => ({
                label: item.formattedMinutes,
                value: item.interval,
              }))}
              error={errors.fromMinutes}
            />
          </div>
        </div>
      </div>
      <div className="items-center flex-1 hidden mx-4 mt-5 lg:flex">
        <hr className="flex-1 border-gray-300" />
        <ClockIcon className="w-4 mx-4 text-gray-400" />
        <hr className="flex-1 border-gray-300" />
      </div>
      <div className="flex mt-4 lg:justify-end lg:mt-0">
        <div className="flex">
          <SelectField
            {...registerSelect("toHours")}
            label={t("To")}
            required
            options={hours.map((item) => ({
              label: item.formattedHours,
              value: item.interval,
              disabled: item.interval <= fromHours,
            }))}
            error={errors.fromMinutes}
          />
        </div>
        <div className="flex items-end">
          <div className="flex items-center">
            <span className="inline-block mx-2">:</span>
            <SelectField
              {...registerSelect("toMinutes")}
              required
              options={minutes.map((item) => ({
                label: item.formattedMinutes,
                value: item.interval,
              }))}
              error={errors.fromMinutes}
            />
            <div className="hidden ml-4 lg:block">
              <Button
                loading={deleteScheduleMutation.isLoading}
                disabled={deleteScheduleMutation.isLoading}
                variant="white"
                onClick={() => deleteScheduleMutation.mutateAsync(schedule.id)}
              >
                <TrashIcon className="h-5" />
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-4 lg:hidden">
        <Button
          loading={deleteScheduleMutation.isLoading}
          disabled={deleteScheduleMutation.isLoading}
          variant="white"
          size="large"
          onClick={() => deleteScheduleMutation.mutateAsync(schedule.id)}
        >
          <TrashIcon className="h-5 mr-2" /> {t("Delete range")}
        </Button>
      </div>
    </div>
  );
};
