import classNames from "classnames";
import { forwardRef, PropsWithoutRef } from "react";
import { LoadingSpinner } from "./LoadingSpinner";

type Size = "small" | "medium" | "large";

type Variant = "primary" | "secondary" | "white" | "danger";

export interface ButtonProps
  extends Omit<PropsWithoutRef<JSX.IntrinsicElements["button"]>, "className"> {
  size?: Size;
  variant?: Variant;
  loading?: boolean;
  withSpinner?: boolean;
}

const sizeClassNames: Record<Size, string> = {
  small: "px-3 py-2 text-sm leading-4",
  medium: "px-5 py-2 text-sm",
  large: "px-5 py-2 text-base",
};

const variantClassNames: Record<Variant, string> = {
  primary:
    "border-transparent text-white bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500",
  danger:
    "border-transparent text-white bg-red-600 hover:bg-red-700 focus:ring-red-500",
  secondary:
    "border-transparent text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:ring-indigo-500",
  white:
    "border-gray-300 text-gray-700 bg-white hover:bg-gray-50 focus:ring-indigo-500",
};

export const getButtonClassNames = ({
  size = "medium",
  variant = "primary",
  loading,
}:
  | (Pick<ButtonProps, "size" | "variant"> & Pick<ButtonProps, "loading">)
  | undefined = {}) =>
  classNames(
    "inline-flex items-center border font-medium justify-center rounded shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:opacity-50",
    {
      "cursor-wait": loading,
      "disabled:cursor-not-allowed": !loading,
    },
    sizeClassNames[size],
    variantClassNames[variant]
  );

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, size, variant, loading, withSpinner, ...props }, ref) => (
    <button
      ref={ref}
      {...props}
      className={getButtonClassNames({
        size,
        variant,
        loading,
      })}
    >
      {children}
      {loading && withSpinner && (
        <span className="inline-block ml-2">
          <LoadingSpinner className="w-3 h-3" />
        </span>
      )}
    </button>
  )
);
