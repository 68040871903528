import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { pipe } from "fp-ts/lib/function";
import { Button } from "components/Button";
import { FormSection } from "components/FormSection";
import { TextField } from "components/TextField";
import { useStringMax, useStringRequired } from "hooks/use-validation-input";
import { SelectField } from "components/SelectField";
import { useContextService } from "hooks/use-context-service";
import { showNotification as showNotificationService } from "services/notification";
import { USER_QUERY, useUser } from "hooks/use-user";
import { useMutation } from "react-query";
import { updateProfile } from "../services/profile";
import { queryClient } from "query";
import { useTimezones } from "hooks/use-timezones";

const useFormSchema = () =>
  z.object({
    firstName: pipe(z.string(), useStringMax(50), useStringRequired()),
    lastName: pipe(z.string(), useStringMax(50), useStringRequired()),
    phone: pipe(z.string(), useStringMax(100)).nullable(),
    address: pipe(z.string(), useStringMax(100)).nullable(),
    timezone: pipe(z.string(), useStringRequired()),
    email: z.any(),
  });

type FormSchema = z.infer<ReturnType<typeof useFormSchema>>;

export const ProfileForm = () => {
  const { t } = useTranslation();

  const { data: userResponse } = useUser();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormSchema>({
    resolver: zodResolver(useFormSchema()),
    defaultValues: {
      ...userResponse?.data?.data,
      timezone: userResponse?.data?.data?.timezone || "UTC",
    },
  });

  const showNotification = useContextService(showNotificationService);

  const updateProfileMutation = useMutation(useContextService(updateProfile), {
    onSuccess: (response) => {
      queryClient.setQueryData(USER_QUERY, response);

      showNotification({
        title: t("Successfully updated"),
        message: t("Your personal information was updated."),
      });
    },
  });

  const { data: timezonesResponse } = useTimezones();

  return (
    <form
      onSubmit={handleSubmit((data) => updateProfileMutation.mutateAsync(data))}
    >
      <FormSection
        title={t("Personal information")}
        description={t("Here you can edit your personal information.")}
        footer={
          <Button type="submit" disabled={isSubmitting} loading={isSubmitting}>
            {t("Submit")}
          </Button>
        }
      >
        <div className="grid gap-4 lg:grid-cols-2">
          <TextField
            {...register("firstName")}
            label={t("First name")}
            error={errors.firstName}
            required
          />
          <TextField
            {...register("lastName")}
            label={t("Last name")}
            error={errors.lastName}
            required
          />
          <TextField
            {...register("email")}
            label={t("Email")}
            error={errors.email}
            required
            disabled
          />
          <SelectField
            {...register("timezone")}
            name="timezone"
            label={t("Timezone")}
            required
            options={
              timezonesResponse?.data?.data.map((item) => ({
                label: item,
                value: item,
              })) || []
            }
          />
          <TextField
            {...register("address")}
            label={t("Address")}
            error={errors.address}
            optional
          />
          <TextField
            {...register("phone")}
            label={t("Phone")}
            error={errors.phone}
            optional
          />
        </div>
      </FormSection>
    </form>
  );
};
