import { intervalToDuration } from "date-fns";

export const parseInterval = (interval: number) => {
  const duration = intervalToDuration({
    start: 0,
    end: interval * 60 * 1000,
  });

  const formattedHours = `0${duration.hours}`.slice(-2);

  const formattedMinutes = `0${duration.minutes}`.slice(-2);

  return {
    hours: duration.hours!,
    minutes: duration.minutes!,
    formattedTime: `${formattedHours}:${formattedMinutes}`,
    formattedHours,
    formattedMinutes,
    interval,
    hoursInterval: duration.hours! * 60,
  };
};
