import { useTranslation } from "react-i18next";
import { FormSection } from "components/FormSection";
import { useContextService } from "hooks/use-context-service";
import { showNotification as showNotificationService } from "services/notification";
import { useUser } from "hooks/use-user";
import { TEAM_QUERY, useTeam } from "../hooks/use-team";
import { useMutation } from "react-query";
import { getTeam, removeMember } from "../services/team";
import { queryClient } from "query";
import { TextButton } from "components/TextButton";
import { Avatar } from "components/Avatar";
import { Modal } from "components/Modal";
import { PromptModalContent } from "components/PromptModalContent";
import { Button } from "components/Button";
import { ServiceReturnType } from "types";
import { useModal } from "hooks/use-modal";
import { useRoles } from "../hooks/use-roles";
import { EditMemberForm } from "./EditMemberForm";
import { ModalHeader } from "components/ModalHeader";

const Member = ({
  member,
  teamId,
  ownerId,
}: {
  member: App.Domain.API.Teams.Resources.MemberResource;
  teamId: string;
  ownerId: string;
}) => {
  const { t } = useTranslation();

  const { data: userResponse } = useUser();

  const showNotification = useContextService(showNotificationService);

  const removeMemberMutation = useMutation(useContextService(removeMember), {
    onMutate: () => {
      queryClient.setQueryData(
        [TEAM_QUERY, teamId],
        (old): ServiceReturnType<typeof getTeam> => {
          closeRemoveModal();

          const oldCache = old as ServiceReturnType<typeof getTeam>;

          return {
            ...oldCache,
            data: {
              data: {
                ...oldCache.data!.data!,
                members: oldCache.data!.data!.members.filter(
                  (item) => item.id !== member.id
                ),
              },
            },
          };
        }
      );

      showNotification({
        title: t("Successfully removed"),
        message: t("You removed the member."),
      });
    },
    onSuccess: (response) => {
      queryClient.setQueryData([TEAM_QUERY, teamId], response);
    },
  });

  const {
    modalVisible: removeModalVisible,
    openModal: openRemoveModal,
    closeModal: closeRemoveModal,
  } = useModal();

  const {
    modalVisible: updateModalVisible,
    openModal: openUpdateModal,
    closeModal: closeUpdateModal,
  } = useModal();

  const roles = useRoles();

  return (
    <>
      <Modal visible={removeModalVisible} onClose={closeRemoveModal}>
        <PromptModalContent
          title={t("Are you sure?")}
          description={t("You are about to remove {{name}} from the team.", {
            name: member.fullName,
          })}
          actions={
            <>
              <Button variant="white" onClick={closeRemoveModal}>
                {t("Cancel")}
              </Button>
              <Button
                variant="danger"
                onClick={() =>
                  removeMemberMutation.mutate({
                    teamId,
                    memberId: member.id,
                  })
                }
              >
                {t("Remove")}
              </Button>
            </>
          }
        />
      </Modal>
      <Modal visible={updateModalVisible} onClose={closeUpdateModal}>
        <ModalHeader onClose={closeUpdateModal}>
          {t("Edit member")} - {member.fullName}
        </ModalHeader>
        <EditMemberForm
          onSubmit={closeUpdateModal}
          onCancel={closeUpdateModal}
          memberId={member.id}
          defaultValues={{
            role: member.role || undefined,
          }}
        />
      </Modal>
      <div className="flex flex-col justify-between py-4 space-y-4 lg:space-y-0 lg:items-center lg:flex-row">
        <div className="flex items-center">
          <div className="flex-shrink-0">
            <Avatar size="large" name={member.fullName} src={member.avatar} />
          </div>
          <div className="flex-1 min-w-0 ml-3">
            <div className="text-base font-medium text-gray-800 truncate">
              {member.fullName}
            </div>
            {(member.role || ownerId === member.id) && (
              <div className="text-sm font-medium text-gray-500 truncate">
                {member.role ? roles.labels[member.role] : t("Owner")}
              </div>
            )}
          </div>
        </div>
        {ownerId !== member.id && userResponse?.data?.data?.id !== member.id && (
          <div className="space-x-4">
            <Button onClick={openUpdateModal} variant="white">
              {t("Edit member")}
            </Button>
            <TextButton
              disabled={removeMemberMutation.isLoading}
              loading={removeMemberMutation.isLoading}
              onClick={openRemoveModal}
              variant="danger"
            >
              {t("Remove member")}
            </TextButton>
          </div>
        )}
      </div>
    </>
  );
};

export const ManageMembers = () => {
  const { t } = useTranslation();

  const { data: userResponse } = useUser();

  const { data: teamResponse } = useTeam(
    userResponse?.data?.data?.currentTeam.id
  );

  return (
    <FormSection
      title={t("Members management")}
      description={t("Manage your team members.")}
    >
      <div className="-my-4 divide-y">
        {teamResponse?.data?.data?.members.map((item) => (
          <Member
            key={item.email}
            teamId={teamResponse.data?.data?.id!}
            ownerId={teamResponse.data?.data?.owner.id!}
            member={item}
          />
        ))}
      </div>
    </FormSection>
  );
};
