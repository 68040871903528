import { Heading } from "components/Heading";
import { useTranslation } from "react-i18next";
import { ProfileForm } from "./ProfileForm";
import { PasswordForm } from "./PasswordForm";
import { FormSectionDivider } from "components/FormSectionDivider";
import { Suspense } from "react";
import { FormSectionPlaceholder } from "components/FormSectionPlaceholder";
import { AvatarForm } from "./AvatarForm";
import { ContentWrapper } from "components/ContentWrapper";
import { HeadingWrapper } from "components/HeadingWrapper";
import { DeleteAccountForm } from "./DeleteAccountForm";

export const Container = () => {
  const { t } = useTranslation();

  return (
    <ContentWrapper>
      <HeadingWrapper>
        <Heading>{t("Profile Settings")}</Heading>
      </HeadingWrapper>
      <Suspense
        fallback={
          <>
            <FormSectionPlaceholder />
            <FormSectionDivider />
            <FormSectionPlaceholder />
            <FormSectionDivider />
            <FormSectionPlaceholder />
            <FormSectionDivider />
            <FormSectionPlaceholder />
          </>
        }
      >
        <ProfileForm />
        <FormSectionDivider />
        <AvatarForm />
        <FormSectionDivider />
        <PasswordForm />
        <FormSectionDivider />
        <DeleteAccountForm />
      </Suspense>
    </ContentWrapper>
  );
};
