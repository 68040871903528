import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { Button } from "components/Button";
import { useContextService } from "hooks/use-context-service";
import { showNotification as showNotificationService } from "services/notification";
import { useUser } from "hooks/use-user";
import { useMutation } from "react-query";
import { updateMember } from "../services/team";
import { queryClient } from "query";
import { TEAM_QUERY, useTeam } from "../hooks/use-team";
import { FieldLabel } from "components/FieldLabel";
import { RadioGroupField } from "components/RadioGroupField";
import { FieldError } from "components/FieldError";
import { roleEnum, useRoles } from "../hooks/use-roles";
import { FormActionsFooter } from "components/ModalFooter";

const useFormSchema = () =>
  z.object({
    role: roleEnum,
  });

type FormSchema = z.infer<ReturnType<typeof useFormSchema>>;

export const EditMemberForm = ({
  memberId,
  defaultValues,
  onSubmit,
  onCancel,
}: {
  memberId: string;
  defaultValues: Partial<FormSchema>;
  onSubmit: () => void;
  onCancel: () => void;
}) => {
  const { t } = useTranslation();

  const { data: userResponse } = useUser();

  const { data: teamResponse } = useTeam(
    userResponse?.data?.data?.currentTeam.id
  );

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm<FormSchema>({
    resolver: zodResolver(useFormSchema()),
    defaultValues,
  });

  const showNotification = useContextService(showNotificationService);

  const updateMemberMutation = useMutation(useContextService(updateMember), {
    onSuccess: (response) => {
      queryClient.setQueryData(
        [TEAM_QUERY, teamResponse?.data?.data?.id],
        response
      );

      showNotification({
        title: t("Successfully updated"),
        message: t("You updated the member."),
      });

      onSubmit();
    },
  });

  const roles = useRoles();

  return (
    <form
      onSubmit={handleSubmit((data) =>
        updateMemberMutation.mutateAsync({
          data,
          teamId: userResponse?.data?.data?.currentTeam.id!,
          memberId: memberId,
        })
      )}
    >
      <div className="grid gap-4">
        <div>
          <FieldLabel required htmlFor="role">
            {t("Role")}
          </FieldLabel>
          <Controller
            name="role"
            control={control}
            render={({ field }) => (
              <RadioGroupField
                label={t("Role")}
                options={[
                  {
                    name: roles.labels.MEMBER,
                    value: roleEnum.Enum.MEMBER,
                    description: roles.descriptions.MEMBER,
                  },
                  {
                    name: roles.labels.ADMIN,
                    value: roleEnum.Enum.ADMIN,
                    description: roles.descriptions.ADMIN,
                  },
                ]}
                {...field}
              />
            )}
          />
          {errors.role && <FieldError>{t("Please select a role")}</FieldError>}
        </div>
      </div>
      <FormActionsFooter>
        <Button variant="white" onClick={onCancel}>
          {t("Cancel")}
        </Button>
        <Button type="submit" disabled={isSubmitting} loading={isSubmitting}>
          {t("Update")}
        </Button>
      </FormActionsFooter>
    </form>
  );
};
