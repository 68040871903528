import { useContextService } from "hooks/use-context-service";
import { useMemo } from "react";
import { useQuery } from "react-query";
import { getTeam } from "../services/team";

export const TEAM_QUERY = "team";

export const useTeam = (teamId?: string) => {
  const getTeamService = useContextService(getTeam);

  const teamIdMemo = useMemo(() => teamId, [teamId]);

  return useQuery([TEAM_QUERY, teamIdMemo], () => getTeamService(teamIdMemo!), {
    enabled: !!teamIdMemo,
    notifyOnChangeProps: "tracked",
  });
};
