import { useMemo } from "react";
import { useHistory, useLocation } from "react-router";

const makeSearchParams = (queryParams: URLSearchParams, page: number) => {
  queryParams.set("page", page.toString());

  return queryParams.toString();
};

export const usePagination = () => {
  const location = useLocation();

  const history = useHistory();

  const queryParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  const page = useMemo(() => {
    const pageQuery = queryParams.get("page");

    return pageQuery && /^[1-9]\d*$/.test(pageQuery)
      ? Number.parseInt(pageQuery)
      : 1;
  }, [queryParams]);

  const nextPage = () => {
    history.push({
      search: "?" + makeSearchParams(queryParams, page + 1),
    });
  };

  const previousPage = () =>
    history.push({
      search: "?" + makeSearchParams(queryParams, page - 1 > 1 ? page - 1 : 1),
    });

  const setPage = (page: number) =>
    history.push({
      search: "?" + makeSearchParams(queryParams, page),
    });

  return {
    page,
    nextPage,
    previousPage,
    setPage,
  };
};
