import { Button } from "components/Button";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ActionsWrapper } from "./ActionsWrapper";
import DayPicker from "react-day-picker";
import {
  addDays,
  addMonths,
  differenceInCalendarDays,
  startOfWeek,
} from "date-fns";
import { SlotsForm } from "./SlotsForm";
import { Suspense } from "react";
import { LoadingSpinner } from "components/LoadingSpinner";
import { FieldLabel } from "components/FieldLabel";
import { WizardSchema } from "../hooks/use-wizard-schema";
import { Header } from "./Header";

export const DateForm = ({
  nextStep,
  previousStep,
  withActions = true,
  withHeader = true,
}: {
  nextStep?: () => void;
  previousStep?: () => void;
  withHeader?: boolean;
  withActions?: boolean;
}) => {
  const { control, watch, getValues, setValue } =
    useFormContext<WizardSchema>();

  const { t } = useTranslation();

  const date = watch("date");

  const slot = watch("slot");

  const { member } = getValues();

  return (
    <>
      {withHeader && (
        <Header
          title={t("Select Date")}
          description={t("The dates and times are shown in your local timezone.")}
        />
      )}
      <div className="flex flex-col max-w-xl px-4 mx-auto mt-4 space-y-4 sm:px-6 sm:space-y-0 sm:space-x-4 sm:flex-row">
        <div>
          <FieldLabel htmlFor="date" required>
            {t("Select Date")}
          </FieldLabel>
          <div className="border border-gray-200 rounded-md">
            <Controller
              control={control}
              name="date"
              render={({ field }) => (
                <DayPicker
                  {...field}
                  selectedDays={date}
                  onDayClick={(date, mods) => {
                    if (!mods.disabled) {
                      field.onChange(date);

                      setValue("slot", "");
                    }
                  }}
                  firstDayOfWeek={1}
                  disabledDays={[
                    { before: addDays(new Date(), 1) },
                    { after: addMonths(new Date(), 2) },
                    (date: Date) => {
                      return !member.workingDays.includes(
                        differenceInCalendarDays(
                          date,
                          startOfWeek(date, {
                            locale: { options: { weekStartsOn: 1 } },
                          })
                        )
                      );
                    },
                  ]}
                />
              )}
            />
          </div>
        </div>
        {date && (
          <Suspense
            fallback={
              <>
                <LoadingSpinner className="w-8 h-8 mt-6 text-indigo-600" />
              </>
            }
          >
            <SlotsForm key={date.toISOString()} />
          </Suspense>
        )}
      </div>
      {withActions && (
        <ActionsWrapper>
          <Button type="button" variant="white" onClick={previousStep}>
            {t("Back")}
          </Button>
          <Button type="button" disabled={!slot} onClick={nextStep}>
            {t("Next step")}
          </Button>
        </ActionsWrapper>
      )}
    </>
  );
};
