import classNames from "classnames";
import { PropsWithoutRef } from "react";

type Size = "medium" | "large" | "xl" | "xs";

export interface AvatarProps
  extends Omit<
    PropsWithoutRef<JSX.IntrinsicElements["img"]>,
    "className" | "src"
  > {
  name?: string;
  size?: Size;
  src?: string | null;
}

const sizeClassNames: Record<Size, string> = {
  xs: "w-5 h-5",
  medium: "w-8 h-8",
  large: "w-10 h-10",
  xl: "w-14 h-14",
};

export const Avatar = ({ name, alt, src, size = "medium" }: AvatarProps) => (
  <img
    className={classNames(
      "rounded-full object-cover object-center",
      sizeClassNames[size]
    )}
    src={src || (name ? `https://eu.ui-avatars.com/api/?name=${name}` : "")}
    alt={alt || name}
  />
);
