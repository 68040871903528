import { useTranslation } from "react-i18next";
import { z } from "zod";

export const roles: [App.Enums.Role, ...App.Enums.Role[]] = ["ADMIN", "MEMBER"];

export const roleEnum = z.enum(roles);

export const useRoles = (): {
  labels: Record<App.Enums.Role, string>;
  descriptions: Record<App.Enums.Role, string>;
} => {
  const { t } = useTranslation();

  return {
    labels: {
      ADMIN: t("Administrator"),
      MEMBER: t("Member"),
    },
    descriptions: {
      ADMIN: t("This type user can access all data inside a team."),
      MEMBER: t("This type user has limited access to the data inside a team."),
    },
  };
};
