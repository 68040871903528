import { Switch as ReactSwitch } from "@headlessui/react";
import classNames from "classnames";
import { ComponentProps, forwardRef } from "react";

export const Switch = forwardRef<
  HTMLButtonElement,
  ComponentProps<typeof ReactSwitch>
>((props, ref) => (
  <ReactSwitch
    {...props}
    className={classNames(
      props.checked ? "bg-indigo-600" : "bg-gray-200",
      "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
    )}
  >
    <span
      aria-hidden="true"
      className={classNames(
        props.checked ? "translate-x-5" : "translate-x-0",
        "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
      )}
    />
  </ReactSwitch>
));
