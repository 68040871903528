import { Badge } from "components/Badge";
import { LoadingTableHeader } from "components/LoadingTableHeader";
import { Pagination } from "components/Pagination";
import { Table } from "components/Table";
import { TableCell } from "components/TableCell";
import { format } from "date-fns";
import { useAppointmentStateBadges } from "hooks/use-appointment-state-badges";
import { usePagination } from "hooks/use-pagination";
import { useContextService } from "hooks/use-context-service";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { CellProps } from "react-table";
import { useClient } from "../../../hooks/use-client";
import { getAppointments } from "../services/appointments";

export const CLIENT_APPOINTMENTS_QUERY = "client_appointments";

export const AppointmentsTable = () => {
  const { page, nextPage, previousPage } = usePagination();

  const getAppointmentsService = useContextService(getAppointments);

  const { data: clientResponse } = useClient();

  const {
    data: appointmentsResponse,
    isFetching,
    isPreviousData,
  } = useQuery(
    [CLIENT_APPOINTMENTS_QUERY, clientResponse?.data?.data?.id!, page],
    () =>
      getAppointmentsService({
        data: { page },
        id: clientResponse?.data?.data?.id!,
      }),
    {
      keepPreviousData: true,
    }
  );

  const { t } = useTranslation();

  const appointmentStateBadges = useAppointmentStateBadges();

  const columns = useMemo(() => {
    return [
      {
        Header: t("Date"),
        Cell: ({
          row,
        }: CellProps<App.Domain.API.Appointments.Resources.AppointmentResource>) => (
          <TableCell widthClassName="w-48">
            {format(new Date(row.original.startDate), "d MMM yyyy")}
          </TableCell>
        ),
      },
      {
        Header: t("User"),
        Cell: ({
          row,
        }: CellProps<App.Domain.API.Appointments.Resources.AppointmentResource>) => (
          <TableCell widthClassName="w-48">
            {row.original.user?.fullName}
          </TableCell>
        ),
      },
      {
        Header: t("Start Time"),
        Cell: ({
          row,
        }: CellProps<App.Domain.API.Appointments.Resources.AppointmentResource>) => (
          <TableCell widthClassName="w-48">
            {format(new Date(row.original.startDate), "HH:mm")}
          </TableCell>
        ),
      },
      {
        Header: t("End Time"),
        Cell: ({
          row,
        }: CellProps<App.Domain.API.Appointments.Resources.AppointmentResource>) => (
          <TableCell widthClassName="w-48">
            {format(new Date(row.original.endDate), "HH:mm")}
          </TableCell>
        ),
      },
      {
        Header: t("Appointment Status"),
        Cell: ({
          row,
        }: CellProps<App.Domain.API.Appointments.Resources.AppointmentResource>) => (
          <TableCell widthClassName="w-96">
            <Badge variant={appointmentStateBadges[row.original.state].variant}>
              {appointmentStateBadges[row.original.state].label}
            </Badge>
          </TableCell>
        ),
      },
      {
        id: "actions",
        Header: <LoadingTableHeader loading={isFetching} />,
      },
    ];
  }, [t, isFetching, appointmentStateBadges]);

  return (
    <>
      <Table columns={columns} data={appointmentsResponse?.data?.data!} />
      <Pagination
        page={page}
        isPreviousData={isPreviousData}
        hasMorePages={appointmentsResponse?.data?.meta.hasMorePages}
        loading={isFetching}
        onNext={nextPage}
        onPrevious={previousPage}
      />
    </>
  );
};
