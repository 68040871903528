import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useTranslation } from "react-i18next";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { pipe } from "fp-ts/lib/function";
import { Button } from "components/Button";
import { useStringMax, useStringRequired } from "hooks/use-validation-input";
import { TextAreaField } from "components/TextAreaField";
import { nullableString } from "utils/zod";
import { SelectField } from "components/SelectField";
import { useUser } from "hooks/use-user";
import { useTeam } from "domain/teams/domain/edit/hooks/use-team";
import { FormActionsFooter } from "components/ModalFooter";
import { FieldLabel } from "components/FieldLabel";
import { Suspense, useEffect, useState, useRef, useMemo } from "react";
import {
  CheckIcon,
  CursorClickIcon,
  PencilAltIcon,
  TrashIcon,
} from "@heroicons/react/outline";
import { FieldError } from "components/FieldError";
import {
  addDays,
  addMonths,
  differenceInCalendarDays,
  eachDayOfInterval,
  endOfMonth,
  format,
  getDay,
  getDaysInMonth,
  isBefore,
  isEqual,
  isSameDay,
  setDate,
  setHours,
  setMinutes,
  startOfDay,
  startOfMonth,
  startOfWeek,
} from "date-fns";
import { ClientSelectForm } from "./ClientSelectForm";
import { CreateClientForm } from "./CreateClientForm";
import { Placeholder } from "components/Placeholder";
import { BackToDetailsHeading } from "./BackToDetailsHeading";
import { getTextButtonClassNames, TextButton } from "components/TextButton";
import { Transition } from "@headlessui/react";
import { useAppointmentStateBadges } from "hooks/use-appointment-state-badges";
import { Badge } from "components/Badge";
import { Link } from "react-router-dom";
import currency from "currency.js";
import classNames from "classnames";
import { TextField } from "components/TextField";
import { useServices } from "hooks/use-services";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import { Switch } from "components/Switch";
import { addWeeks } from "@fullcalendar/common";
import { RecurringDatePickerForm } from "./RecurringDatePickerForm";
import { useMutation } from "react-query";
import { useContextService } from "hooks/use-context-service";
import { validateCollisions } from "../services/appointments";
import { ValidationFailed } from "errors/ValidationFailed";
import { addMinutes } from "date-fns/esm";

enum RecurringEntity {
  DAY = "DAY",
  WEEK = "WEEK",
  MONTH = "MONTH",
}

enum DayOfWeek {
  MONDAY,
  TUESDAY,
  WEDNESDAY,
  THURSDAY,
  FRIDAY,
  SATURDAY,
  SUNDAY,
}

enum MonthlyIntervals {
  SAME_DATE = "SAME_DATE",
  SAME_DAY = "SAME_DAY",
}

const getDefaultRecurringWeekDays = (date: Date) => [
  differenceInCalendarDays(
    date,
    startOfWeek(date, {
      locale: { options: { weekStartsOn: 1 } },
    })
  ),
];

const useFormSchema = () =>
  z.object({
    userId: pipe(z.string(), useStringRequired()),
    notes: pipe(z.string(), useStringMax(1000)).or(nullableString),
    date: z.date(),
    startTime: z.string(),
    endTime: z.string(),
    recurring: z.boolean(),
    recurringRepeatInterval: pipe(z.string()).or(nullableString),
    recurringRepeatEnity: z.nativeEnum(RecurringEntity),
    recurringRepeatAmount: pipe(z.string()).or(nullableString),
    recurringRepeatWeekDays: z.array(z.nativeEnum(DayOfWeek)),
    recurringRepeatMonthlyInterval: z.nativeEnum(MonthlyIntervals),
    client: z.object({
      id: pipe(z.string(), useStringRequired()),
      fullName: pipe(z.string(), useStringRequired()),
    }),
    serviceId: pipe(z.string(), useStringRequired()),
    price: pipe(z.string(), useStringRequired()),
  });

type FormSchema = z.infer<ReturnType<typeof useFormSchema>>;

type Mode = "create" | "edit";

export const AppointmentForm = ({
  onSubmit,
  onCancel,
  onCofirm,
  onComplete,
  onDelete,
  onDeleteAll,
  mode,
  appointment,
}: {
  onSubmit: (
    data: FormSchema & { recurringAppointments?: Date[] }
  ) => Promise<any>;
  onCancel: () => void;
  onCofirm: () => void;
  onComplete: () => void;
  onDelete: () => void;
  onDeleteAll: () => void;
  mode: Mode;
  appointment?: Omit<
    Partial<App.Domain.API.Appointments.Resources.AppointmentResource>,
    "user"
  > & {
    user: Partial<
      App.Domain.API.Appointments.Resources.AppointmentResource["user"]
    >;
  };
}) => {
  const { t } = useTranslation();

  const parsedAppointmentStartDate = appointment?.startDate
    ? new Date(appointment.startDate)
    : undefined;

  const form = useForm<FormSchema>({
    resolver: zodResolver(useFormSchema()),
    defaultValues: {
      client: {
        id: "",
        fullName: "",
      },
      recurringRepeatInterval: "1",
      recurringRepeatAmount: "3",
      recurringRepeatEnity: RecurringEntity.WEEK,
      recurringRepeatWeekDays: parsedAppointmentStartDate
        ? getDefaultRecurringWeekDays(parsedAppointmentStartDate)
        : [],
      recurring: false,
      recurringRepeatMonthlyInterval: MonthlyIntervals.SAME_DAY,
      ...appointment,
      date: parsedAppointmentStartDate,
      startTime: parsedAppointmentStartDate
        ? format(parsedAppointmentStartDate, "HH:mm")
        : undefined,
      endTime: appointment?.endDate
        ? format(new Date(appointment.endDate), "HH:mm")
        : undefined,
      price:
        appointment?.price || appointment?.price === 0
          ? currency(appointment.price, { fromCents: true }).toString()
          : "",
      userId: appointment?.user?.id || "",
      serviceId: appointment?.service?.id,
    },
  });

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    reset,
    getValues,
    formState: { errors, isSubmitting },
  } = form;

  const { data: userResponse } = useUser();

  const { data: teamResponse } = useTeam(
    userResponse?.data?.data?.currentTeam.id
  );

  const [screen, setScreen] = useState<
    | "details"
    | "select-client"
    | "create-client"
    | "select-service"
    | "edit-recurring-appointment"
  >("details");

  const detailsScreen = () => setScreen("details");

  const [selectedRecurringAppointment, setSelectedRecurringAppointment] =
    useState<Date>();

  const editRecurringAppointmentScreen = (date: Date) => {
    setScreen("edit-recurring-appointment");

    setSelectedRecurringAppointment(date);

    window.scrollTo(0, 0);
  };

  const date = watch("date");

  const parsedFormStartDate = useMemo(() => date, [date]);

  useEffect(() => {
    setValue(
      "recurringRepeatWeekDays",
      getDefaultRecurringWeekDays(parsedFormStartDate)
    );
  }, [setValue, parsedFormStartDate]);

  const startTime = watch("startTime");

  const endTime = watch("endTime");

  const client = watch("client") as Partial<FormSchema["client"]> | undefined;

  const serviceId = watch("serviceId");

  const price = watch("price");

  const userId = watch("userId");

  const notes = watch("notes");

  const recurring = watch("recurring");

  const recurringRepeatEnity = watch("recurringRepeatEnity");

  const recurringRepeatAmount = watch("recurringRepeatAmount");

  const recurringRepeatInterval = watch("recurringRepeatInterval");

  const recurringRepeatWeekDays = watch("recurringRepeatWeekDays");

  const recurringRepeatMonthlyInterval = watch(
    "recurringRepeatMonthlyInterval"
  );

  const recurringRepeatIntervalParsed = Number.parseInt(
    !recurringRepeatInterval || recurringRepeatInterval === "0"
      ? "1"
      : recurringRepeatInterval
  );

  const dayOfWeek = format(parsedFormStartDate, "eeee");

  const monthlyIntervalSameDate = format(parsedFormStartDate, "d");

  const monthInterval = eachDayOfInterval({
    start: startOfMonth(parsedFormStartDate),
    end: endOfMonth(parsedFormStartDate),
  });

  const dayOfWeekInMonth = monthInterval?.filter(
    (item) => getDay(item) === getDay(parsedFormStartDate)
  );

  const monthlyIntervalSameDayOrder = dayOfWeekInMonth?.findIndex((item) =>
    isSameDay(startOfDay(parsedFormStartDate), startOfDay(item))
  );

  const { data: servicesResponse } = useServices();

  const selectedMember = teamResponse?.data?.data?.members.find(
    (item) => item.id === userId
  );

  const selectedService = servicesResponse?.data?.data?.find(
    (item) => item.id === serviceId
  );

  useEffect(() => {
    if (selectedService?.duration && appointment?.startDate) {
      setValue(
        "endTime",
        format(
          addMinutes(new Date(appointment.startDate), selectedService.duration),
          "HH:mm"
        )
      );
    }
  }, [setValue, selectedService?.duration, appointment?.startDate]);

  useEffect(() => {
    if (recurringRepeatAmount && Number.parseInt(recurringRepeatAmount) > 20) {
      setValue("recurringRepeatAmount", "20");
    }
  }, [recurringRepeatAmount, setValue]);

  useEffect(() => {
    if (
      recurringRepeatInterval &&
      Number.parseInt(recurringRepeatInterval) > 20
    ) {
      setValue("recurringRepeatInterval", "20");
    }
  }, [recurringRepeatInterval, setValue]);

  const recurringAppointments = useMemo(() => {
    if (!selectedMember || !recurring) {
      return [];
    }

    const getResult = () => {
      const occurrences = Number.parseInt(
        !recurringRepeatAmount || recurringRepeatAmount === "0"
          ? "2"
          : recurringRepeatAmount
      );

      const result: Date[] = [];

      if (recurringRepeatEnity === RecurringEntity.DAY) {
        for (let i = 0; i < occurrences - 1; i++) {
          let newItem: Date = addDays(
            result[i - 1] || parsedFormStartDate!,
            recurringRepeatIntervalParsed
          );

          while (
            !selectedMember.workingDays.includes(
              differenceInCalendarDays(
                newItem,
                startOfWeek(newItem, {
                  locale: { options: { weekStartsOn: 1 } },
                })
              )
            )
          ) {
            newItem = addDays(newItem, 1);
          }

          result.push(newItem);
        }

        return [parsedFormStartDate, ...result];
      }

      if (recurringRepeatEnity === RecurringEntity.WEEK) {
        let numberOfWeeks = occurrences / recurringRepeatWeekDays.length;

        let loopDate = startOfWeek(date, {
          locale: { options: { weekStartsOn: 1 } },
        });

        for (let i = 0; i < numberOfWeeks; i++) {
          for (let j = 0; j < 7; j++) {
            if (recurringRepeatWeekDays.includes(j)) {
              if (isBefore(loopDate, startOfDay(date))) {
                numberOfWeeks = numberOfWeeks + 1;
              } else {
                result.push(loopDate);
              }
            }

            if (result.length >= occurrences) {
              return result;
            }

            loopDate = addDays(loopDate, 1);
          }

          loopDate = startOfWeek(
            addWeeks(loopDate, recurringRepeatIntervalParsed - 1),
            {
              locale: { options: { weekStartsOn: 1 } },
            }
          );
        }

        return result;
      }

      if (recurringRepeatEnity === RecurringEntity.MONTH) {
        if (recurringRepeatMonthlyInterval === MonthlyIntervals.SAME_DATE) {
          for (let i = 0; i < occurrences - 1; i++) {
            result.push(
              addMonths(
                result[i - 1] || parsedFormStartDate!,
                recurringRepeatIntervalParsed
              )
            );
          }

          return [parsedFormStartDate, ...result];
        } else {
          const selectedDayOfWeek = differenceInCalendarDays(
            parsedFormStartDate,
            startOfWeek(parsedFormStartDate, {
              locale: { options: { weekStartsOn: 1 } },
            })
          );

          for (let i = 1; i <= occurrences - 1; i++) {
            const nextMonth = startOfMonth(addMonths(parsedFormStartDate, i));

            const days = [];

            for (let j = 1; j <= getDaysInMonth(nextMonth); j++) {
              const incrementedDate = setDate(nextMonth, j);

              if (
                selectedDayOfWeek ===
                differenceInCalendarDays(
                  incrementedDate,
                  startOfWeek(incrementedDate, {
                    locale: { options: { weekStartsOn: 1 } },
                  })
                )
              ) {
                days.push(incrementedDate);
              }
            }

            if (days[monthlyIntervalSameDayOrder]) {
              result.push(days[monthlyIntervalSameDayOrder]);
            } else if (monthlyIntervalSameDayOrder === 4) {
              result.push(days[days.length - 1]);
            }
          }

          return [parsedFormStartDate, ...result];
        }
      }

      return [];
    };

    const startHours = Number.parseInt(startTime.split(":")[0]);

    const startMinutes = Number.parseInt(startTime.split(":")[1]);

    const result = getResult().map((item) =>
      setHours(setMinutes(item, startMinutes), startHours)
    );

    return result;
  }, [
    date,
    monthlyIntervalSameDayOrder,
    parsedFormStartDate,
    recurring,
    recurringRepeatAmount,
    recurringRepeatEnity,
    recurringRepeatIntervalParsed,
    recurringRepeatMonthlyInterval,
    recurringRepeatWeekDays,
    selectedMember,
    startTime,
  ]);

  const [statefulRecurringAppointments, setStatefulRecurringAppointments] =
    useState<Date[]>([]);

  useEffect(() => {
    setStatefulRecurringAppointments(recurringAppointments);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(recurringAppointments)]);

  useEffect(() => {
    detailsScreen();
  }, [client?.id]);

  const [deleteConfirmation, setDeleteConfirmation] = useState(false);

  const appointmentStateBadges = useAppointmentStateBadges();

  useEffect(() => {
    if (serviceId && mode === "create") {
      const service = servicesResponse?.data?.data.find(
        (item) => item.id === serviceId
      );

      if (service) {
        setValue(
          "price",
          currency(service.price, { fromCents: true }).toString(),
          {
            shouldValidate: true,
          }
        );
      }
    }
  }, [serviceId, servicesResponse?.data?.data, setValue, mode]);

  const payLink = `${window.location.origin}/online-appointment/pay/${appointment?.externalId}`;

  const payLinkInputRef = useRef<HTMLInputElement>(null);

  const copyPayLink = () => {
    navigator.clipboard.writeText(payLink);

    payLinkInputRef.current?.select();
  };

  const weekDayLabels = {
    [DayOfWeek.MONDAY]: t("M"),
    [DayOfWeek.TUESDAY]: t("T"),
    [DayOfWeek.WEDNESDAY]: t("W"),
    [DayOfWeek.THURSDAY]: t("T"),
    [DayOfWeek.FRIDAY]: t("F"),
    [DayOfWeek.SATURDAY]: t("S"),
    [DayOfWeek.SUNDAY]: t("S"),
  };

  const [collisions, setCollisions] = useState<Date[]>([]);

  const validateCollisionsMutation = useMutation(
    useContextService(validateCollisions),
    {
      onError: (error: any) => {
        if (
          error instanceof ValidationFailed &&
          error.getMessages().recurringAppointmentsCollisions
        ) {
          const collisions = error
            .getMessages()
            .recurringAppointmentsCollisions.map((item) => new Date(item));

          reset(getValues(), { keepIsSubmitted: false });

          setCollisions(collisions);

          return new Promise(() => {});
        }
      },
    }
  );

  if (screen === "create-client") {
    return (
      <CreateClientForm
        onSucess={(response) => {
          setValue("client", response.data!.data!);

          detailsScreen();
        }}
        onCancel={() => detailsScreen()}
      />
    );
  }

  if (
    screen === "edit-recurring-appointment" &&
    selectedMember &&
    selectedService &&
    selectedRecurringAppointment
  ) {
    return (
      <>
        <BackToDetailsHeading onClick={detailsScreen} />
        <RecurringDatePickerForm
          onCancel={detailsScreen}
          member={selectedMember}
          service={selectedService}
          selectedDate={selectedRecurringAppointment}
          onSubmit={(value) => {
            setStatefulRecurringAppointments(
              statefulRecurringAppointments.map((item) =>
                isEqual(item, selectedRecurringAppointment) ? value : item
              )
            );

            setSelectedRecurringAppointment(undefined);

            detailsScreen();
          }}
        />
      </>
    );
  }

  return (
    <FormProvider {...form}>
      {screen === "select-client" ? (
        <>
          <BackToDetailsHeading onClick={detailsScreen} />
          <div className="mt-4">
            <Suspense
              fallback={
                <>
                  <div className="space-y-1">
                    <Placeholder widthClassName="w-32" heightClassName="h-4" />
                    <Placeholder
                      widthClassName="w-full"
                      heightClassName="h-10"
                    />
                  </div>

                  <div className="mt-4 space-y-2">
                    {[...Array(5)].map((item, index) => (
                      <Placeholder
                        key={index}
                        widthClassName="w-full"
                        heightClassName="h-16"
                      />
                    ))}
                  </div>
                  <div className="mt-4">
                    <Placeholder
                      widthClassName="w-full"
                      heightClassName="h-10"
                    />
                  </div>
                  <div className="mt-8 text-right">
                    <Placeholder widthClassName="w-40" heightClassName="h-10" />
                  </div>
                </>
              }
            >
              <ClientSelectForm
                onCreateNewClient={() => setScreen("create-client")}
                onCancel={detailsScreen}
              />
            </Suspense>
          </div>
        </>
      ) : (
        <form
          onSubmit={handleSubmit(async (data) => {
            if (mode === "create") {
              const startHours = Number.parseInt(data.startTime.split(":")[0]);

              const startMinutes = Number.parseInt(
                data.startTime.split(":")[1]
              );

              const endHours = Number.parseInt(data.endTime.split(":")[0]);

              const endMinutes = Number.parseInt(data.endTime.split(":")[1]);

              await validateCollisionsMutation.mutateAsync({
                ...data,
                recurringAppointments: statefulRecurringAppointments.map(item => item.toISOString()),
                clientId: data.client.id,
                startDate: setHours(
                  setMinutes(data.date, startMinutes),
                  startHours
                ).toISOString(),
                endDate: setHours(
                  setMinutes(data.date, endMinutes),
                  endHours
                ).toISOString(),
                price: currency(data.price).intValue,
              });
            }

            onSubmit({
              ...data,
              recurringAppointments: statefulRecurringAppointments,
            });
          })}
        >
          {screen === "details" && (
            <>
              {mode === "edit" && (
                <div className="space-y-4 lg:col-span-2">
                  <hr />
                  {appointment?.state === "PENDING_CONFIRMATION" && (
                    <div className="-ml-1">
                      <TextButton
                        type="button"
                        disabled={isSubmitting}
                        loading={isSubmitting}
                        onClick={onCofirm}
                      >
                        <CheckIcon className="w-5 h-5 mr-2" />
                        {t("Confirm appointment")}
                      </TextButton>
                    </div>
                  )}
                  {appointment?.state === "CONFIRMED" && (
                    <div className="-ml-1">
                      <TextButton
                        type="button"
                        disabled={isSubmitting}
                        loading={isSubmitting}
                        onClick={onComplete}
                        variant="success"
                      >
                        <CheckIcon className="w-5 h-5 mr-2" />
                        {t("Complete appointment")}
                      </TextButton>
                    </div>
                  )}
                  {(!appointment || appointment.state !== "COMPLETED") && (
                    <>
                      <div className="flex flex-col justify-between space-y-4 sm:items-center sm:flex-row sm:space-y-0">
                        <div className="-ml-1">
                          <TextButton
                            variant="danger"
                            type="button"
                            disabled={isSubmitting}
                            loading={isSubmitting}
                            onClick={() => setDeleteConfirmation(true)}
                          >
                            <TrashIcon className="w-5 h-5 mr-2" />
                            {t("Delete appointment")}
                          </TextButton>
                        </div>

                        <Transition
                          show={deleteConfirmation}
                          enter="ease-out duration-300 transition"
                          enterFrom="opacity-0"
                          enterTo="opacity-100"
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <div className="flex items-center">
                            {appointment?.recurring ? (
                              <div className="flex flex-col space-y-4">
                                <Button
                                  variant="white"
                                  size="small"
                                  type="button"
                                  onClick={onDelete}
                                >
                                  {t("Delete this appointment")}
                                </Button>
                                {appointment?.recurring && (
                                  <Button
                                    variant="white"
                                    size="small"
                                    type="button"
                                    onClick={onDeleteAll}
                                  >
                                    {t("Delete all recurring appointments")}
                                  </Button>
                                )}
                              </div>
                            ) : (
                              <>
                                <span className="mr-2 text-sm text-gray-600">
                                  {t("Are you sure?")}
                                </span>
                                <Button
                                  variant="white"
                                  size="small"
                                  type="button"
                                  onClick={onDelete}
                                >
                                  {t("Yes, Delete")}
                                </Button>
                              </>
                            )}
                          </div>
                        </Transition>
                      </div>
                      <hr />
                    </>
                  )}
                </div>
              )}
              <div className="grid gap-4 mt-4 lg:grid-cols-2">
                {appointment?.state && (
                  <div className="lg:col-span-2">
                    <FieldLabel htmlFor="status">{t("Status")}</FieldLabel>
                    <p id="status">
                      <Badge
                        variant={
                          appointmentStateBadges[appointment.state].variant
                        }
                      >
                        {appointmentStateBadges[appointment.state].label}
                      </Badge>
                    </p>
                  </div>
                )}
                {(appointment?.state === "PENDING_PAYMENT" ||
                  appointment?.state === "PENDING_CLIENT_CONFIRMATION") && (
                  <div className="lg:col-span-2">
                    <div className="flex flex-col space-y-4 sm:items-end sm:flex-row sm:space-y-0 sm:space-x-3 lg:col-span-2">
                      <div className="flex-1">
                        <TextField
                          ref={payLinkInputRef}
                          label={t("Payment link")}
                          name="paymentLink"
                          value={payLink}
                          readOnly
                          onFocus={(e) => e.currentTarget.select()}
                        />
                      </div>
                      <div className="flex flex-col">
                        <Button
                          type="button"
                          variant="white"
                          onClick={copyPayLink}
                        >
                          {t("Copy link")}
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
                {!appointment || appointment.state !== "COMPLETED" ? (
                  <>
                    {userResponse?.data?.data?.currentTeam.isOwner && (
                      <div className="lg:col-span-2">
                        <SelectField
                          {...register("userId")}
                          label={t("Team member")}
                          required
                          error={errors.userId}
                          options={teamResponse?.data?.data?.members.map(
                            (item) => ({
                              label: item.fullName,
                              value: item.id,
                            })
                          )}
                        />
                      </div>
                    )}
                  </>
                ) : (
                  <div className="lg:col-span-2">
                    <FieldLabel htmlFor="userId">{t("Team member")}</FieldLabel>
                    <p id="userId" className="text-sm text-gray-600">
                      {selectedMember?.fullName}
                    </p>
                  </div>
                )}
                <div className="lg:col-span-2">
                  {!appointment || appointment.state !== "COMPLETED" ? (
                    <Controller
                      control={control}
                      name="date"
                      render={({ field }) => (
                        <DayPickerInput
                          {...field}
                          onDayChange={field.onChange}
                          dayPickerProps={{
                            disabledDays: [{ before: new Date() }],
                            firstDayOfWeek: 1,
                          }}
                          formatDate={(date) => format(date, "yyyy-MM-dd")}
                          inputProps={{
                            readOnly: true,
                            required: true,
                            label: t("Date"),
                            name: "date",
                          }}
                          component={TextField}
                        />
                      )}
                    />
                  ) : (
                    <>
                      <FieldLabel htmlFor="date">{t("Date")}</FieldLabel>
                      <p id="date" className="text-sm text-gray-600">
                        {parsedAppointmentStartDate &&
                          format(parsedAppointmentStartDate, "yyyy-MM-dd")}
                      </p>
                    </>
                  )}
                </div>
                <div>
                  <FieldLabel htmlFor="startTime">{t("From")}</FieldLabel>
                  <p id="startTime" className="text-sm text-gray-600">
                    {startTime}
                  </p>
                </div>
                <div>
                  <FieldLabel htmlFor="endTime">{t("To")}</FieldLabel>
                  <p id="endTime" className="text-sm text-gray-600">
                    {endTime}
                  </p>
                </div>
                <div className="flex flex-col lg:col-span-2">
                  <FieldLabel htmlFor="client-select" required>
                    {t("Client")}
                  </FieldLabel>
                  {mode === "create" ? (
                    <Button
                      disabled={isSubmitting}
                      type="button"
                      name="client-select"
                      variant="white"
                      onClick={() => setScreen("select-client")}
                    >
                      {client?.id ? (
                        <>
                          {client?.fullName}
                          <PencilAltIcon className="inline-block w-4 h-4 ml-1" />
                        </>
                      ) : (
                        t("Select Client")
                      )}
                    </Button>
                  ) : (
                    <div className="flex justify-start">
                      <Link
                        to={`/clients/${client?.id}`}
                        className={classNames(
                          getTextButtonClassNames(),
                          "-ml-1 py-1"
                        )}
                      >
                        {client?.fullName}
                      </Link>
                    </div>
                  )}
                  {errors.client && (
                    <FieldError>{errors.client.id?.message}</FieldError>
                  )}
                </div>
                {mode === "create" ? (
                  <>
                    <div className="lg:col-span-2">
                      <SelectField
                        label={t("Service")}
                        {...register("serviceId")}
                        error={errors.serviceId}
                        options={
                          servicesResponse?.data?.data.map((item) => ({
                            label: item.name,
                            value: item.id,
                          })) || []
                        }
                        required
                      />
                    </div>
                    <div className="lg:col-span-2">
                      <TextField
                        label={t("Price")}
                        {...register("price")}
                        error={errors.price}
                        type="number"
                        step="any"
                        min="0"
                        max="1000"
                        required
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div>
                      <FieldLabel htmlFor="serviceId">
                        {t("Service")}
                      </FieldLabel>
                      <p
                        id="serviceId"
                        className="text-sm text-gray-600 truncate"
                      >
                        {servicesResponse?.data?.data.find(
                          (item) => item.id === serviceId
                        )?.name || t("N/A")}
                      </p>
                    </div>
                    <div>
                      <FieldLabel htmlFor="price">{t("Price")}</FieldLabel>
                      <p id="price" className="text-sm text-gray-600">
                        {price}
                      </p>
                    </div>
                  </>
                )}
                <div className="lg:col-span-2">
                  {!appointment || appointment.state !== "COMPLETED" ? (
                    <TextAreaField
                      {...register("notes")}
                      label={t("Notes")}
                      error={errors.notes}
                      optional
                    />
                  ) : (
                    <>
                      <FieldLabel htmlFor="price">{t("Notes")}</FieldLabel>
                      <p id="notes" className="text-sm text-gray-600">
                        {notes || t("N/A")}
                      </p>
                    </>
                  )}
                </div>
                {mode === "create" &&
                  selectedMember &&
                  selectedMember.workingDays.length > 0 && (
                    <>
                      <div className="lg:col-span-2">
                        <FieldLabel htmlFor="recurring">
                          {t("Recurring")}
                        </FieldLabel>
                        <Controller
                          control={control}
                          name="recurring"
                          render={({ field }) => (
                            <Switch checked={field.value} {...field} />
                          )}
                        />
                      </div>
                      {recurring && (
                        <>
                          <div className="lg:col-span-2">
                            <FieldLabel
                              required
                              htmlFor="recurringRepeatInterval"
                            >
                              {t("Repeat every")}
                            </FieldLabel>
                            <div className="flex space-x-4">
                              <div className="w-24">
                                <TextField
                                  {...register("recurringRepeatInterval")}
                                  error={errors.recurringRepeatInterval}
                                  required
                                  step="1"
                                  min="1"
                                  max="20"
                                  type="number"
                                  placeholder="1"
                                />
                              </div>
                              <div className="flex-1">
                                <SelectField
                                  {...register("recurringRepeatEnity")}
                                  error={errors.recurringRepeatEnity}
                                  options={[
                                    {
                                      label:
                                        recurringRepeatIntervalParsed > 1
                                          ? t("Days").toString()
                                          : t("Day").toString(),
                                      value: RecurringEntity.DAY,
                                    },
                                    {
                                      label:
                                        recurringRepeatIntervalParsed > 1
                                          ? t("Weeks").toString()
                                          : t("Week").toString(),
                                      value: RecurringEntity.WEEK,
                                    },
                                    {
                                      label:
                                        recurringRepeatIntervalParsed > 1
                                          ? t("Months").toString()
                                          : t("Month").toString(),
                                      value: RecurringEntity.MONTH,
                                    },
                                  ]}
                                  required
                                />
                              </div>
                            </div>
                          </div>
                          {recurringRepeatEnity === RecurringEntity.MONTH && (
                            <div className="lg:col-span-2">
                              <SelectField
                                label={t("Repeat on")}
                                {...register("recurringRepeatMonthlyInterval")}
                                error={errors.recurringRepeatMonthlyInterval}
                                options={[
                                  {
                                    label: t("Monthly on day {{date}}", {
                                      date: monthlyIntervalSameDate,
                                    }).toString(),
                                    value: MonthlyIntervals.SAME_DATE,
                                  },
                                  {
                                    label: t("Monthly on {{day}}", {
                                      day:
                                        (monthlyIntervalSameDayOrder !==
                                        undefined
                                          ? [
                                              t("first"),
                                              t("second"),
                                              t("third"),
                                              t("fourth"),
                                              t("last"),
                                            ][monthlyIntervalSameDayOrder]
                                          : "") + ` ${dayOfWeek}`,
                                    }).toString(),
                                    value: MonthlyIntervals.SAME_DAY,
                                  },
                                ]}
                                required
                              />
                            </div>
                          )}
                          {recurringRepeatEnity === RecurringEntity.WEEK && (
                            <div className="lg:col-span-2">
                              <FieldLabel
                                required
                                htmlFor="recurringRepeatWeekDays"
                              >
                                {t("Repeat on")}
                              </FieldLabel>
                              <Controller
                                control={control}
                                name="recurringRepeatWeekDays"
                                render={({ field }) => (
                                  <div className="flex space-x-2">
                                    {[
                                      DayOfWeek.MONDAY,
                                      DayOfWeek.TUESDAY,
                                      DayOfWeek.WEDNESDAY,
                                      DayOfWeek.THURSDAY,
                                      DayOfWeek.FRIDAY,
                                      DayOfWeek.SATURDAY,
                                      DayOfWeek.SUNDAY,
                                    ].map((day) => (
                                      <button
                                        type="button"
                                        className={classNames(
                                          "rounded-full w-6 h-6 flex items-center justify-center text-xs font-medium focus:outline-none shadow",
                                          field.value.includes(day)
                                            ? "bg-indigo-600 text-white"
                                            : "bg-gray-300 text-gray-600"
                                        )}
                                        key={day}
                                        onClick={() => {
                                          const value = field.value.includes(
                                            day
                                          )
                                            ? field.value.filter(
                                                (item) => item !== day
                                              )
                                            : [...field.value, day];

                                          if (value.length === 0) {
                                            field.onChange(
                                              getDefaultRecurringWeekDays(
                                                parsedFormStartDate
                                              )
                                            );
                                          } else {
                                            field.onChange(value.sort());
                                          }
                                        }}
                                      >
                                        {weekDayLabels[day]}
                                      </button>
                                    ))}
                                  </div>
                                )}
                              />
                            </div>
                          )}
                          <div className="lg:col-span-2">
                            <FieldLabel
                              required
                              htmlFor="recurringRepeatAmount"
                            >
                              {t("Ends after")}
                            </FieldLabel>
                            <div className="flex items-center space-x-4">
                              <div className="w-24">
                                <TextField
                                  {...register("recurringRepeatAmount")}
                                  error={errors.recurringRepeatAmount}
                                  required
                                  step="1"
                                  min="2"
                                  max="20"
                                  placeholder="2"
                                  type="number"
                                />
                              </div>
                              <span className="text-sm text-gray-600">
                                {t("Occurrences")}
                              </span>
                            </div>
                          </div>
                        </>
                      )}
                      {statefulRecurringAppointments.length > 0 && (
                        <div className="py-4 border-t border-b lg:col-span-2">
                          <FieldLabel htmlFor="recurringAppointments">
                            {t("Recurring Appointments Preview")}
                          </FieldLabel>
                          <div
                            id="recurringAppointments"
                            className="grid grid-cols-2 gap-4 lg:grid-cols-3"
                          >
                            {statefulRecurringAppointments.map(
                              (item, index) => {
                                const dateFormatted = format(
                                  item,
                                  "yyyy-MM-dd"
                                );

                                const timeFormatted = format(item, "HH:mm");

                                const collision = collisions.some(
                                  (collision) =>
                                    dateFormatted ===
                                      format(collision, "yyyy-MM-dd") &&
                                    timeFormatted === format(collision, "HH:mm")
                                );

                                return (
                                  <div key={index} className="flex flex-col">
                                    <div
                                      className={classNames(
                                        "p-2 text-sm font-medium text-center text-gray-600 bg-gray-100 border border-gray-300 shadow-sm",
                                        {
                                          rounded: !collision,
                                          "rounded-t": collision,
                                        }
                                      )}
                                    >
                                      <p>{dateFormatted}</p>
                                      <span className="text-xs text-gray-500">
                                        {timeFormatted}
                                      </span>
                                    </div>
                                    {collision && (
                                      <button
                                        onClick={() =>
                                          editRecurringAppointmentScreen(item)
                                        }
                                        className="relative w-full text-sm font-medium text-yellow-800 bg-yellow-200 border-b border-l border-r border-yellow-500 rounded-b focus:outline-none"
                                        type="button"
                                      >
                                        {t("Fix Collission")}{" "}
                                        <CursorClickIcon className="absolute top-0 right-0 w-5 h-5 mr-1" />
                                      </button>
                                    )}
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </div>
                      )}
                    </>
                  )}
                {/* {(!appointment || appointment.state !== "COMPLETED") && (
                  <div className="lg:col-span-2">
                    <p className="text-sm text-gray-600">
                      {mode === "create"
                        ? t(
                            "A payment link will be sent to the client via email upon appointment creation."
                          )
                        : t(
                            "Any updates to the appointment's date or time will send a confirmation link to the client via email."
                          )}
                    </p>
                  </div>
                )} */}

                <div className="-mt-5 lg:col-span-2">
                  <FormActionsFooter>
                    <Button
                      onClick={onCancel}
                      type="button"
                      disabled={isSubmitting}
                      loading={isSubmitting}
                      variant="white"
                    >
                      {t("Close")}
                    </Button>
                    {(!appointment || appointment.state !== "COMPLETED") && (
                      <Button
                        type="submit"
                        disabled={isSubmitting}
                        loading={isSubmitting}
                        withSpinner
                      >
                        {mode === "create" ? t("Create") : t("Update")}
                      </Button>
                    )}
                  </FormActionsFooter>
                </div>
              </div>
            </>
          )}
        </form>
      )}
    </FormProvider>
  );
};
