import { Heading } from "components/Heading";
import { useTranslation } from "react-i18next";
import { Suspense } from "react";
import { ServicesTable } from "./ServicesTable";
import { ContentWrapper } from "components/ContentWrapper";
import { TablePlaceholder } from "components/TablePlaceholder";
import { Card } from "components/Card";
import { useUser } from "hooks/use-user";
import { getButtonClassNames } from "components/Button";
import { HeadingWrapper } from "components/HeadingWrapper";
import { NegateCardPadding } from "components/NegateCardPadding";
import { Placeholder } from "components/Placeholder";
import { Link } from "react-router-dom";

const PageHeading = () => {
  const { t } = useTranslation();

  return <Heading>{t("Services")}</Heading>;
};

export const Content = () => {
  const { data: userResponse } = useUser();

  const { t } = useTranslation();

  return (
    <>
      <HeadingWrapper>
        <PageHeading />
        <Link to="/services/create" className={getButtonClassNames()}>
          {t("Create")}
        </Link>
      </HeadingWrapper>
      <ServicesTable key={userResponse?.data?.data?.currentTeam.id!} />
    </>
  );
};

export const Container = () => (
  <ContentWrapper>
    <Suspense
      fallback={
        <>
          <HeadingWrapper>
            <PageHeading />
            <Placeholder widthClassName="w-24" heightClassName="h-10" />
          </HeadingWrapper>
          <Card>
            <NegateCardPadding>
              <TablePlaceholder />
            </NegateCardPadding>
          </Card>
        </>
      }
    >
      <Content />
    </Suspense>
  </ContentWrapper>
);
