export const namesOf = <T>(typeProxy: TypeProxy<T>): string[] =>
  (typeProxy as any).__path;

export const nameOf = <T>(typeProxy: TypeProxy<T>): string =>
  (typeProxy as any).__name;

type TypeProxy<T> = {
  [P in keyof T]: TypeProxy<T[P]>;
};

export const typeProxy = <T>(): TypeProxy<T> => getProxy([]) as TypeProxy<T>;

const getProxy = (root: string[]) => new Proxy(root, handler) as any;

const handler: ProxyHandler<string[]> = {
  get: (target, key) => {
    if (key === "__path") return target;
    if (key === "__name")
      return Array.isArray(target) ? target[target.length - 1] : null;
    return getProxy([...target, key as string]);
  },
};
