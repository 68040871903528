import { useQuery } from "react-query";
import { getServices } from "services/services";
import { useContextService } from "./use-context-service";
import { useUser } from "./use-user";

export const SERVICES_QUERY = "services";

export const useServices = () => {
  const { data: userResponse } = useUser();

  return useQuery(
    [SERVICES_QUERY, userResponse?.data?.data?.currentTeam.id!],
    useContextService(getServices),
    {
      enabled: !!userResponse?.data?.data?.id,
    }
  );
};
