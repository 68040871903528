import { Context, ResponsePayload } from "types";

export const getInvitations =
  ({ apiClient }: Context) =>
  () =>
    apiClient<App.Domain.API.Invitations.Resources.InvitationsResource>({
      url: "/invitations",
      method: "GET",
    });

export const acceptInvitation =
  ({ apiClient }: Context) =>
  (id: string) =>
    apiClient<ResponsePayload<App.Domain.API.Account.Resources.UserResource>>({
      url: `/invitations/${id}/accept`,
      method: "POST",
    });

export const declineInvitation =
  ({ apiClient }: Context) =>
  (id: string) =>
    apiClient<ResponsePayload<App.Domain.API.Account.Resources.UserResource>>({
      url: `/invitations/${id}/decline`,
      method: "POST",
    });
