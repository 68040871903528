import { useLogout } from "hooks/use-logout";
import { useUser } from "hooks/use-user";
import { useTranslation } from "react-i18next";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { Avatar } from "./Avatar";
import { Menu, MenuButton, MenuLink } from "./Menu";

export const AccountDropdownMenu = () => {
  const { data: userResponse } = useUser();

  const { t } = useTranslation();

  const { logoutMutation } = useLogout();

  return (
    <Menu
      content={
        <>
          <MenuLink to="/profile/edit">{t("Edit profile")}</MenuLink>
          <MenuButton onClick={() => logoutMutation.mutate()}>
            {t("Sign out")}
          </MenuButton>
        </>
      }
    >
      <button className="flex items-center text-sm font-medium text-gray-900 bg-white focus:outline-none">
        <span className="inline-block mr-3">
          <Avatar
            name={userResponse?.data?.data?.fullName}
            src={userResponse?.data?.data?.avatar}
          />
        </span>
        <span className="max-w-[14rem] overflow-hidden overflow-ellipsis">
          {userResponse?.data?.data?.firstName}
        </span>
        <span className="inline-block ml-1">
          <ChevronDownIcon className="w-5 text-gray-500" />
        </span>
      </button>
    </Menu>
  );
};
