import React from "react";
import ReactDOM from "react-dom";
import { Container } from "./components/Container";
import "tailwindcss/tailwind.css";
import "./env";
import "./i18n";
import { QueryClientProvider } from "react-query";
import { queryClient } from "query";

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Container />
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
