import { XIcon } from "@heroicons/react/outline";
import { useUser } from "hooks/use-user";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export const VerifyEmail = () => {
  const { data: userResponse } = useUser();

  const { t } = useTranslation();

  const [visible, setVisible] = useState(true);

  if (!visible || userResponse?.data?.data?.verified) {
    return null;
  }

  return (
    <div className="relative bg-blueGray-600">
      <div className="px-3 py-3 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="pr-16 sm:text-center sm:px-16">
          <p className="font-medium text-white">
            <span>
              {t(
                "Please verify your account using the link you've received via email."
              )}
            </span>
          </p>
        </div>
        <div className="absolute inset-y-0 right-0 flex items-start pt-1 pr-1 sm:pt-1 sm:pr-2 sm:items-start">
          <button
            type="button"
            className="flex p-2 rounded-md hover:bg-blueGray-500 focus:outline-none focus:ring-2 focus:ring-white"
            onClick={() => setVisible(false)}
          >
            <XIcon className="w-6 h-6 text-white" aria-hidden="true" />
          </button>
        </div>
      </div>
    </div>
  );
};
