import { useEffect, useLayoutEffect, useState, MutableRefObject } from "react";
import FullCalendar from "@fullcalendar/react";

export const useCalendarView = (
  calendarRef: MutableRefObject<FullCalendar | null>
) => {
  const [view, setView] = useState<"timeGridWeek" | "timeGridDay">();

  useLayoutEffect(() => {
    const updateSize = () =>
      setView(window.innerWidth >= 768 ? "timeGridWeek" : "timeGridDay");

    window.addEventListener("resize", updateSize);

    updateSize();

    return () => window.removeEventListener("resize", updateSize);
  }, []);

  useEffect(() => {
    if (view) {
      calendarRef.current?.getApi()?.changeView(view);
    }
  }, [calendarRef, view]);

  return { view };
};
