import { FormSection, FormSectionProps } from "./FormSection";
import { Placeholder } from "./Placeholder";

const FieldGroupPlaceholder = () => (
  <div className="space-y-2">
    <Placeholder widthClassName="w-1/3" heightClassName="h-3" />
    <Placeholder widthClassName="w-full" heightClassName="h-8" />
  </div>
);

type FormSectionPlaceholderProps = Pick<FormSectionProps, "withCard">;

export const FormSectionPlaceholder = (props: FormSectionPlaceholderProps) => (
  <FormSection
    {...props}
    title={<Placeholder widthClassName="w-1/2" heightClassName="h-5" />}
    description={
      <div className="mt-4">
        <Placeholder widthClassName="w-2/3" heightClassName="h-4" />
      </div>
    }
    footer={<Placeholder widthClassName="w-24" heightClassName="h-8" />}
  >
    <div className="grid gap-4 lg:grid-cols-2">
      <FieldGroupPlaceholder />
      <FieldGroupPlaceholder />
      <FieldGroupPlaceholder />
      <FieldGroupPlaceholder />
    </div>
  </FormSection>
);
