export const Header = ({
  title,
  description,
}: {
  title: string;
  description: string;
}) => (
  <>
    <h3 className="font-medium text-center text-gray-900">{title}</h3>
    <p className="text-center text-gray-600">{description}</p>
  </>
);
