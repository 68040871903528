import { forwardRef, PropsWithoutRef } from "react";
import { useTranslation } from "react-i18next";
import { FieldProps } from "types";
import { getFieldIds } from "utils/field";

export type FieldLabelProps = Omit<
  PropsWithoutRef<JSX.IntrinsicElements["label"]>,
  "className"
> &
  Partial<Pick<FieldProps, "optional" | "required">> &
  Required<Pick<PropsWithoutRef<JSX.IntrinsicElements["label"]>, "htmlFor">>;

export const FieldLabel = forwardRef<HTMLLabelElement, FieldLabelProps>(
  ({ children, optional, required, ...props }, ref) => {
    const { t } = useTranslation();

    const { optionalId } = getFieldIds({ name: props.htmlFor });

    return (
      <div className="flex justify-between">
        <label
          ref={ref}
          {...props}
          className="block mb-1 text-sm font-medium text-gray-700"
        >
          {children} {required && <sup className="text-xs text-red-400">*</sup>}
        </label>
        {optional && (
          <span className="text-sm text-gray-500" id={optionalId}>
            {t("Optional")}
          </span>
        )}
      </div>
    );
  }
);
