import { Button } from "components/Button";
import { useTranslation } from "react-i18next";
import { ActionsWrapper } from "../../../components/ActionsWrapper";
import { Header } from "../../../components/Header";
import { TextField } from "components/TextField";
import { useFormContext } from "react-hook-form";

export const DetailsForm = ({
  nextStep,
  previousStep,
}: {
  nextStep: () => void;
  previousStep: () => void;
}) => {
  const {
    formState: { errors, isSubmitting },
    register,
  } = useFormContext();

  const { t } = useTranslation();

  return (
    <>
      <Header
        title={t("Personal Information")}
        description={t("Please enter below your personal details.")}
      />
      <div className="grid gap-4 mt-4 lg:grid-cols-2">
        <TextField
          {...register("firstName")}
          label={t("First name")}
          error={errors.firstName}
          required
        />
        <TextField
          {...register("lastName")}
          label={t("Last name")}
          error={errors.lastName}
          required
        />
        <TextField
          {...register("email")}
          label={t("Email")}
          error={errors.email}
          required
        />
        <TextField
          {...register("phone")}
          label={t("Phone")}
          error={errors.phone}
          optional
        />
      </div>
      <ActionsWrapper>
        {!isSubmitting && (
          <Button type="button" variant="white" onClick={previousStep}>
            {t("Back")}
          </Button>
        )}
        <Button
          onClick={nextStep}
          loading={isSubmitting}
          disabled={isSubmitting}
        >
          {t("Submit")}
        </Button>
      </ActionsWrapper>
    </>
  );
};
