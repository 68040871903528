import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { pipe } from "fp-ts/lib/function";
import { Button } from "components/Button";
import { FormSection, FormSectionProps } from "components/FormSection";
import { TextField } from "components/TextField";
import {
  useStringMax,
  useStringRequired,
  useValidEmail,
} from "hooks/use-validation-input";
import { nullableString } from "utils/zod";
import { TextAreaField } from "components/TextAreaField";
import { ReactNode } from "react";
// import { useTimezones } from "hooks/use-timezones";
import { useUser } from "hooks/use-user";

const useFormSchema = () =>
  z.object({
    firstName: pipe(z.string(), useStringMax(50), useStringRequired()),
    lastName: pipe(z.string(), useStringMax(50), useStringRequired()),
    email: pipe(
      z.string(),
      useStringMax(100),
      useValidEmail(),
      useStringRequired()
    ),
    phone: pipe(z.string(), useStringMax(100)).or(nullableString),
    address: pipe(z.string(), useStringMax(100)).or(nullableString),
    notes: pipe(z.string(), useStringMax(1000)).or(nullableString),
    timezone: pipe(z.string(), useStringRequired()),
  });

type FormSchema = z.infer<ReturnType<typeof useFormSchema>>;

export const ClientForm = ({
  withCard,
  actions,
  variant,
  defaultValues,
  onSubmit,
}: {
  actions?: ReactNode;
  withCard?: FormSectionProps["withCard"];
  variant?: FormSectionProps["variant"];
  defaultValues?: Partial<FormSchema>;
  onSubmit: (data: FormSchema) => Promise<any>;
}) => {
  const { t } = useTranslation();

  const { data: userResponse } = useUser();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormSchema>({
    resolver: zodResolver(useFormSchema()),
    defaultValues: {
      timezone: userResponse?.data?.data?.timezone,
      ...defaultValues,
    },
  });

  // const { data: timezonesResponse } = useTimezones();

  return (
    <form onSubmit={handleSubmit((data) => onSubmit(data))}>
      <FormSection
        variant={variant}
        withCard={withCard}
        title={t("Client details")}
        description={t("Personal client information.")}
        footer={
          <>
            {actions}
            <Button
              type="submit"
              disabled={isSubmitting}
              loading={isSubmitting}
            >
              {defaultValues ? t("Update") : t("Create")}
            </Button>
          </>
        }
      >
        <div className="grid gap-4 lg:grid-cols-2">
          <TextField
            {...register("firstName")}
            label={t("First name")}
            error={errors.firstName}
            required
          />
          <TextField
            {...register("lastName")}
            label={t("Last name")}
            error={errors.lastName}
            required
          />
          <TextField
            {...register("email")}
            label={t("Email")}
            error={errors.email}
            required
          />
          {/* <SelectField
            {...register("timezone")}
            name="timezone"
            label={t("Timezone")}
            required
            options={
              timezonesResponse?.data?.data.map((item) => ({
                label: item,
                value: item,
              })) || []
            }
          /> */}
          <TextField
            {...register("phone")}
            label={t("Phone")}
            error={errors.phone}
            optional
          />
          <TextField
            {...register("address")}
            label={t("Address")}
            error={errors.address}
            optional
          />
          <div className="lg:col-span-2">
            <TextAreaField
              {...register("notes")}
              label={t("Notes")}
              error={errors.notes}
              optional
            />
          </div>
        </div>
      </FormSection>
    </form>
  );
};
