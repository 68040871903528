import { SearchIcon } from "@heroicons/react/outline";
import { useTranslation } from "react-i18next";
import { Column, useTable } from "react-table";

export interface TableProps {
  columns: Column[];
  data: any[];
}

export const Table = ({ columns, data }: TableProps) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  const { t } = useTranslation();

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto">
        <div className="inline-block min-w-full py-2 align-middle">
          <div className="overflow-hidden border-b border-gray-200">
            <table
              {...getTableProps()}
              className="min-w-full divide-y divide-gray-200"
            >
              <thead className="bg-gray-50">
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps()}
                        scope="col"
                        className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                      >
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);

                  return (
                    <tr
                      {...row.getRowProps()}
                      className={
                        row.index % 2 === 0 ? "bg-white" : "bg-gray-50"
                      }
                    >
                      {row.cells.map((cell) => (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {rows.length === 0 && (
        <div className="flex flex-col items-center justify-center p-4 border-b border-gray-200 bg-gray-50 sm:p-8">
          <SearchIcon className="w-6 h-6 text-gray-400 animate-pulse" />
          <p className="mt-2 text-sm text-gray-500">
            {t("No data was found.")}
          </p>
        </div>
      )}
    </div>
  );
};
