import { Context } from "types";

export const getAppointments =
  ({ apiClient }: Context) =>
  ({
    id,
    data,
  }: {
    id: string | number;
    data?: App.Resources.PaginateResource;
  }) =>
    apiClient<App.Domain.API.Appointments.Resources.AppointmentsResource>({
      url: `/clients/${id}/appointments`,
      method: "GET",
      params: data,
    });
