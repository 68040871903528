import { ReactNode } from "react";

export const InvitationItem = ({
  children,
  actions,
}: {
  children: ReactNode;
  actions: ReactNode;
}) => (
  <div className="flex flex-col justify-between py-4 space-y-4 lg:space-x-4 lg:space-y-0 lg:items-center lg:flex-row">
    <div className="max-w-xs text-sm text-gray-700 break-words">{children}</div>
    <div className="flex space-x-6">{actions}</div>
  </div>
);
