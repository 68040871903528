import { useTranslation } from "react-i18next";
import { useContextService } from "hooks/use-context-service";
import { showNotification as showNotificationService } from "services/notification";
import { useMutation } from "react-query";
import { createClient } from "../services/client";
import { queryClient } from "query";
import { useHistory } from "react-router";
import { CLIENTS_QUERY } from "../../list/components/ClientsTable";
import { useUser } from "hooks/use-user";
import { ClientForm } from "domain/clients/components/ClientForm";

export const CreateClientForm = () => {
  const { t } = useTranslation();

  const showNotification = useContextService(showNotificationService);

  const history = useHistory();

  const { data: userResponse } = useUser();

  const createClientMutation = useMutation(useContextService(createClient), {
    onSuccess: (response) => {
      const queryKey = [
        CLIENTS_QUERY,
        userResponse?.data?.data?.currentTeam.id!,
      ];

      if (queryClient.getQueryData(queryKey)) {
        queryClient.refetchQueries(queryKey);
      }

      showNotification({
        title: t("Successfully created"),
        message: t("You added a new client."),
      });

      history.push("/clients");
    },
  });

  return <ClientForm onSubmit={createClientMutation.mutateAsync} />;
};
