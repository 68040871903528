import { PropsWithoutRef } from "react";

export interface SectionHeadingProps
  extends Omit<PropsWithoutRef<JSX.IntrinsicElements["div"]>, "className"> {}

export const SectionHeading = ({ children, ...props }: SectionHeadingProps) => (
  <h3 {...props} className="text-lg font-medium leading-6 text-gray-900">
    {children}
  </h3>
);
