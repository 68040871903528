import { Heading } from "components/Heading";
import { useTranslation } from "react-i18next";
import { ContentWrapper } from "components/ContentWrapper";
import { HeadingWrapper } from "components/HeadingWrapper";
import { Breadcrumbs } from "components/Breadcrumbs";
import { Tabs } from "components/Tabs";
import { Route, useRouteMatch } from "react-router";
import { ClientDetails } from "../domain/details";
import { RouteSwitch } from "components/RouteSwitch";
import { EditClient } from "../domain/edit";
import { Card } from "components/Card";
import { DeleteClient } from "../domain/delete";
import { ClientAppointments } from "../domain/appointments";

export const Container = () => {
  const { t } = useTranslation();

  const match = useRouteMatch();

  return (
    <>
      <Breadcrumbs
        pages={[
          {
            name: t("Clients"),
            href: "/clients",
          },
          {
            name: t("Client details"),
            href: "/clients/create",
            current: true,
          },
        ]}
      />
      <ContentWrapper>
        <HeadingWrapper>
          <Heading>{t("Client Details")}</Heading>
        </HeadingWrapper>
        <Card>
          <div className="-mx-4 -mt-5 sm:-mx-6 sm:-mt-6">
            <Tabs
              tabs={[
                { name: t("Details"), href: match.url },
                {
                  name: t("Edit"),
                  href: `${match.url}/edit`,
                },
                {
                  name: t("Appointments"),
                  href: `${match.url}/appointments`,
                },
                {
                  name: t("Delete"),
                  href: `${match.url}/delete`,
                },
              ]}
            />
          </div>
          <div className="mt-5 sm:mt-6">
            <RouteSwitch>
              <Route
                exact
                path={match.path + "/delete"}
                component={DeleteClient}
              />
              <Route exact path={match.path + "/edit"} component={EditClient} />
              <Route
                exact
                path={match.path + "/appointments"}
                component={ClientAppointments}
              />
              <Route exact path={match.path + "/"} component={ClientDetails} />
            </RouteSwitch>
          </div>
        </Card>
      </ContentWrapper>
    </>
  );
};
