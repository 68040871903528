import { Context, ResponsePayload } from "types";

export const editService =
  ({ apiClient }: Context) =>
  ({
    id,
    data,
  }: {
    id: string;
    data: App.Domain.API.Services.Resources.SaveServiceResource;
  }) =>
    apiClient<
      ResponsePayload<App.Domain.API.Services.Resources.ServiceResource>
    >({
      url: `/services/${id}`,
      method: "PUT",
      data,
    });

export const deleteService =
  ({ apiClient }: Context) =>
  (id: string) =>
    apiClient({
      url: `/services/${id}`,
      method: "DELETE",
    });
