import { useTranslation } from "react-i18next";
import { Button } from "./Button";

export interface PaginationProps {
  page: number;
  isPreviousData?: boolean;
  loading?: boolean;
  hasMorePages?: boolean;
  onPrevious: () => void;
  onNext: () => void;
}

export const Pagination = ({
  page,
  isPreviousData,
  hasMorePages,
  loading,
  onPrevious,
  onNext,
}: PaginationProps) => {
  const { t } = useTranslation();

  return (
    <nav
      className="flex items-center justify-between px-4 py-3 bg-white sm:px-6"
      aria-label={t("Pagination")}
    >
      <div className="hidden sm:block">
        <p className="text-sm text-gray-700">
          {t("Page")} <span className="font-medium">{page}</span>
        </p>
      </div>
      <div className="flex justify-between flex-1 space-x-4 sm:justify-end">
        <Button variant="white" disabled={page === 1} onClick={onPrevious}>
          {t("Previous")}
        </Button>
        <Button
          variant="white"
          disabled={isPreviousData || !hasMorePages}
          loading={loading && (isPreviousData || !hasMorePages)}
          onClick={onNext}
        >
          {t("Next")}
        </Button>
      </div>
    </nav>
  );
};
