import { Heading } from "components/Heading";
import { useTranslation } from "react-i18next";
import { CreateTeamForm } from "./CreateTeamForm";
import { Suspense } from "react";
import { FormSectionPlaceholder } from "components/FormSectionPlaceholder";
import { ContentWrapper } from "components/ContentWrapper";
import { HeadingWrapper } from "components/HeadingWrapper";

export const Container = () => {
  const { t } = useTranslation();

  return (
    <ContentWrapper>
      <HeadingWrapper>
        <Heading>{t("Create New Team")}</Heading>
      </HeadingWrapper>
      <Suspense
        fallback={
          <>
            <FormSectionPlaceholder />
          </>
        }
      >
        <CreateTeamForm />
      </Suspense>
    </ContentWrapper>
  );
};
