import { ReactNode } from "react";
import { Route, Switch } from "react-router-dom";
import { NotFound } from "./NotFound";

export const RouteSwitch = ({ children }: { children: ReactNode }) => (
  <Switch>
    {children}
    <Route path="*" component={NotFound} />
  </Switch>
);
