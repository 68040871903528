import { Context, ResponsePayload } from "types";
import { nameOf, typeProxy } from "utils/schema-keys";

export const updateAvatar =
  ({ apiClient }: Context) =>
  (data: App.Resources.FileUploadResource) => {
    const fileUploadKeys = typeProxy<App.Resources.FileUploadResource>();

    const formData = new FormData();

    formData.append(nameOf(fileUploadKeys.file), data.file);

    return apiClient<
      ResponsePayload<App.Domain.API.Account.Resources.UserResource>
    >({
      url: "/account/avatar",
      method: "POST",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    });
  };

export const removeAvatar =
  ({ apiClient }: Context) =>
  () =>
    apiClient<ResponsePayload<App.Domain.API.Account.Resources.UserResource>>({
      url: "/account/avatar",
      method: "DELETE",
    });

export const updateProfile =
  ({ apiClient }: Context) =>
  (data: App.Domain.API.Account.Resources.UpdateProfileResource) =>
    apiClient<ResponsePayload<App.Domain.API.Account.Resources.UserResource>>({
      url: "/account/profile",
      method: "PUT",
      data,
    });

export const updatePassword =
  ({ apiClient }: Context) =>
  (data: App.Domain.API.Account.Resources.UpdatePasswordResource) =>
    apiClient({
      url: "/account/password",
      method: "PUT",
      data,
    });

export const deleteAccount =
  ({ apiClient }: Context) =>
  (data: App.Resources.PasswordResource) =>
    apiClient({
      url: "/account",
      method: "DELETE",
      data,
    });
