import { z } from "zod";
import { Button } from "components/Button";
import { FormSection } from "components/FormSection";
import { TextField } from "components/TextField";
import { showNotification as showNotificationService } from "services/notification";
import { useUser } from "hooks/use-user";
import { useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { TEAM_QUERY, useTeam } from "../hooks/use-team";
import { zodResolver } from "@hookform/resolvers/zod";
import { Switch } from "components/Switch";
import { useContextService } from "hooks/use-context-service";
import { useMutation } from "react-query";
import { updateOnlineAppointments } from "../services/team";
import { queryClient } from "query";
import { FieldLabel } from "components/FieldLabel";

const useFormSchema = () =>
  z.object({
    onlineAppointmentsEnabled: z.boolean(),
  });

type FormSchema = z.infer<ReturnType<typeof useFormSchema>>;

export const OnlineAppointments = () => {
  const { t } = useTranslation();

  const { data: userResponse } = useUser();

  const { data: teamResponse } = useTeam(
    userResponse?.data?.data?.currentTeam.id
  );

  const {
    control,
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = useForm<FormSchema>({
    resolver: zodResolver(useFormSchema()),
    defaultValues: {
      onlineAppointmentsEnabled:
        teamResponse?.data?.data?.onlineAppointmentsEnabled,
    },
  });

  const link = `${window.location.origin}/online-appointment/${teamResponse?.data?.data?.externalId}`;

  const inputRef = useRef<HTMLInputElement>(null);

  const copyLink = () => {
    navigator.clipboard.writeText(link);

    inputRef.current?.select();
  };

  const showNotification = useContextService(showNotificationService);

  const updateTeamMutation = useMutation(
    useContextService(updateOnlineAppointments),
    {
      onSuccess: (response) => {
        queryClient.setQueryData(
          [TEAM_QUERY, userResponse?.data?.data?.currentTeam.id],
          response
        );

        showNotification({
          title: t("Successfully updated"),
          message: t("Your team was updated."),
        });
      },
    }
  );

  const onlineAppointmentsEnabled = watch("onlineAppointmentsEnabled");

  const editAllowed =
    userResponse?.data?.data?.currentTeam.isOwner ||
    userResponse?.data?.data?.currentTeam.role === "ADMIN";

  return (
    <form
      onSubmit={handleSubmit((data) =>
        updateTeamMutation.mutateAsync({
          data,
          id: userResponse?.data?.data?.currentTeam.id!,
        })
      )}
    >
      <FormSection
        title={t("Online appointments")}
        description={t("Manage your team's online appointment feature.")}
        footer={
          editAllowed && (
            <Button
              type="submit"
              disabled={isSubmitting}
              loading={isSubmitting}
            >
              {t("Save")}
            </Button>
          )
        }
      >
        {editAllowed && (
          <div>
            <FieldLabel required htmlFor="onlineAppointmentsEnabled">
              {t("Online Appointments Enabled")}
            </FieldLabel>
            <Controller
              control={control}
              name="onlineAppointmentsEnabled"
              render={({ field }) => (
                <Switch checked={field.value} {...field} />
              )}
            />
          </div>
        )}
        <div className="max-w-xl">
          <p className="text-sm text-gray-700">
            {onlineAppointmentsEnabled &&
              t(
                "You can share the link below with your clients for online appointments."
              )}
            {!onlineAppointmentsEnabled &&
              editAllowed &&
              t(
                "Online appointments are disabled. Clients will not be able to request online appointments."
              )}
            {!onlineAppointmentsEnabled &&
              !editAllowed &&
              t("Online appointments are disabled by team management.")}
          </p>
        </div>
        {onlineAppointmentsEnabled && (
          <div className="grid gap-4 lg:grid-cols-3">
            <div className="flex flex-col space-y-4 sm:items-end sm:flex-row sm:space-y-0 sm:space-x-3 lg:col-span-2">
              <div className="flex-1">
                <TextField
                  ref={inputRef}
                  label={t("Online appointment link")}
                  name="onlineAppointmentLink"
                  value={link}
                  readOnly
                  onFocus={(e) => e.currentTarget.select()}
                />
              </div>
              <div className="flex flex-col">
                <Button type="button" variant="white" onClick={copyLink}>
                  {t("Copy link")}
                </Button>
              </div>
            </div>
          </div>
        )}
      </FormSection>
    </form>
  );
};
