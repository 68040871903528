import { Context, ResponsePayload } from "types";

export const getClient =
  ({ apiClient }: Context) =>
  (id: string | number) =>
    apiClient<ResponsePayload<App.Domain.API.Clients.Resources.ClientResource>>(
      {
        url: `/clients/${id}`,
        method: "GET",
      }
    );
