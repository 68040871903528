import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { Button } from "components/Button";
import { FormSection } from "components/FormSection";
import { useContextService } from "hooks/use-context-service";
import { useMutation } from "react-query";
import { deleteAccount } from "../services/profile";
import { queryClient } from "query";
import { pipe } from "fp-ts/lib/function";
import { useStringMax, useStringRequired } from "hooks/use-validation-input";
import { TextField } from "components/TextField";
import { Unauthorized } from "errors/Unauthorized";
import { useLogout } from "hooks/use-logout";

const useFormSchema = () =>
  z.object({
    accountPassword: pipe(z.string(), useStringMax(250), useStringRequired()),
  });

type FormSchema = z.infer<ReturnType<typeof useFormSchema>>;

export const DeleteAccountForm = () => {
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    reset,
    setError,
    setFocus,
    formState: { isSubmitting, errors },
  } = useForm<FormSchema>({
    resolver: zodResolver(useFormSchema()),
  });

  const { redirectToLogin } = useLogout();

  const deleteAccountMutation = useMutation(useContextService(deleteAccount), {
    onSuccess: () => {
      queryClient.removeQueries();

      redirectToLogin();
    },
    onError: (error: any) => {
      if (error instanceof Unauthorized) {
        reset();

        setError("accountPassword", {
          message: t("Incorrect password"),
        });

        setFocus("accountPassword");

        return new Promise(() => {});
      }
    },
  });

  return (
    <form
      onSubmit={handleSubmit((data) =>
        deleteAccountMutation.mutateAsync({ password: data.accountPassword })
      )}
    >
      <FormSection
        title={t("Delete account")}
        description={t("Permanently delete your account.")}
      >
        <div className="max-w-xl">
          <p className="text-sm text-gray-700">
            {t(
              "Once your account is deleted, all of your resources and data will be permanently deleted. Before deleting your account, please download any data or information that you wish to retain."
            )}
          </p>
        </div>
        <div className="grid gap-4 lg:grid-cols-2">
          <TextField
            {...register("accountPassword")}
            label={t("Password")}
            error={errors.accountPassword}
            required
            type="password"
          />
        </div>
        <Button
          type="submit"
          variant="danger"
          disabled={isSubmitting}
          loading={isSubmitting}
        >
          {t("Permanently delete account")}
        </Button>
      </FormSection>
    </form>
  );
};
