import { PropsWithoutRef } from "react";

export interface PlaceholderProps {
  widthClassName: string;
  heightClassName: string;
  bgClassName?: string;
}

export const Placeholder = ({
  widthClassName,
  heightClassName,
  bgClassName = "bg-gray-200",
}: PropsWithoutRef<PlaceholderProps>) => (
  <div
    className={`${widthClassName} ${heightClassName} ${bgClassName} inline-flex items-center rounded animate-pulse`}
  >
    &nbsp;
  </div>
);
