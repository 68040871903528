import { SectionHeading } from "components/SectionHeading";
import {
  DescriptionList,
  DescriptionListItem,
} from "components/DescriptionList";
import { NegateHorizontalCardPadding } from "components/NegateCardPadding";
import { Placeholder } from "components/Placeholder";
import { Suspense } from "react";
import { useTranslation } from "react-i18next";
import { useClient } from "../../../hooks/use-client";

const titlePlaceholderWidthClassNames = ["w-16", "w-20", "w-28"];

const descriptionPlaceholderWidthClassNames = [
  "w-24 lg:w-1/4",
  "w-32 lg:w-2/4",
  "w-40 lg:w-64",
];

const PlaceholderItem = () => (
  <DescriptionListItem
    title={
      <Placeholder
        widthClassName={
          titlePlaceholderWidthClassNames[
            Math.floor(Math.random() * titlePlaceholderWidthClassNames.length)
          ]
        }
        heightClassName="h-4"
      />
    }
    description={
      <Placeholder
        widthClassName={
          descriptionPlaceholderWidthClassNames[
            Math.floor(
              Math.random() * descriptionPlaceholderWidthClassNames.length
            )
          ]
        }
        heightClassName="h-4"
      />
    }
  />
);

const Content = () => {
  const { data: clientResponse } = useClient();

  const { t } = useTranslation();

  return (
    <DescriptionList>
      <DescriptionListItem
        title={t("First name")}
        description={clientResponse?.data?.data?.firstName}
      />
      <DescriptionListItem
        title={t("Last name")}
        description={clientResponse?.data?.data?.lastName}
      />
      <DescriptionListItem
        title={t("Email")}
        description={clientResponse?.data?.data?.email}
      />
      <DescriptionListItem
        title={t("Timezone")}
        description={clientResponse?.data?.data?.timezone}
      />
      <DescriptionListItem
        title={t("Phone")}
        description={clientResponse?.data?.data?.phone}
      />
      <DescriptionListItem
        title={t("Address")}
        description={clientResponse?.data?.data?.address}
      />
      <DescriptionListItem
        title={t("Notes")}
        description={clientResponse?.data?.data?.notes}
      />
    </DescriptionList>
  );
};

export const Container = () => {
  const { t } = useTranslation();

  return (
    <>
      <SectionHeading>{t("Personal information")}</SectionHeading>
      <div className="mt-5 sm:mt-6">
        <NegateHorizontalCardPadding>
          <Suspense
            fallback={
              <DescriptionList>
                {[...Array(6)].map((item, index) => (
                  <PlaceholderItem key={index} />
                ))}
              </DescriptionList>
            }
          >
            <Content />
          </Suspense>
        </NegateHorizontalCardPadding>
      </div>
    </>
  );
};
