import { CalendarListeners } from "@fullcalendar/common";
import FullCalendar from "@fullcalendar/react";
import { compareAsc, format, isEqual, startOfDay, sub } from "date-fns";
import { MutableRefObject, useEffect, useMemo, useState } from "react";

export const useCalendarDates = (
  calendarRef: MutableRefObject<FullCalendar | null>
) => {
  const [startDate, setStartDate] = useState<string>();

  const [endDate, setEndDate] = useState<string>();

  useEffect(() => {
    const handler: CalendarListeners["datesSet"] = ({ start, end }) => {
      setStartDate(format(start, "yyyy-MM-dd"));
      setEndDate(
        format(
          sub(end, {
            days: 1,
          }),
          "yyyy-MM-dd"
        )
      );
    };

    const calendarApi = calendarRef.current?.getApi();

    calendarApi?.on("datesSet", handler);

    return () => calendarApi?.off("datesSet", handler);
  }, [calendarRef]);

  const todayNotInView = useMemo(() => {
    if (!startDate || !endDate) {
      return false;
    }

    const today = startOfDay(new Date());

    const parsedStartDate = startOfDay(new Date(startDate));

    const parsedEndDate = startOfDay(new Date(endDate));

    if (isEqual(parsedStartDate, parsedEndDate)) {
      return !isEqual(today, parsedStartDate) && !isEqual(today, parsedEndDate);
    }

    return (
      compareAsc(parsedStartDate, today) === 1 ||
      compareAsc(parsedEndDate, today) === -1
    );
  }, [startDate, endDate]);

  return {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    todayNotInView,
  };
};
