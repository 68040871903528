import i18next from "i18next";
import { initReactI18next } from "react-i18next";

i18next.use(initReactI18next).init({
  resources: {
    en: {},
  },
  lng: "en",
  fallbackLng: "en",
});

export const i18n = i18next;
