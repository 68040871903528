import classNames from "classnames";
import { forwardRef, PropsWithoutRef } from "react";

type Variant = "primary" | "danger" | "secondary" | "success";

export interface TextButtonProps
  extends Omit<PropsWithoutRef<JSX.IntrinsicElements["button"]>, "className"> {
  variant?: Variant;
  loading?: boolean;
}

const variantClassNames: Record<Variant, string> = {
  primary: "text-indigo-500 focus:ring-indigo-500",
  danger: "text-red-500 focus:ring-red-500",
  secondary: "text-gray-500 focus:ring-gray-500",
  success: "text-green-500 focus:ring-green-500",
};

export const getTextButtonClassNames = ({
  variant = "primary",
  loading,
}: Pick<TextButtonProps, "variant" | "loading"> | undefined = {}) =>
  classNames(
    "text-sm focus:outline-none disabled:opacity-50 font-medium focus:ring-2 rounded inline-flex items-center px-1 py-2 leading-4 focus:ring-offset-2",
    {
      "cursor-wait": loading,
      "disabled:cursor-not-allowed": !loading,
    },
    variantClassNames[variant]
  );

export const TextButton = forwardRef<HTMLButtonElement, TextButtonProps>(
  ({ children, variant, loading, ...props }, ref) => (
    <button
      ref={ref}
      {...props}
      className={getTextButtonClassNames({
        variant,
        loading,
      })}
    >
      {children}
    </button>
  )
);
