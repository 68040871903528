import { Context, ResponsePayload } from "types";

export const createService =
  ({ apiClient }: Context) =>
  (data: App.Domain.API.Services.Resources.SaveServiceResource) =>
    apiClient<
      ResponsePayload<App.Domain.API.Services.Resources.ServiceResource>
    >({
      url: "/services",
      method: "POST",
      data,
    });
