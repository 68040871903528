import { ExclamationIcon } from "@heroicons/react/outline";
import { Dialog } from "@headlessui/react";
import { ReactNode } from "react";
import classNames from "classnames";
import { FormActionsFooter } from "./ModalFooter";

type Variant = "danger" | "primary";

const iconVariants: Record<Variant, ReactNode> = {
  danger: (
    <ExclamationIcon className="w-6 h-6 text-red-600" aria-hidden="true" />
  ),
  primary: (
    <ExclamationIcon className="w-6 h-6 text-indigo-600" aria-hidden="true" />
  ),
};

const iconClassNames: Record<Variant, string> = {
  danger: "bg-red-100",
  primary: "bg-indigo-100",
};

export interface PromptModalContentProps {
  title: ReactNode;
  description: ReactNode;
  actions: ReactNode;
  variant?: Variant;
}

export const PromptModalContent = ({
  title,
  description,
  actions,
  variant = "danger",
}: PromptModalContentProps) => {
  return (
    <>
      <div className="sm:flex sm:items-start">
        <div
          className={classNames(
            "flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto rounded-full sm:mx-0 sm:h-10 sm:w-10",
            iconClassNames[variant]
          )}
        >
          {iconVariants[variant]}
        </div>
        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
          <Dialog.Title
            as="h3"
            className="text-lg font-medium leading-6 text-gray-900"
          >
            {title}
          </Dialog.Title>
          <div className="mt-2">
            <p className="text-sm text-gray-500">{description}</p>
          </div>
        </div>
      </div>
      <FormActionsFooter>{actions}</FormActionsFooter>
    </>
  );
};
