import { useContextService } from "hooks/use-context-service";
import { useQuery } from "react-query";
import { useParams } from "react-router";
import { getTeam } from "../services/online-appointment";

export const ONLINE_APPOINTMENT_TEAM_QUERY = "online-appointment-team";

export const useTeam = () => {
  const getTeamService = useContextService(getTeam);

  const { id } = useParams<{ id: string }>();

  return useQuery([ONLINE_APPOINTMENT_TEAM_QUERY, id], () =>
    getTeamService(id)
  );
};
