import { Avatar } from "components/Avatar";
import { Button } from "components/Button";
import { RadioGroupField } from "components/RadioGroupField";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useTeam } from "../../../hooks/use-team";
import { ActionsWrapper } from "../../../components/ActionsWrapper";
import { Header } from "../../../components/Header";

export const MemberForm = ({ nextStep }: { nextStep: () => void }) => {
  const { control, watch, setValue } = useFormContext();

  const { t } = useTranslation();

  const memberId = watch("member.id");

  const { data: teamResponse } = useTeam();

  return (
    <>
      <Header
        title={t("Select Provider")}
        description={t("The selected person will be providing you services.")}
      />
      <div className="mt-4">
        <Controller
          name="member.id"
          control={control}
          render={({ field }) => (
            <RadioGroupField
              label={t("Member")}
              options={
                teamResponse?.data?.data?.members.map((item) => ({
                  name: (
                    <div className="flex items-center">
                      <Avatar
                        size="xs"
                        src={item.avatar}
                        name={item.fullName}
                      />
                      <span className="ml-2">{item.fullName}</span>
                    </div>
                  ),
                  value: item.id,
                })) || []
              }
              {...field}
              onChange={(value) => {
                field.onChange(value);

                setValue(
                  "member",
                  teamResponse?.data?.data?.members.find(
                    (item) => item.id === value
                  )!
                );
              }}
            />
          )}
        />
      </div>
      <ActionsWrapper>
        <Button type="button" disabled={!memberId} onClick={nextStep}>
          {t("Next step")}
        </Button>
      </ActionsWrapper>
    </>
  );
};
