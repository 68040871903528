import { EditClientForm } from "./EditClientForm";
import { Suspense } from "react";
import { FormSectionPlaceholder } from "components/FormSectionPlaceholder";

export const Container = () => {
  return (
    <Suspense fallback={<FormSectionPlaceholder withCard={false} />}>
      <EditClientForm />
    </Suspense>
  );
};
