import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm } from "react-hook-form";
import { ReactNode, useState } from "react";
import { MemberForm } from "./MemberForm";
import { useTranslation } from "react-i18next";
import { Card } from "components/Card";
import { Steps } from "components/Steps";
import { useTeam } from "../../../hooks/use-team";
import { ServiceForm } from "./ServiceForm";
import { Avatar } from "components/Avatar";
import { DetailsForm } from "./DetailsForm";
import { useMutation } from "react-query";
import { useContextService } from "hooks/use-context-service";
import { createAppointment } from "../services/online-appointment";
import { useHistory, useParams } from "react-router";
import { Wrapper } from "domain/online-appointments/components/Wrapper";
import {
  useWizardSchema,
  WizardSchema,
} from "domain/online-appointments/hooks/use-wizard-schema";
import { DateForm } from "domain/online-appointments/components/DateForm";
import { CheckCircleIcon } from "@heroicons/react/outline";
import { Header } from "domain/online-appointments/components/Header";

type Step = "team-member" | "service" | "date" | "details";

export const Wizard = () => {
  const [step, setStep] = useState<Step>("team-member");

  const form = useForm<WizardSchema>({
    resolver: zodResolver(useWizardSchema()),
    defaultValues: {
      slot: "",
    },
  });

  const { t } = useTranslation();

  const steps: { name: string; value: Step }[] = [
    {
      name: t("Select Provider"),
      value: "team-member",
    },
    {
      name: t("Select Service"),
      value: "service",
    },
    {
      name: t("Select Date"),
      value: "date",
    },
    {
      name: t("Personal Information"),
      value: "details",
    },
  ];

  const stepComponents: Record<Step, ReactNode> = {
    "team-member": <MemberForm nextStep={() => setStep("service")} />,
    service: (
      <ServiceForm
        previousStep={() => setStep("team-member")}
        nextStep={() => setStep("date")}
      />
    ),
    date: (
      <DateForm
        previousStep={() => setStep("service")}
        nextStep={() => setStep("details")}
      />
    ),
    details: (
      <DetailsForm previousStep={() => setStep("date")} nextStep={() => {}} />
    ),
  };

  const { id } = useParams<{ id: string }>();

  const { data: teamResponse } = useTeam();

  const history = useHistory();

  const [submittedFree, setSubmittedFree] = useState(false);

  const createAppointmentMutation = useMutation(
    useContextService(createAppointment),
    {
      onSuccess: (response) => {
        if (response.data?.data?.state === "PENDING_CONFIRMATION") {
          setSubmittedFree(true);
        } else {
          history.push(`/online-appointment/pay/${response.data?.data?.id}`);
        }
      },
    }
  );

  if (submittedFree) {
    return (
      <Wrapper>
        <div className="w-full max-w-lg">
          <Card>
            <div className="text-center">
              <CheckCircleIcon className="w-10 mx-auto text-green-500" />
              <Header
                title={t("Successfully submitted")}
                description={t("Thank you for your appointment!")}
              />
            </div>
          </Card>
        </div>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <div className="w-full max-w-screen-lg">
        {teamResponse?.data?.data?.logo && (
          <div className="flex justify-center mb-2">
            <Avatar size="xl" src={teamResponse?.data?.data?.logo} />
          </div>
        )}
        <h1 className="mb-4 text-xl font-bold text-center">
          {teamResponse?.data?.data?.name}
        </h1>
        <div className="mb-4">
          <Steps
            steps={steps}
            activeStep={step}
            onStepClick={(step) => setStep(step.value as Step)}
          />
        </div>
        <Card>
          <form
            onSubmit={form.handleSubmit((data) =>
              createAppointmentMutation.mutateAsync({
                id,
                data: {
                  ...data,
                  memberId: data.member.id,
                  date: data.date.toISOString(),
                  timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                },
              })
            )}
          >
            <FormProvider {...form}>{stepComponents[step]}</FormProvider>
          </form>
        </Card>
      </div>
    </Wrapper>
  );
};
