import { Heading } from "components/Heading";
import { useTranslation } from "react-i18next";
import { EditTeamForm } from "./EditTeamForm";
import { Fragment, Suspense } from "react";
import { FormSectionPlaceholder } from "components/FormSectionPlaceholder";
import { LogoForm } from "./LogoForm";
import { FormSectionDivider } from "components/FormSectionDivider";
import { useUser } from "hooks/use-user";
import { useTeam } from "../hooks/use-team";
import { DeleteTeamForm } from "./DeleteTeamForm";
import { LeaveTeamForm } from "./LeaveTeamForm";
import { InviteMemberForm } from "./InviteMemberForm";
import { ManageInvitations } from "./ManageInvitations";
import { ManageMembers } from "./ManageMembers";
import { ContentWrapper } from "components/ContentWrapper";
import { HeadingWrapper } from "components/HeadingWrapper";
import { OnlineAppointments } from "./OnlineAppointments";

const Content = () => {
  const { data: userResponse } = useUser();

  const { data: teamResponse } = useTeam(
    userResponse?.data?.data?.currentTeam.id
  );

  if (!userResponse?.data?.data?.currentTeam) {
    return null;
  }

  if (userResponse?.data?.data?.currentTeam.isOwner) {
    return (
      <div key={userResponse?.data?.data?.currentTeam.id}>
        <EditTeamForm />
        <FormSectionDivider />
        <LogoForm />
        <FormSectionDivider />
        <OnlineAppointments />
        <FormSectionDivider />
        <InviteMemberForm />
        <FormSectionDivider />
        <ManageInvitations />
        <FormSectionDivider />
        <ManageMembers />
        {!teamResponse?.data?.data?.personal && (
          <>
            <FormSectionDivider />
            <DeleteTeamForm />
          </>
        )}
      </div>
    );
  }

  if (userResponse?.data?.data?.currentTeam.role === "ADMIN") {
    return (
      <div key={userResponse?.data?.data?.currentTeam.id}>
        <OnlineAppointments />
        <FormSectionDivider />
        <InviteMemberForm />
        <FormSectionDivider />
        <ManageInvitations />
        <FormSectionDivider />
        <ManageMembers />
        <FormSectionDivider />
        <LeaveTeamForm />
      </div>
    );
  }

  return (
    <>
      <OnlineAppointments />
      <FormSectionDivider />
      <LeaveTeamForm />
    </>
  );
};

export const Container = () => {
  const { t } = useTranslation();

  return (
    <ContentWrapper>
      <HeadingWrapper>
        <Heading>{t("Team Settings")}</Heading>
      </HeadingWrapper>
      <Suspense
        fallback={
          <>
            <FormSectionPlaceholder />
            <FormSectionDivider />
            <FormSectionPlaceholder />
            <FormSectionDivider />
            <FormSectionPlaceholder />
            <FormSectionDivider />
            <FormSectionPlaceholder />
            <FormSectionDivider />
            <FormSectionPlaceholder />
          </>
        }
      >
        <Content />
      </Suspense>
    </ContentWrapper>
  );
};
