import { Context, ResponsePayload } from "types";

export const createAppointment =
  ({ apiClient }: Context) =>
  ({
    id,
    data,
  }: {
    id: string;
    data: App.Domain.API.OnlineAppointments.Resources.CreateAppointmentResource;
  }) =>
    apiClient<
      ResponsePayload<App.Domain.API.OnlineAppointments.Resources.PaymentIdResource>
    >({
      url: `/online-appointment/${id}`,
      method: "POST",
      data,
    });
