import { Button } from "components/Button";
import { FormSection } from "components/FormSection";
import { useContextService } from "hooks/use-context-service";
import { useUser } from "hooks/use-user";
import { queryClient } from "query";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { ServiceReturnType } from "types";
import { createSchedule, getSchedules } from "../services/schedules";
import { ScheduleItem } from "./ScheduleItem";
import { SCHEDULES_QUERY } from "./WeekSchedule";

export const DayOfWeek = ({
  day,
  schedules,
}: {
  day: number;
  schedules: App.Domain.API.Schedules.Resources.ScheduleResource[];
}) => {
  const { t } = useTranslation();

  const daysOfWeek = [
    t("Monday"),
    t("Tuesday"),
    t("Wednesday"),
    t("Thursday"),
    t("Friday"),
    t("Saturday"),
    t("Sunday"),
  ];

  const { data: userResponse } = useUser();

  const addScheduleMutation = useMutation(useContextService(createSchedule), {
    onSuccess: (response) => {
      queryClient.setQueryData(
        [SCHEDULES_QUERY, userResponse?.data?.data?.currentTeam.id!],
        (data): ServiceReturnType<typeof getSchedules> => {
          const oldData = data as ServiceReturnType<typeof getSchedules>;

          return {
            ...oldData,
            data: {
              ...oldData.data,
              data: [...oldData.data!.data, response.data!.data!],
            },
          };
        }
      );
    },
  });

  return (
    <FormSection
      title={daysOfWeek[day]}
      description={t("Your {{day}} availability.", { day: daysOfWeek[day] })}
      footer={
        <Button
          loading={addScheduleMutation.isLoading}
          disabled={addScheduleMutation.isLoading || schedules.length >= 3}
          onClick={() => addScheduleMutation.mutateAsync({ day })}
        >
          {t("Add range")}
        </Button>
      }
    >
      <div className="space-y-4 md:space-y-8">
        {schedules.length > 0 ? (
          schedules.map((item, index) => (
            <ScheduleItem key={index} schedule={item} />
          ))
        ) : (
          <p className="text-sm text-gray-500">
            {addScheduleMutation.isLoading
              ? t("Adding a new range. Please wait.")
              : t("{{day}} is a day off.", { day: daysOfWeek[day] })}
          </p>
        )}
      </div>
    </FormSection>
  );
};
