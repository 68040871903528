import { ContentWrapper } from "components/ContentWrapper";
import { FormSectionDivider } from "components/FormSectionDivider";
import { FormSectionPlaceholder } from "components/FormSectionPlaceholder";
import { Heading } from "components/Heading";
import { HeadingWrapper } from "components/HeadingWrapper";
import { Suspense } from "react";
import { useTranslation } from "react-i18next";
import { WeekSchedule } from "./WeekSchedule";

export const Container = () => {
  const { t } = useTranslation();

  return (
    <ContentWrapper>
      <HeadingWrapper>
        <Heading>{t("Personal Schedule")}</Heading>
      </HeadingWrapper>
      <Suspense
        fallback={
          <>
            <FormSectionPlaceholder />
            <FormSectionDivider />
            <FormSectionPlaceholder />
            <FormSectionDivider />
            <FormSectionPlaceholder />
            <FormSectionDivider />
            <FormSectionPlaceholder />
          </>
        }
      >
        <div className="space-y-4">
          <WeekSchedule />
        </div>
      </Suspense>
    </ContentWrapper>
  );
};
