import { ArrowLeftIcon } from "@heroicons/react/solid";
import { TextButton, TextButtonProps } from "components/TextButton";
import { useTranslation } from "react-i18next";

export const BackToDetailsHeading = (
  props: Pick<TextButtonProps, "onClick" | "disabled" | "loading">
) => {
  const { t } = useTranslation();

  return (
    <div className="-ml-1 lg:col-span-2">
      <TextButton {...props}>
        <ArrowLeftIcon className="inline-block w-4 h-4 mr-2" />
        {t("Back to details")}
      </TextButton>
    </div>
  );
};
