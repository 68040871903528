import React from "react";
import { Actions, Context } from "types";
import { createApiClient } from "services/api-client";
import axios from "axios";

axios.defaults.withCredentials = true;

export const contextState: Context = {
  apiClient: createApiClient(axios.request),
  dispatch: (action: Actions) => {},
};

export const AppContext = React.createContext(contextState);

export const reducer = (
  state: typeof contextState,
  action: Actions
): typeof contextState => {
  switch (action.type) {
    case "SHOW_NOTIFICATION": {
      const { type, ...notification } = action;

      return {
        ...state,
        notification: {
          ...notification,
          variant: notification.variant || "SUCCESS",
        },
      };
    }
    case "HIDE_NOTIFICATION": {
      return { ...state, notification: null };
    }
  }
};
