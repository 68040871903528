import { Button, getButtonClassNames } from "components/Button";
import { Card } from "components/Card";
import { Header } from "domain/online-appointments/components/Header";
import { Wrapper } from "domain/online-appointments/components/Wrapper";
import { useContextService } from "hooks/use-context-service";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import {
  confirmAppointment,
  getAppointment,
} from "../services/online-appointment";
import { ServiceReturnType } from "types";
import { queryClient } from "query";
import { APPOINTMENT_PAY_QUERY } from "../../pay/components/Details";
import { getAppointment as getAppointmentPayment } from "../../pay/services/online-appointment";
import { NegateHorizontalCardPadding } from "components/NegateCardPadding";
import {
  DescriptionList,
  DescriptionListItem,
} from "components/DescriptionList";
import { format } from "date-fns";

const APPOINTMENT_EDIT_QUERY = "appointment-edit";

export const Details = ({
  onSuccess,
}: {
  onSuccess: (data: ServiceReturnType<typeof confirmAppointment>) => void;
}) => {
  const { id } = useParams<{ id: string }>();

  const getAppointmentService = useContextService(getAppointment);

  const { data: appointmentResponse } = useQuery(
    [APPOINTMENT_EDIT_QUERY, id],
    () => getAppointmentService(id)
  );

  const { t } = useTranslation();

  const confirmAppointmentMutation = useMutation(
    useContextService(confirmAppointment),
    {
      onSuccess: (response) => {
        if (
          queryClient.getQueryData([
            APPOINTMENT_PAY_QUERY,
            response.data?.data?.id,
          ])
        ) {
          queryClient.setQueryData(
            [APPOINTMENT_PAY_QUERY, response.data?.data?.id],
            (old): ServiceReturnType<typeof getAppointmentPayment> => {
              const oldCache = old as ServiceReturnType<
                typeof getAppointmentPayment
              >;

              return {
                ...oldCache,
                data: {
                  ...oldCache.data!,
                  data: { ...oldCache.data!.data!, ...response.data?.data! },
                },
              };
            }
          );
        }

        onSuccess(response);
      },
    }
  );

  return (
    <Wrapper>
      <div className="w-full max-w-lg">
        <Card>
          <Header
            title={t("Confirm Appointment Updates")}
            description={t("The date and time was updated by the provider.")}
          />
          <div className="mt-4">
            <NegateHorizontalCardPadding>
              <div className="border-b">
                <DescriptionList>
                  {appointmentResponse?.data?.data?.startDate && (
                    <DescriptionListItem
                      title={
                        appointmentResponse?.data?.data?.recurringAppointments
                          ? t("Appointments")
                          : t("Appointment date")
                      }
                      description={
                        appointmentResponse?.data?.data
                          ?.recurringAppointments ? (
                          <>
                            {appointmentResponse?.data?.data?.recurringAppointments.map(
                              (item, index) => (
                                <div key={index}>
                                  {format(new Date(item), "yyyy-MM-dd HH:mm")}
                                </div>
                              )
                            )}
                          </>
                        ) : (
                          format(
                            new Date(appointmentResponse.data.data.startDate),
                            "yyyy-MM-dd"
                          )
                        )
                      }
                    />
                  )}
                </DescriptionList>
              </div>
            </NegateHorizontalCardPadding>
          </div>
          <div className="flex flex-col mt-5 space-y-4 sm:mt-6">
            <Button
              loading={confirmAppointmentMutation.isLoading}
              disabled={confirmAppointmentMutation.isLoading}
              withSpinner
              onClick={() => confirmAppointmentMutation.mutateAsync(id)}
            >
              {t("Accept")}
            </Button>
            {!appointmentResponse?.data?.data?.recurringAppointments && (
              <Link
                to={`/online-appointment/edit/${appointmentResponse?.data?.data?.teamId}/${appointmentResponse?.data?.data?.id}`}
                className={getButtonClassNames({ variant: "white" })}
              >
                {t("Decline - Change date or time")}
              </Link>
            )}
          </div>
        </Card>
      </div>
    </Wrapper>
  );
};
