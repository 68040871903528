import { z } from "zod";
import { pipe } from "fp-ts/lib/function";
import { useStringRequired } from "hooks/use-validation-input";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import DayPicker from "react-day-picker";
import {
  addMonths,
  differenceInCalendarDays,
  format,
  startOfWeek,
  subDays,
} from "date-fns";
import { FieldLabel } from "components/FieldLabel";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormActionsFooter } from "components/ModalFooter";
import { Button } from "components/Button";
import { SectionHeading } from "components/SectionHeading";
import { getSlots } from "domain/online-appointments/services/online-appointment";
import { useTeam } from "domain/teams/domain/edit/hooks/use-team";
import { useUser } from "hooks/use-user";
import { useContextService } from "hooks/use-context-service";
import { useQuery } from "react-query";
import { LoadingSpinner } from "components/LoadingSpinner";
import { RadioGroupField } from "components/RadioGroupField";

export const useFormSchema = () =>
  z.object({
    date: z.date(),
    slot: pipe(z.string(), useStringRequired()),
  });

export type FormSchema = z.infer<ReturnType<typeof useFormSchema>>;

export const RecurringDatePickerForm = ({
  member,
  onCancel,
  onSubmit,
  selectedDate,
  service,
}: {
  member: App.Domain.API.Teams.Resources.MemberResource;
  service: App.Domain.API.Services.Resources.ServiceResource;
  onCancel: () => void;
  onSubmit: (date: Date) => void;
  selectedDate: Date;
}) => {
  const { control, watch, setValue } = useForm<FormSchema>({
    resolver: zodResolver(useFormSchema()),
    defaultValues: {
      date: selectedDate,
    },
  });

  const { t } = useTranslation();

  const date = watch("date");

  const { data: userResponse } = useUser();

  const { data: teamResponse } = useTeam(
    userResponse?.data?.data?.currentTeam.id
  );

  const getSlotsService = useContextService(getSlots);

  const { data: slotsResponse, isLoading } = useQuery(
    ["slots", teamResponse?.data?.data?.id, date, member.id, service.id],
    () => {
      return getSlotsService({
        id: teamResponse?.data?.data?.externalId!,
        data: {
          date: date.toISOString(),
          memberId: member.id,
          serviceId: service.id,
        },
      });
    },
    {
      onSuccess: (response) => {
        if (response.data?.data.length === 0) {
          setValue("slot", "");
        }
      },
      enabled: Boolean(date),
      suspense: false,
    }
  );

  return (
    <div className="mt-4">
      <SectionHeading>
        <span className="text-base text-gray-700">
          {t("Edit recurring appointment")}
        </span>
      </SectionHeading>
      <div className="max-w-md mt-1 text-sm text-gray-600">
        {t("Currently selected date and time is {{date}}", {
          date: format(selectedDate, "MMM d, yyyy HH:mm"),
        })}
      </div>
      <div className="flex flex-col mt-4 space-y-4 sm:px-0 sm:space-y-0 sm:space-x-4 sm:flex-row">
        <div>
          <FieldLabel htmlFor="date" required>
            {t("Select Date")}
          </FieldLabel>
          <div className="border border-gray-200 rounded-md">
            <Controller
              control={control}
              name="date"
              render={({ field }) => (
                <DayPicker
                  {...field}
                  selectedDays={date}
                  initialMonth={date}
                  onDayClick={(date, mods) => {
                    if (!mods.disabled) {
                      field.onChange(date);

                      setValue("slot", "");
                    }
                  }}
                  firstDayOfWeek={1}
                  disabledDays={[
                    { before: subDays(new Date(), 1) },
                    { after: addMonths(new Date(), 2) },
                    (date: Date) => {
                      return !member.workingDays.includes(
                        differenceInCalendarDays(
                          date,
                          startOfWeek(date, {
                            locale: { options: { weekStartsOn: 1 } },
                          })
                        )
                      );
                    },
                  ]}
                />
              )}
            />
          </div>
        </div>
        {isLoading ? (
          <LoadingSpinner className="w-8 h-8 mt-6 text-indigo-600" />
        ) : slotsResponse?.data?.data.length === 0 ? (
          <p className="mt-6 text-sm text-gray-600">
            {t("There are no available slots for this day.")}
          </p>
        ) : (
          slotsResponse && (
            <div className="w-full">
              <FieldLabel htmlFor="slot" required>
                {t("Select Time")}
              </FieldLabel>
              <Controller
                name="slot"
                control={control}
                render={({ field }) => (
                  <RadioGroupField
                    label={t("Role")}
                    options={
                      slotsResponse?.data?.data.map((item) => {
                        const itemDate = new Date(item);

                        return {
                          name: format(itemDate, "MMM d - HH:mm"),
                          value: itemDate.toISOString(),
                        };
                      }) || []
                    }
                    {...field}
                    onChange={(value) => {
                      field.onChange(value);

                      onSubmit(new Date(value));
                    }}
                  />
                )}
              />
            </div>
          )
        )}
      </div>
      <div className="pt-4 mt-4 border-t lg:col-span-2">
        <FormActionsFooter>
          <Button onClick={onCancel} type="button" variant="white">
            {t("Back to details")}
          </Button>
        </FormActionsFooter>
      </div>
    </div>
  );
};
