import { Transition } from "@headlessui/react";
import { forwardRef, PropsWithoutRef } from "react";
import { FieldProps } from "types";
import { FieldGroup } from "./FieldGroup";
import { LoadingSpinner } from "./LoadingSpinner";

export type TextFieldProps = Omit<
  PropsWithoutRef<JSX.IntrinsicElements["input"]>,
  "className" | "children"
> &
  FieldProps & {
    loading?: boolean;
  };

export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  ({ type = "text", required, optional, loading = false, ...props }, ref) => (
    <FieldGroup required={required} optional={optional} {...props}>
      {(computedProps) => (
        <>
          <input ref={ref} type={type} {...computedProps} {...props} />
          {
            <Transition
              show={loading}
              enter="ease-out duration-300 transition"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="absolute top-0 right-0 flex items-center h-full px-2 pointer-events-none">
                <div className="px-2 py-1 bg-white">
                  <LoadingSpinner className="w-3 h-3 text-indigo-900" />
                </div>
              </div>
            </Transition>
          }
        </>
      )}
    </FieldGroup>
  )
);
