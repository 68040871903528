import { Heading } from "components/Heading";
import { useTranslation } from "react-i18next";
import { Suspense } from "react";
import { ClientsTable } from "./ClientsTable";
import { ContentWrapper } from "components/ContentWrapper";
import { TablePlaceholder } from "components/TablePlaceholder";
import { Card } from "components/Card";
import { useUser } from "hooks/use-user";
import { HeadingWrapper } from "components/HeadingWrapper";
import { NegateCardPadding } from "components/NegateCardPadding";
import { Placeholder } from "components/Placeholder";

export const Content = () => {
  const { data: userResponse } = useUser();

  return (
    <>
      <ClientsTable key={userResponse?.data?.data?.currentTeam.id!} />
    </>
  );
};

export const Container = () => {
  const { t } = useTranslation();

  return (
    <ContentWrapper>
      <Suspense
        fallback={
          <>
            <HeadingWrapper>
              <Heading>{t("Clients")}</Heading>
              <Placeholder widthClassName="w-24" heightClassName="h-10" />
            </HeadingWrapper>
            <Card>
              <NegateCardPadding>
                <TablePlaceholder />
              </NegateCardPadding>
            </Card>
          </>
        }
      >
        <Content />
      </Suspense>
    </ContentWrapper>
  );
};
