import { Context, ResponsePayload } from "types";

export const createTeam =
  ({ apiClient }: Context) =>
  (data: App.Domain.API.Teams.Resources.SaveTeamResource) =>
    apiClient<ResponsePayload<App.Domain.API.Account.Resources.UserResource>>({
      url: "/teams",
      method: "POST",
      data,
    });
