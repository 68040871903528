import { Context, ResponsePayload } from "types";

export const createClient =
  ({ apiClient }: Context) =>
  (data: App.Domain.API.Clients.Resources.SaveClientResource) =>
    apiClient<ResponsePayload<App.Domain.API.Clients.Resources.ClientResource>>(
      {
        url: "/clients",
        method: "POST",
        data,
      }
    );
