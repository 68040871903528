import { CheckCircleIcon } from "@heroicons/react/outline";
import { useTeam } from "domain/teams/domain/edit/hooks/use-team";
import { useContextService } from "hooks/use-context-service";
import { USER_QUERY, useUser } from "hooks/use-user";
import { queryClient } from "query";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { switchTeam } from "services/team";
import { LoadingSpinner } from "./LoadingSpinner";
import { MobileNavButton, MobileNavLink } from "./MobileNavLink";

const Label = ({ children }: { children: ReactNode }) => (
  <span className="px-3 text-xs text-gray-400">{children}</span>
);

export const TeamMobileNav = ({ onNavigate }: { onNavigate: () => void }) => {
  const { data: userResponse } = useUser();

  const { data: teamResponse } = useTeam(
    userResponse?.data?.data?.currentTeam.id
  );

  const { t } = useTranslation();

  const switchTeamMutation = useMutation(useContextService(switchTeam), {
    onSuccess: (response) => {
      queryClient.setQueryData(USER_QUERY, response);
    },
  });

  return (
    <div className="space-y-1">
      <div className="flex items-center">
        <Label>{t("Switch Teams")}</Label>
        {switchTeamMutation.isLoading && (
          <LoadingSpinner className="w-3 text-indigo-900" />
        )}
      </div>
      {userResponse?.data?.data?.teams.map((item) => (
        <MobileNavButton
          key={item.id}
          onClick={() => switchTeamMutation.mutate({ teamId: item.id })}
          disabled={switchTeamMutation.isLoading}
        >
          {item.id === userResponse?.data?.data?.currentTeam.id &&
            !switchTeamMutation.isLoading && (
              <div className="w-4 mr-2">
                <CheckCircleIcon className="w-4 text-green-500" />
              </div>
            )}
          <span className="overflow-hidden overflow-ellipsis">{item.name}</span>
        </MobileNavButton>
      ))}

      <Label>{t("Manage Team")}</Label>
      <MobileNavLink to="/teams/edit" onClick={onNavigate}>
        {t("Team settings")}
      </MobileNavLink>
      <MobileNavLink to="/teams/create" onClick={onNavigate}>
        {t("Create new team")}
      </MobileNavLink>
      {teamResponse?.data?.data?.owner.id === userResponse?.data?.data?.id && (
        <MobileNavLink to="/transactions" onClick={onNavigate}>
          {t("Transactions")}
        </MobileNavLink>
      )}
      {(teamResponse?.data?.data?.owner.id === userResponse?.data?.data?.id ||
        userResponse?.data?.data?.currentTeam.role === "ADMIN") && (
        <MobileNavLink to="/services" onClick={onNavigate}>
          {t("Services")}
        </MobileNavLink>
      )}
      <MobileNavLink to="/schedule" onClick={onNavigate}>
        {t("Schedule")}
      </MobileNavLink>
    </div>
  );
};
