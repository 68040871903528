import { useContextService } from "hooks/use-context-service";
import { useQuery } from "react-query";
import { useParams } from "react-router";
import { getClient } from "../services/client";

export const CLIENT_QUERY = "client";

export const useClient = () => {
  const { id } = useParams<{ id: string }>();

  const getClientService = useContextService(getClient);

  return useQuery([CLIENT_QUERY, id], () => getClientService(id));
};
