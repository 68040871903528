import { Context, ResponsePayload } from "types";

export const switchTeam =
  ({ apiClient }: Context) =>
  (data: App.Domain.API.Teams.Resources.SwitchTeamResource) =>
    apiClient<ResponsePayload<App.Domain.API.Account.Resources.UserResource>>({
      url: "/teams/switch",
      method: "PUT",
      data,
    });
