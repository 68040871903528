import { Loading } from "domain/online-appointments/components/Loading";
import { Suspense } from "react";
import { Wizard } from "./Wizard";

export const Container = () => {
  return (
    <Suspense fallback={<Loading />}>
      <Wizard />
    </Suspense>
  );
};
