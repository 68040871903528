import { CheckIcon } from "@heroicons/react/solid";

interface Step {
  name: string;
  value: string;
}

interface StepsProps {
  steps: Step[];
  activeStep: string;
  onStepClick: (step: Step) => void;
}

export const Steps = ({ onStepClick, steps, activeStep }: StepsProps) => {
  const activeStepIndex = steps.findIndex((item) => item.value === activeStep);

  const onClickHandler = ({ step, index }: { step: Step; index: number }) => {
    if (index < activeStepIndex) {
      onStepClick(step);
    }
  };

  return (
    <nav aria-label="Progress">
      <ol className="bg-white border border-gray-300 divide-y divide-gray-300 rounded-md md:flex md:divide-y-0">
        {steps.map((step, index) => (
          <li key={step.name} className="relative md:flex-1 md:flex">
            {index < activeStepIndex ? (
              <button
                onClick={() => onClickHandler({ step, index })}
                type="button"
                className="flex items-center w-full group focus:outline-none"
              >
                <span className="flex items-center px-6 py-4 text-sm font-medium">
                  <span className="flex items-center justify-center flex-shrink-0 w-10 h-10 bg-indigo-600 rounded-full group-hover:bg-indigo-800">
                    <CheckIcon
                      className="w-6 h-6 text-white"
                      aria-hidden="true"
                    />
                  </span>
                  <span className="ml-4 text-sm font-medium text-gray-900">
                    {step.name}
                  </span>
                </span>
              </button>
            ) : index === activeStepIndex ? (
              <button
                onClick={() => onClickHandler({ step, index })}
                type="button"
                className="flex items-center px-6 py-4 text-sm font-medium focus:outline-none"
                aria-current="step"
              >
                <span className="flex items-center justify-center flex-shrink-0 w-10 h-10 border-2 border-indigo-600 rounded-full">
                  <span className="text-indigo-600">{index + 1}</span>
                </span>
                <span className="ml-4 text-sm font-medium text-indigo-600">
                  {step.name}
                </span>
              </button>
            ) : (
              <button
                onClick={() => onClickHandler({ step, index })}
                type="button"
                className="flex items-center cursor-not-allowed focus:outline-none"
              >
                <span className="flex items-center px-6 py-4 text-sm font-medium">
                  <span className="flex items-center justify-center flex-shrink-0 w-10 h-10 border-2 border-gray-300 rounded-full">
                    <span className="text-gray-500">
                      {index + 1}
                    </span>
                  </span>
                  <span className="ml-4 text-sm font-medium text-gray-500">
                    {step.name}
                  </span>
                </span>
              </button>
            )}

            {index !== steps.length - 1 ? (
              <div
                className="absolute top-0 right-0 hidden w-5 h-full md:block"
                aria-hidden="true"
              >
                <svg
                  className="w-full h-full text-gray-300"
                  viewBox="0 0 22 80"
                  fill="none"
                  preserveAspectRatio="none"
                >
                  <path
                    d="M0 -2L20 40L0 82"
                    vectorEffect="non-scaling-stroke"
                    stroke="currentcolor"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            ) : null}
          </li>
        ))}
      </ol>
    </nav>
  );
};
