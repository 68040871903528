import { RouteSwitch } from "components/RouteSwitch";
import { Route, useRouteMatch } from "react-router-dom";
import { CreateService } from "../domain/create";
import { ServicesList } from "../domain/list";

export const Container = () => {
  const match = useRouteMatch();

  return (
    <RouteSwitch>
      <Route exact path={match.path + "/create"}>
        <CreateService />
      </Route>
      <Route exact path={match.path}>
        <ServicesList />
      </Route>
    </RouteSwitch>
  );
};
