import { RadioGroup } from "@headlessui/react";
import classNames from "classnames";
import { forwardRef, ReactNode } from "react";
import { FieldProps } from "types";

interface Option {
  name: string | ReactNode;
  description?: string | null | ReactNode;
  value: string;
}

interface RadioGroupFieldProps extends FieldProps {
  options: Option[];
  value: string | null;
  onChange: (value: string) => void;
}

export const RadioGroupField = forwardRef<HTMLDivElement, RadioGroupFieldProps>(
  ({ options, label, ...props }, ref) => {
    return (
      <RadioGroup {...props}>
        {/* <RadioGroup.Label className="sr-only">{label}</RadioGroup.Label> */}
        <div className="-space-y-px bg-white rounded-md">
          {options.map((option, index) => (
            <RadioGroup.Option
              key={index}
              value={option.value}
              className={({ checked }) =>
                classNames(
                  index === 0 ? "rounded-tl-md rounded-tr-md" : "",
                  index === options.length - 1
                    ? "rounded-bl-md rounded-br-md"
                    : "",
                  checked
                    ? "bg-indigo-50 border-indigo-200 z-10"
                    : "border-gray-200",
                  "relative border p-4 flex cursor-pointer focus:outline-none"
                )
              }
            >
              {({ active, checked }) => (
                <>
                  <div aria-hidden="true" className="w-4 h-4">
                    <span
                      className={classNames(
                        checked
                          ? "bg-indigo-600 border-transparent"
                          : "bg-white border-gray-300",
                        active ? "ring-2 ring-offset-2 ring-indigo-500" : "",
                        "h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center"
                      )}
                      aria-hidden="true"
                    >
                      <span className="rounded-full bg-white w-1.5 h-1.5" />
                    </span>
                  </div>
                  <div className="flex flex-col ml-3">
                    <RadioGroup.Label
                      as="span"
                      className={classNames(
                        checked ? "text-indigo-900" : "text-gray-900",
                        "block text-sm font-medium"
                      )}
                    >
                      {option.name}
                    </RadioGroup.Label>
                    <RadioGroup.Description
                      as="span"
                      className={classNames(
                        checked ? "text-indigo-700" : "text-gray-500",
                        "block text-sm"
                      )}
                    >
                      {option.description}
                    </RadioGroup.Description>
                  </div>
                </>
              )}
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
    );
  }
);
