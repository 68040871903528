import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { pipe } from "fp-ts/lib/function";
import { Button } from "components/Button";
import { FormSection } from "components/FormSection";
import { TextField } from "components/TextField";
import { useStringMax, useStringRequired } from "hooks/use-validation-input";
import { useContextService } from "hooks/use-context-service";
import { showNotification as showNotificationService } from "services/notification";
import { USER_QUERY, useUser } from "hooks/use-user";
import { useMutation } from "react-query";
import { updateTeam } from "../services/team";
import { queryClient } from "query";
import { UserInfoFormHint } from "components/UserInfoFormHint";
import { useTeam } from "../hooks/use-team";

const useFormSchema = () =>
  z.object({
    name: pipe(z.string(), useStringMax(50), useStringRequired()),
  });

type FormSchema = z.infer<ReturnType<typeof useFormSchema>>;

export const EditTeamForm = () => {
  const { t } = useTranslation();

  const { data: userResponse } = useUser();

  const { data: teamResponse } = useTeam(
    userResponse?.data?.data?.currentTeam.id
  );

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormSchema>({
    resolver: zodResolver(useFormSchema()),
    defaultValues: teamResponse?.data?.data,
  });

  const showNotification = useContextService(showNotificationService);

  const updateTeamMutation = useMutation(useContextService(updateTeam), {
    onSuccess: (response) => {
      queryClient.setQueryData(USER_QUERY, response);

      showNotification({
        title: t("Successfully updated"),
        message: t("Your team was updated."),
      });
    },
  });

  return (
    <form
      onSubmit={handleSubmit((data) =>
        updateTeamMutation.mutateAsync({
          data,
          id: userResponse?.data?.data?.currentTeam.id!,
        })
      )}
    >
      <FormSection
        title={t("Team details")}
        description={t("Here you can edit the main details of your team.")}
        footer={
          <Button type="submit" disabled={isSubmitting} loading={isSubmitting}>
            {t("Save")}
          </Button>
        }
      >
        <div className="max-w-xl">
          {teamResponse?.data?.data && (
            <UserInfoFormHint
              label={t("Team owner")}
              name={teamResponse?.data?.data?.owner.fullName}
              avatar={teamResponse?.data?.data?.owner.avatar}
              email={teamResponse?.data?.data?.owner.email}
            />
          )}
        </div>
        <div className="grid gap-4 lg:grid-cols-2">
          <TextField
            {...register("name")}
            label={t("Team name")}
            error={errors.name}
            required
          />
        </div>
      </FormSection>
    </form>
  );
};
