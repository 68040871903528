import { useTranslation } from "react-i18next";
import { Controller, useFormContext } from "react-hook-form";
import { Button } from "components/Button";
import { useUser } from "hooks/use-user";
import { FormActionsFooter } from "components/ModalFooter";
import { RadioGroupField } from "components/RadioGroupField";
import { TextField } from "components/TextField";
import { useQuery } from "react-query";
import { useContextService } from "hooks/use-context-service";
import { getClients } from "domain/clients/domain/list/services/clients";
import useDebouncedState from "hooks/use-debounced-state";
import { useState } from "react";

export const APPOINTMENT_CLIENTS_QUERY = "appointmentClients";

export const ClientSelectForm = ({
  onCancel,
  onCreateNewClient,
}: {
  onCancel: () => void;
  onCreateNewClient: () => void;
}) => {
  const { t } = useTranslation();

  const { data: userResponse } = useUser();

  const getClientsService = useContextService(getClients);

  const [clientSearchTerm, setClientSearchTerm] = useState("");

  const debouncedClientSearchTerm = useDebouncedState(clientSearchTerm);

  const clientsQuery = useQuery(
    [
      APPOINTMENT_CLIENTS_QUERY,
      userResponse?.data?.data?.currentTeam.id!,
      debouncedClientSearchTerm,
    ],
    () => getClientsService({ page: 1, searchTerm: clientSearchTerm, filter: null }),
    {
      keepPreviousData: true,
    }
  );

  const { control, setValue } = useFormContext();

  return (
    <div className="grid gap-4 lg:grid-cols-2">
      <div className="lg:col-span-2">
        <TextField
          label={t("Client search")}
          placeholder={t("Search by name, email, phone, etc.")}
          name="client-search"
          value={clientSearchTerm}
          onChange={(e) => setClientSearchTerm(e.target.value)}
          loading={clientsQuery.isFetching}
        />
      </div>
      <div className="flex flex-col lg:col-span-2">
        <Controller
          name="client.id"
          control={control}
          render={({ field }) =>
            clientsQuery.isFetched &&
            clientsQuery.data!.data!.data.length === 0 ? (
              <p className="text-sm text-gray-500">{t("No data was found.")}</p>
            ) : (
              <RadioGroupField
                {...field}
                label={t("Client")}
                onChange={(value) => {
                  field.onChange(value);

                  setValue(
                    "client",
                    clientsQuery.data?.data?.data.find(
                      (item) => item.id === value
                    )!
                  );
                }}
                options={
                  clientsQuery.data?.data?.data.slice(0, 5).map((item) => ({
                    name: item.fullName,
                    value: item.id,
                    description: item.email,
                  })) || []
                }
                required
              />
            )
          }
        />
        <div className="flex flex-col mt-4">
          <Button onClick={onCreateNewClient}>{t("Create new client")}</Button>
        </div>
      </div>
      <div className="lg:col-span-2">
        <FormActionsFooter>
          <Button onClick={onCancel} type="button" variant="white">
            {t("Back to details")}
          </Button>
        </FormActionsFooter>
      </div>
    </div>
  );
};
