import { PropsWithoutRef } from "react";

export interface CardProps
  extends Omit<PropsWithoutRef<JSX.IntrinsicElements["div"]>, "className"> {}

export const Card = ({ children, ...props }: CardProps) => (
  <div
    {...props}
    className="w-full overflow-hidden bg-white shadow sm:rounded-lg"
  >
    <div className="px-4 py-5 sm:p-6">{children}</div>
  </div>
);
