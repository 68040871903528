import { BadgeVariant } from "components/Badge";
import { useTranslation } from "react-i18next";

export const useAppointmentStateBadges = (): Record<
  App.States.AppointmentState.AppointmentState,
  { label: string; variant: BadgeVariant }
> => {
  const { t } = useTranslation();

  return {
    PENDING_PAYMENT: {
      label: t("Online Appointment (Unpaid)"),
      variant: "secondary",
    },
    PENDING_CONFIRMATION: {
      label: t("Pending Confirmation (Paid)"),
      variant: "warning",
    },
    PENDING_CLIENT_CONFIRMATION: {
      label: t("Pending Client Confirmation (Unpaid)"),
      variant: "warningSecondary",
    },
    PAID_PENDING_CLIENT_CONFIRMATION: {
      label: t("Pending Client Confirmation (Paid)"),
      variant: "warningSecondary",
    },
    CONFIRMED: {
      label: t("Confirmed"),
      variant: "primary",
    },
    COMPLETED: {
      label: t("Completed"),
      variant: "success",
    },
  };
};
