import { ComponentProps, ReactNode } from "react";
import { Route } from "react-router";
import { Layout } from "./Layout";

export const LayoutRoute = ({
  children,
  ...props
}: {
  children: ReactNode;
} & ComponentProps<typeof Route>) => (
  <Route {...props}>
    <Layout>{children}</Layout>
  </Route>
);
