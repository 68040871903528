import { Context } from "types";

export const getTransactions =
  ({ apiClient }: Context) =>
  (data?: App.Resources.PaginateResource) =>
    apiClient<App.Domain.API.Transactions.Resources.TransactionsResource>({
      url: "/transactions",
      method: "GET",
      params: data,
    });
