import { ReactNode } from "react";

export interface FormActionsFooterProps {
  children: ReactNode;
}

export const FormActionsFooter = ({ children }: FormActionsFooterProps) => (
  <div className="flex flex-col-reverse mt-5 space-y-4 space-y-reverse sm:mt-4 sm:flex-row sm:justify-end sm:space-y-0 sm:space-x-3">
    {children}
  </div>
);
