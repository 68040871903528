import { Context, ResponsePayload } from "types";

export const getSchedules =
  ({ apiClient }: Context) =>
  () =>
    apiClient<App.Domain.API.Schedules.Resources.SchedulesResource>({
      url: "/schedules",
    });

export const createSchedule =
  ({ apiClient }: Context) =>
  (data: App.Domain.API.Schedules.Resources.CreateScheduleResource) =>
    apiClient<
      ResponsePayload<App.Domain.API.Schedules.Resources.ScheduleResource>
    >({
      url: "/schedules",
      method: "POST",
      data,
    });

export const updateSchedule =
  ({ apiClient }: Context) =>
  ({
    id,
    data,
  }: {
    id: string;
    data: App.Domain.API.Schedules.Resources.UpdateScheduleResource;
  }) =>
    apiClient<
      ResponsePayload<App.Domain.API.Schedules.Resources.ScheduleResource>
    >({
      url: `/schedules/${id}`,
      method: "PUT",
      data,
    });

export const deleteSchedule =
  ({ apiClient }: Context) =>
  (id: string) =>
    apiClient({
      url: `/schedules/${id}`,
      method: "DELETE",
    });
