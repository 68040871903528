import { useCallback, useState } from "react";

export const useModal = ({ onClose }: { onClose?: () => void } = {}) => {
  const [modalVisible, setModalVisible] = useState(false);

  const openModal = useCallback(() => setModalVisible(true), [setModalVisible]);

  const closeModal = useCallback(() => {
    setModalVisible(false);

    if (onClose) {
      onClose();
    }
  }, [setModalVisible, onClose]);

  return {
    modalVisible,
    setModalVisible,
    openModal,
    closeModal,
  };
};
