export interface Env {
  REACT_APP_API_URL: string;
  REACT_APP_LOGIN_URL: string;
  REACT_APP_VENDOR_ID: string;
}

declare global {
  namespace NodeJS {
    interface ProcessEnv extends Env {}
  }
}

const assert = (condition: any, message: string): asserts condition => {
  if (!condition) {
    throw new Error(message);
  }
};

const assertEnvVar = (envVar: keyof Env) => {
  return assert(
    process.env[envVar] !== undefined,
    `Environment variable ${envVar} is required`
  );
};

assertEnvVar("REACT_APP_API_URL");
assertEnvVar("REACT_APP_LOGIN_URL");
assertEnvVar("REACT_APP_VENDOR_ID");
