import { Context } from "types";

export const getClients =
  ({ apiClient }: Context) =>
  (data?: App.Domain.API.Clients.Resources.RetrieveClientsResource) =>
    apiClient<App.Domain.API.Clients.Resources.ClientsResource>({
      url: "/clients",
      method: "GET",
      params: data,
    });
