import {
  ChevronLeftIcon,
  ChevronRightIcon,
  SelectorIcon,
  UserIcon,
} from "@heroicons/react/solid";
import { CheckCircleIcon } from "@heroicons/react/outline";
import { Menu, MenuButton, MenuLabel } from "components/Menu";
import format from "date-fns/format";
import { useTranslation } from "react-i18next";
import { useUser } from "hooks/use-user";
import { useTeam } from "domain/teams/domain/edit/hooks/use-team";
import { Button } from "components/Button";

export const Header = ({
  startDate,
  endDate,
  selectedUserId,
  todayNotInView,
  onUserSelect,
  onToday,
  onPrev,
  onNext,
}: {
  startDate: string;
  endDate: string;
  selectedUserId: string;
  todayNotInView: boolean;
  onUserSelect: (id: string) => void;
  onToday: () => void;
  onPrev: () => void;
  onNext: () => void;
}) => {
  const { t } = useTranslation();

  const { data: userResponse } = useUser();

  const { data: teamResponse } = useTeam(
    userResponse?.data?.data?.currentTeam.id
  );

  return (
    <div className="flex px-4 py-5 md:justify-between md:items-center sm:p-6">
      <div className="hidden text-3xl font-extrabold md:block">
        {format(new Date(startDate), "MMM d")} -
        {format(new Date(endDate), "MMM d, yyyy")}
      </div>
      <div className="flex flex-row-reverse justify-between w-full md:w-auto md:justify-end md:flex-row">
        {userResponse?.data?.data?.currentTeam.isOwner && (
          <Menu
            content={
              <>
                <MenuLabel>{t("Team Members")}</MenuLabel>
                {teamResponse?.data?.data?.members.map((item) => (
                  <MenuButton
                    key={item.id}
                    onClick={() => onUserSelect(item.id)}
                  >
                    {item.id === selectedUserId && (
                      <div className="w-4 mr-1">
                        <CheckCircleIcon className="w-4 text-green-500" />
                      </div>
                    )}
                    {item.fullName}
                  </MenuButton>
                ))}
              </>
            }
          >
            <Button variant="white" size="small">
              <span className="hidden md:inline">
                {
                  teamResponse?.data?.data?.members.find(
                    (item) => item.id === selectedUserId
                  )?.fullName
                }
              </span>
              <span className="inline md:hidden">
                <UserIcon className="w-5 text-gray-500" />
              </span>
              <span className="inline-block ml-1">
                <SelectorIcon className="w-5 text-gray-500" />
              </span>
            </Button>
          </Menu>
        )}
        <div className="flex mr-2 space-x-2 md:ml-2 md:mr-0">
          <Button
            variant="white"
            size="small"
            onClick={onToday}
            disabled={!todayNotInView}
          >
            {t("Today")}
          </Button>
          <Button variant="white" size="small" onClick={onPrev}>
            <ChevronLeftIcon className="w-5 h-5" />
          </Button>
          <Button variant="white" size="small" onClick={onNext}>
            <ChevronRightIcon className="w-5 h-5" />
          </Button>
        </div>
      </div>
    </div>
  );
};
