import { useTranslation } from "react-i18next";
import { useContextService } from "hooks/use-context-service";
import { showNotification as showNotificationService } from "services/notification";
import { useMutation } from "react-query";
import { createService } from "../services/service";
import { queryClient } from "query";
import { useHistory } from "react-router";
import { useUser } from "hooks/use-user";
import { ServiceReturnType } from "types";
import { ServiceForm } from "domain/services/components/ServiceForm";
import { getServices } from "services/services";
import { SERVICES_QUERY } from "hooks/use-services";

export const CreateServiceForm = () => {
  const { t } = useTranslation();

  const showNotification = useContextService(showNotificationService);

  const history = useHistory();

  const { data: userResponse } = useUser();

  const createServiceMutation = useMutation(useContextService(createService), {
    onSuccess: (response) => {
      const queryKey = [
        SERVICES_QUERY,
        userResponse?.data?.data?.currentTeam.id!,
      ];

      if (queryClient.getQueryData(queryKey)) {
        queryClient.setQueryData(
          queryKey,
          (old): ServiceReturnType<typeof getServices> => {
            const oldCache = old as ServiceReturnType<typeof getServices>;

            return {
              ...oldCache,
              data: {
                ...oldCache.data!,
                data: [response.data?.data!, ...oldCache.data!.data!],
              },
            };
          }
        );

        queryClient.refetchQueries(queryKey);
      }

      showNotification({
        title: t("Successfully created"),
        message: t("You added a new service."),
      });

      history.push("/services");
    },
  });

  return (
    <ServiceForm onSubmit={(data) => createServiceMutation.mutateAsync(data)} />
  );
};
