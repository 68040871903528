import { LoadingSpinner } from "components/LoadingSpinner";
import { useTranslation } from "react-i18next";

export const Loading = () => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center justify-center w-full h-full min-h-screen bg-gray-50">
      <LoadingSpinner className="w-10 h-10 text-indigo-800" />
      <h3 className="mt-2 font-medium text-indigo-900">{t("Loading")}</h3>
    </div>
  );
};
