import { RouteSwitch } from "components/RouteSwitch";
import { Route, useRouteMatch } from "react-router-dom";
import { ProfileEdit } from "../domain/edit";

export const Container = () => {
  const match = useRouteMatch();

  return (
    <RouteSwitch>
      <Route exact path={match.path + "/edit"}>
        <ProfileEdit />
      </Route>
    </RouteSwitch>
  );
};
