import { CheckCircleIcon } from "@heroicons/react/outline";
import { Card } from "components/Card";
import { Header } from "domain/online-appointments/components/Header";

import { Loading } from "domain/online-appointments/components/Loading";
import { Wrapper } from "domain/online-appointments/components/Wrapper";
import { Suspense, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Details } from "./Details";

export const Container = () => {
  const [submitted, setSubmitted] = useState(false);

  const { t } = useTranslation();

  const history = useHistory();

  if (submitted) {
    return (
      <Wrapper>
        <div className="w-full max-w-lg">
          <Card>
            <div className="text-center">
              <CheckCircleIcon className="w-10 mx-auto text-green-500" />
              <Header
                title={t("Successfully accepted")}
                description={t("Thank you for your answer!")}
              />
            </div>
          </Card>
        </div>
      </Wrapper>
    );
  }

  return (
    <Suspense fallback={<Loading />}>
      <Details
        onSuccess={(response) => {
          if (response.data?.data?.state === "PENDING_PAYMENT") {
            history.push(`/online-appointment/pay/${response.data.data.id}`);
          } else {
            setSubmitted(true);
          }
        }}
      />
    </Suspense>
  );
};
