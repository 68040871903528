import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { Button } from "components/Button";
import { FormSection } from "components/FormSection";
import { useContextService } from "hooks/use-context-service";
import { showNotification as showNotificationService } from "services/notification";
import { USER_QUERY, useUser } from "hooks/use-user";
import { useMutation } from "react-query";
import { queryClient } from "query";
import { FileField } from "components/FileField";
import { Avatar } from "components/Avatar";
import { useMemo } from "react";
import { pipe } from "fp-ts/lib/function";
import {
  useFileMaxSize,
  useFileMimes,
  useFileRequired,
} from "hooks/use-validation-input";
import { updateAvatar, removeAvatar } from "../services/profile";
import { ServiceReturnType } from "types";

const allowedMimes = ["JPG", "PNG"];

const maxFileSize = 1 * 1024 * 1024;

const useFormSchema = () =>
  z.object({
    avatar: pipe(
      z.instanceof(FileList),
      useFileRequired(),
      useFileMimes(allowedMimes),
      useFileMaxSize(maxFileSize)
    ),
  });

type FormSchema = z.infer<ReturnType<typeof useFormSchema>>;

export const AvatarForm = () => {
  const { t } = useTranslation();

  const { data: userResponse } = useUser();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<FormSchema>({
    resolver: zodResolver(useFormSchema()),
  });

  const showNotification = useContextService(showNotificationService);

  const onMutationSuccess =
    (message: string) =>
    (
      response: ServiceReturnType<typeof updateAvatar | typeof removeAvatar>
    ) => {
      queryClient.setQueryData(USER_QUERY, response);

      showNotification({
        title: t("Successfully updated"),
        message,
      });

      reset();
    };

  const updateAvatarMutation = useMutation(useContextService(updateAvatar), {
    onSuccess: onMutationSuccess("Your profile photo was updated."),
  });

  const removeAvatarMutation = useMutation(useContextService(removeAvatar), {
    onSuccess: onMutationSuccess("Your profile photo was removed."),
  });

  const selectedFiles = watch("avatar");

  const previewImage = useMemo(
    () =>
      selectedFiles?.length ? URL.createObjectURL(selectedFiles[0]) : null,
    [selectedFiles]
  );

  return (
    <form
      onSubmit={handleSubmit((data) =>
        updateAvatarMutation.mutateAsync({ file: data.avatar[0] })
      )}
    >
      <FormSection
        title={t("Profile photo")}
        description={t("Here you can update your public profile photo.")}
        footer={
          <>
            {userResponse?.data?.data?.avatar && (
              <Button
                variant="white"
                disabled={isSubmitting || removeAvatarMutation.isLoading}
                loading={isSubmitting || removeAvatarMutation.isLoading}
                onClick={() => removeAvatarMutation.mutate()}
              >
                {t("Remove my avatar")}
              </Button>
            )}
            <Button type="submit" disabled={isSubmitting} loading={isSubmitting}>
              {t("Submit")}
            </Button>
          </>
        }
      >
        <FileField
          {...register("avatar")}
          label={t("Avatar")}
          error={errors.avatar}
          mimes={allowedMimes}
          maxFileSize={maxFileSize}
          preview={
            <Avatar
              size="xl"
              name={
                previewImage ? t("Avatar") : userResponse?.data?.data?.fullName
              }
              src={previewImage || userResponse?.data?.data?.avatar}
            />
          }
          onDrop={(files) => {
            setValue("avatar", files);
          }}
        />
      </FormSection>
    </form>
  );
};
