import { useLogout } from "hooks/use-logout";
import { useUser } from "hooks/use-user";
import { useTranslation } from "react-i18next";
import { Avatar } from "./Avatar";
import { MobileNavLink, MobileNavButton } from "./MobileNavLink";
import { Ping } from "./Ping";

export const AccountMobileNav = ({
  onNavigate,
}: {
  onNavigate: () => void;
}) => {
  const { data: userResponse } = useUser();

  const { t } = useTranslation();

  const { logoutMutation } = useLogout();

  return (
    <>
      <div className="flex items-center px-3">
        <div className="flex-shrink-0">
          <Avatar
            size="large"
            name={userResponse?.data?.data?.fullName}
            src={userResponse?.data?.data?.avatar}
          />
        </div>
        <div className="flex-1 min-w-0 ml-3">
          <div className="text-base font-medium text-gray-800 truncate">
            {userResponse?.data?.data?.fullName}
          </div>
          <div className="text-sm font-medium text-gray-500 truncate">
            {userResponse?.data?.data?.email}
          </div>
        </div>
      </div>
      <div className="mt-2 space-y-1">
        {userResponse?.data?.data?.hasPendingInvitations && (
          <MobileNavLink to="/teams/invitations">
            {t("Team Invitations")}
            <span className="-mt-3">
              <Ping />
            </span>
          </MobileNavLink>
        )}
        <MobileNavLink to="/profile/edit" onClick={onNavigate}>
          {t("Edit profile")}
        </MobileNavLink>
        <MobileNavButton onClick={() => logoutMutation.mutate()}>
          {t("Sign out")}
        </MobileNavButton>
      </div>
    </>
  );
};
