import { Context, ResponsePayload } from "types";

export const getAppointment =
  ({ apiClient }: Context) =>
  (id: string) =>
    apiClient<
      ResponsePayload<App.Domain.API.OnlineAppointments.Resources.AppointmentResource>
    >({
      url: `/online-appointment/edit/${id}`,
      method: "GET",
    });

export const editAppointment =
  ({ apiClient }: Context) =>
  ({
    id,
    data,
  }: {
    id: string;
    data: App.Domain.API.OnlineAppointments.Resources.UpdateAppointmentResource;
  }) =>
    apiClient<
      ResponsePayload<App.Domain.API.OnlineAppointments.Resources.AppointmentPaymentResource>
    >({
      url: `/online-appointment/edit/${id}`,
      method: "PUT",
      data,
    });
