import { Avatar } from "components/Avatar";
import { Button, getButtonClassNames } from "components/Button";
import { Card } from "components/Card";
import {
  DescriptionList,
  DescriptionListItem,
} from "components/DescriptionList";
import { NegateHorizontalCardPadding } from "components/NegateCardPadding";
import { format } from "date-fns";
import { Header } from "domain/online-appointments/components/Header";
import { Wrapper } from "domain/online-appointments/components/Wrapper";
import { useContextService } from "hooks/use-context-service";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { getAppointment } from "../services/online-appointment";
import currency from "currency.js";

export const APPOINTMENT_PAY_QUERY = "appointment-pay";

const paddle = (window as any).Paddle;

export const Details = ({
  onPaymentSuccess,
}: {
  onPaymentSuccess: () => void;
}) => {
  const { id } = useParams<{ id: string }>();

  const getAppointmentService = useContextService(getAppointment);

  const { data: appointmentResponse } = useQuery(
    [APPOINTMENT_PAY_QUERY, id],
    () => getAppointmentService(id)
  );

  const { t } = useTranslation();

  useEffect(() => {
    paddle.Environment.set("sandbox");

    paddle.Setup({ vendor: Number.parseInt(process.env.REACT_APP_VENDOR_ID) });
  }, []);

  const openPaymentWidget = () =>
    paddle.Checkout.open({
      override: appointmentResponse?.data?.data?.paymentLink,
      successCallback: onPaymentSuccess,
    });

  return (
    <Wrapper>
      <div className="w-full max-w-lg">
        <Card>
          <Header
            title={t("Appointment Payment")}
            description={t("Please confirm your details below.")}
          />
          <div className="mt-4">
            <NegateHorizontalCardPadding>
              <div className="border-b">
                <DescriptionList>
                  <DescriptionListItem
                    title={t("Client name")}
                    description={
                      appointmentResponse?.data?.data?.client.fullName
                    }
                  />
                  {appointmentResponse?.data?.data?.startDate && (
                    <DescriptionListItem
                      title={
                        appointmentResponse?.data?.data?.recurringAppointments
                          ? t("Appointments")
                          : t("Appointment date")
                      }
                      description={
                        appointmentResponse?.data?.data
                          ?.recurringAppointments ? (
                          <>
                            {appointmentResponse?.data?.data?.recurringAppointments.map(
                              (item, index) => (
                                <div key={index}>
                                  {format(new Date(item), "yyyy-MM-dd HH:mm")}
                                </div>
                              )
                            )}
                          </>
                        ) : (
                          format(
                            new Date(appointmentResponse.data.data.startDate),
                            "yyyy-MM-dd"
                          )
                        )
                      }
                    />
                  )}
                  {!appointmentResponse?.data?.data?.recurringAppointments &&
                    appointmentResponse?.data?.data?.startDate && (
                      <DescriptionListItem
                        title={t("Appointment time")}
                        description={format(
                          new Date(appointmentResponse.data.data.startDate),
                          "HH:mm"
                        )}
                      />
                    )}
                  <DescriptionListItem
                    title={t("Provider")}
                    description={
                      <div className="flex items-center space-x-2">
                        <Avatar
                          size="xs"
                          src={appointmentResponse?.data?.data?.member.avatar}
                          name={
                            appointmentResponse?.data?.data?.member.fullName
                          }
                        />
                        <span>
                          {appointmentResponse?.data?.data?.member.fullName}
                        </span>
                      </div>
                    }
                  />
                </DescriptionList>
              </div>
            </NegateHorizontalCardPadding>
          </div>
          <div className="flex flex-col mt-5 space-y-4 sm:mt-6">
            <Button withSpinner onClick={openPaymentWidget}>
              {t("Pay {{amount}}", {
                amount: appointmentResponse?.data?.data?.price
                  ? currency(appointmentResponse?.data?.data?.price, {
                      fromCents: true,
                      symbol: "€",
                    }).format()
                  : 0,
              })}
            </Button>
            {!appointmentResponse?.data?.data?.recurringAppointments && (
              <Link
                to={`/online-appointment/edit/${appointmentResponse?.data?.data?.teamId}/${appointmentResponse?.data?.data?.id}`}
                className={getButtonClassNames({ variant: "white" })}
              >
                {t("Change date or time")}
              </Link>
            )}
          </div>
        </Card>
      </div>
    </Wrapper>
  );
};
