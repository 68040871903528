import { AppointmentsTable } from "./AppointmentsTable";
import { Suspense } from "react";
import { NegateCardPadding } from "components/NegateCardPadding";
import { TablePlaceholder } from "components/TablePlaceholder";

export const Container = () => {
  return (
    <NegateCardPadding>
      <Suspense fallback={<TablePlaceholder />}>
        <AppointmentsTable />
      </Suspense>
    </NegateCardPadding>
  );
};
