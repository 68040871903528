import { useTranslation } from "react-i18next";
import { z } from "zod";

export const useStringMax = (max: number) => {
  const { t } = useTranslation();

  return (input: z.ZodString) =>
    input.max(max, {
      message: t("You can't enter more than {{max}} characters", {
        max: max,
      }),
    });
};

export const useStringMin = (min: number) => {
  const { t } = useTranslation();

  return (input: z.ZodString) =>
    input.min(min, {
      message: t("Enter at least {{min}} characters", {
        min: min,
      }),
    });
};

export const useStringRequired = () => {
  const { t } = useTranslation();

  return (input: z.ZodString) =>
    input.min(1, {
      message: t("This field is required"),
    });
};

export const useValidEmail = () => {
  const { t } = useTranslation();

  return (input: z.ZodString) =>
    input.email({
      message: t("Must be a valid email"),
    });
};

const zodFile = z.instanceof(FileList);

export const useFileRequired = () => {
  const { t } = useTranslation();

  return (input: typeof zodFile) =>
    input.refine((data) => data?.length === 1, {
      message: t("Select a file"),
    });
};

export const useFileMimes = (mimes: string[]) => {
  const { t } = useTranslation();

  return (input: typeof zodFile) =>
    input.refine(
      (data) => {
        if (!data[0]) {
          return true;
        }

        const fileExtension =
          data[0].name.split(".").pop()?.toLowerCase() || "";

        return mimes.map((item) => item.toLowerCase()).includes(fileExtension);
      },
      {
        message: t("Invalid file type"),
      }
    );
};

export const useFileMaxSize = (max: number) => {
  const { t } = useTranslation();

  return (input: typeof zodFile) =>
    input.refine(
      (data) => {
        if (!data[0]) {
          return true;
        }

        return data[0].size <= max;
      },
      {
        message: t("File too large"),
      }
    );
};
