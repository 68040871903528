import classNames from "classnames";
import { ReactNode } from "react";
import { SectionHeading } from "./SectionHeading";

type Variant = "horizontal" | "vertical";

export interface FormSectionProps {
  title: string | ReactNode;
  description: string | ReactNode;
  children: ReactNode;
  footer?: ReactNode;
  withCard?: boolean;
  variant?: Variant;
}

export const FormSection = ({
  children,
  title,
  description,
  footer,
  withCard = true,
  variant = "horizontal",
}: FormSectionProps) => (
  <div>
    <div
      className={classNames("md:grid md:gap-6", {
        "md:grid-cols-3": variant === "horizontal",
      })}
    >
      <div className="md:col-span-1">
        <div
          className={classNames({
            "px-4 sm:px-0": withCard,
          })}
        >
          <SectionHeading>
            <span
              className={classNames({
                "text-base text-gray-700": variant === "vertical",
              })}
            >
              {title}
            </span>
          </SectionHeading>
          <div className="max-w-md mt-1 text-sm text-gray-600">
            {description}
          </div>
        </div>
      </div>
      <div className="mt-5 md:mt-0 md:col-span-2">
        <div
          className={classNames({
            "shadow sm:rounded-md sm:overflow-hidden": withCard,
          })}
        >
          <div
            className={classNames({
              "px-4 py-5 space-y-6 bg-white sm:p-6": withCard,
            })}
          >
            {children}
          </div>
          {footer && (
            <div
              className={classNames("space-x-4 text-right", {
                "bg-gray-50 px-4 sm:px-6 py-3": withCard,
                "pt-8": !withCard,
              })}
            >
              {footer}
            </div>
          )}
        </div>
      </div>
    </div>
  </div>
);
