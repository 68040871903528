import { Placeholder } from "components/Placeholder";
import { Suspense } from "react";
import { Calendar } from "./Calendar";

export const Container = () => {
  return (
    <Suspense
      fallback={
        <div className="px-4 py-5 sm:p-6">
          <div className="hidden md:block">
            <div className="flex items-center justify-between">
              <Placeholder widthClassName="w-64" heightClassName="h-12" />

              <div className="space-x-6">
                {[...Array(3)].map((item, index) => (
                  <Placeholder
                    key={index}
                    widthClassName="w-24"
                    heightClassName="h-10"
                  />
                ))}
              </div>
            </div>
            <div className="h-screen grid-cols-7 gap-4 mt-4 md:grid">
              {[...Array(7)].map((item, index) => (
                <Placeholder
                  key={index}
                  widthClassName="w-full"
                  heightClassName="h-full"
                />
              ))}
            </div>
          </div>
          <div className="block md:hidden">
            <div className="flex justify-between">
              {[...Array(3)].map((item, index) => (
                <Placeholder
                  key={index}
                  widthClassName="w-20"
                  heightClassName="h-10"
                />
              ))}
            </div>
            <div className="h-screen mt-4">
              <Placeholder widthClassName="w-full" heightClassName="h-full" />
            </div>
          </div>
        </div>
      }
    >
      <Calendar />
    </Suspense>
  );
};
