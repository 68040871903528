import { RouteSwitch } from "components/RouteSwitch";
import { Route, useRouteMatch } from "react-router-dom";
import { TransactionsList } from "../domain/list";

export const Container = () => {
  const match = useRouteMatch();

  return (
    <RouteSwitch>
      <Route exact path={match.path}>
        <TransactionsList />
      </Route>
    </RouteSwitch>
  );
};
