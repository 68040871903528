import { Context, ResponsePayload } from "types";
import { nameOf, typeProxy } from "utils/schema-keys";

export const updateLogo =
  ({ apiClient }: Context) =>
  ({ id, data }: { id: string; data: App.Resources.FileUploadResource }) => {
    const fileUploadKeys = typeProxy<App.Resources.FileUploadResource>();

    const formData = new FormData();

    formData.append(nameOf(fileUploadKeys.file), data.file);

    return apiClient<
      ResponsePayload<App.Domain.API.Account.Resources.UserResource>
    >({
      url: `/teams/${id}/logo`,
      method: "POST",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    });
  };

export const removeLogo =
  ({ apiClient }: Context) =>
  (id: string) =>
    apiClient<ResponsePayload<App.Domain.API.Account.Resources.UserResource>>({
      url: `/teams/${id}/logo`,
      method: "DELETE",
    });

export const getTeam =
  ({ apiClient }: Context) =>
  (id: string) =>
    apiClient<ResponsePayload<App.Domain.API.Teams.Resources.TeamResource>>({
      url: `/teams/${id}`,
      method: "GET",
    });

export const updateTeam =
  ({ apiClient }: Context) =>
  ({
    id,
    data,
  }: {
    id: string;
    data: App.Domain.API.Teams.Resources.SaveTeamResource;
  }) =>
    apiClient<ResponsePayload<App.Domain.API.Account.Resources.UserResource>>({
      url: `/teams/${id}`,
      method: "PUT",
      data,
    });

export const updateOnlineAppointments =
  ({ apiClient }: Context) =>
  ({
    id,
    data,
  }: {
    id: string;
    data: App.Domain.API.Teams.Resources.UpdateOnlineAppointmentsResource;
  }) =>
    apiClient<ResponsePayload<App.Domain.API.Teams.Resources.TeamResource>>({
      url: `/teams/${id}/online-appointments`,
      method: "PUT",
      data,
    });

export const deleteTeam =
  ({ apiClient }: Context) =>
  ({ id, data }: { id: string; data: App.Resources.PasswordResource }) =>
    apiClient<ResponsePayload<App.Domain.API.Account.Resources.UserResource>>({
      url: `/teams/${id}`,
      method: "DELETE",
      data,
    });

export const leaveTeam =
  ({ apiClient }: Context) =>
  ({ id, data }: { id: string; data: App.Resources.PasswordResource }) =>
    apiClient<ResponsePayload<App.Domain.API.Account.Resources.UserResource>>({
      url: `/teams/${id}/leave`,
      method: "DELETE",
      data,
    });

export const inviteMember =
  ({ apiClient }: Context) =>
  ({
    id,
    data,
  }: {
    id: string;
    data: App.Domain.API.Teams.Resources.InviteMemberResource;
  }) =>
    apiClient<ResponsePayload<App.Domain.API.Teams.Resources.TeamResource>>({
      url: `/teams/${id}/invite`,
      method: "POST",
      data,
    });

export const removeMember =
  ({ apiClient }: Context) =>
  ({ teamId, memberId }: { teamId: string; memberId: string }) =>
    apiClient<ResponsePayload<App.Domain.API.Teams.Resources.TeamResource>>({
      url: `/teams/${teamId}/members/${memberId}`,
      method: "DELETE",
    });

export const updateMember =
  ({ apiClient }: Context) =>
  ({
    teamId,
    memberId,
    data,
  }: {
    teamId: string;
    memberId: string;
    data: App.Domain.API.Teams.Resources.UpdateMemberResource;
  }) =>
    apiClient<ResponsePayload<App.Domain.API.Teams.Resources.TeamResource>>({
      url: `/teams/${teamId}/members/${memberId}`,
      method: "PUT",
      data,
    });

export const revokeInvitation =
  ({ apiClient }: Context) =>
  ({
    id,
    data,
  }: {
    id: string;
    data: App.Domain.API.Teams.Resources.RevokeInvitationResource;
  }) =>
    apiClient<ResponsePayload<App.Domain.API.Teams.Resources.TeamResource>>({
      url: `/teams/${id}/revoke-invitation`,
      method: "DELETE",
      data,
    });
