import { Transition } from "@headlessui/react";
import { LoadingSpinner } from "./LoadingSpinner";

export interface LoadingTableHeaderProps {
  loading?: boolean;
}

export const LoadingTableHeader = ({ loading }: LoadingTableHeaderProps) => (
  <div className="w-3 ml-auto">
    <Transition
      show={loading}
      enter="ease-out duration-300 transition"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <LoadingSpinner className="w-3 text-gray-500" />
    </Transition>
  </div>
);
