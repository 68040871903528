import { useMutation } from "react-query";
import { logout } from "services/user";
import { useContextService } from "./use-context-service";

export const useLogout = () => {
  const redirectToLogin = () =>
    window.location.replace(process.env.REACT_APP_LOGIN_URL);

  const logoutMutation = useMutation(useContextService(logout), {
    onSuccess: redirectToLogin,
  });

  return { logoutMutation: logoutMutation, redirectToLogin };
};
