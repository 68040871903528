import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { pipe } from "fp-ts/lib/function";
import { Button } from "components/Button";
import { FormSection, FormSectionProps } from "components/FormSection";
import { TextField } from "components/TextField";
import { useStringMax, useStringRequired } from "hooks/use-validation-input";
import { nullableString } from "utils/zod";
import { TextAreaField } from "components/TextAreaField";
import { ReactNode } from "react";
import { SelectField } from "components/SelectField";
import currency from "currency.js";
import { parseInterval } from "utils/time";

const useFormSchema = () =>
  z.object({
    name: pipe(z.string(), useStringMax(70), useStringRequired()),
    description: pipe(z.string(), useStringMax(200)).or(nullableString),
    price: pipe(z.string(), useStringRequired()),
    duration: pipe(z.string(), useStringRequired()),
    gapBefore: pipe(z.string(), useStringRequired()),
    gapAfter: pipe(z.string(), useStringRequired()),
  });

type FormSchema = z.infer<ReturnType<typeof useFormSchema>>;

const serviceDurations = [
  5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95,
  100, 105, 110, 115, 120,
].map(parseInterval);

const serviceGaps = [0, 5, 10, 15, 20, 25, 30].map(parseInterval);

export const ServiceForm = ({
  withCard,
  actions,
  variant,
  service,
  onSubmit,
}: {
  actions?: ReactNode;
  withCard?: FormSectionProps["withCard"];
  variant?: FormSectionProps["variant"];
  service?: Partial<App.Domain.API.Services.Resources.ServiceResource>;
  onSubmit: (
    data: Omit<FormSchema, "price" | "duration" | "gapBefore" | "gapAfter"> & {
      price: number;
      duration: number;
      gapBefore: number;
      gapAfter: number;
    }
  ) => Promise<any>;
}) => {
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormSchema>({
    resolver: zodResolver(useFormSchema()),
    defaultValues: {
      ...service,
      price:
        service?.price || service?.price === 0
          ? currency(service.price, { fromCents: true }).toString()
          : "",
      duration: service?.duration?.toString() || '30',
      gapBefore: service?.gapBefore?.toString(),
      gapAfter: service?.gapAfter?.toString(),
    },
  });

  return (
    <form
      onSubmit={handleSubmit((data) =>
        onSubmit({
          ...data,
          price: currency(data.price).intValue,
          duration: Number.parseInt(data.duration),
          gapBefore: Number.parseInt(data.gapBefore),
          gapAfter: Number.parseInt(data.gapAfter),
        })
      )}
    >
      <FormSection
        variant={variant}
        withCard={withCard}
        title={t("Service details")}
        description={t("Define the service details and price.")}
        footer={
          <>
            {actions}
            <Button
              type="submit"
              disabled={isSubmitting}
              loading={isSubmitting}
            >
              {service ? t("Update") : t("Create")}
            </Button>
          </>
        }
      >
        <div className="grid gap-4 lg:grid-cols-2">
          <TextField
            {...register("name")}
            label={t("Name")}
            error={errors.name}
            required
          />
          <TextField
            label={t("Price")}
            {...register("price")}
            error={errors.price}
            required
            type="number"
            step="any"
            min="0"
            max="1000"
          />
          <div className="lg:col-span-2">
            <SelectField
              {...register("duration")}
              label={t("Duration")}
              error={errors.duration}
              required
              options={serviceDurations.map((item) => ({
                label: item.formattedTime,
                value: item.interval,
              }))}
            />
          </div>
          <div className="lg:col-span-2">
            <SelectField
              {...register("gapBefore")}
              label={t("Preparation time")}
              error={errors.gapBefore}
              required
              options={serviceGaps.map((item) => ({
                label: item.formattedTime,
                value: item.interval,
              }))}
            />
          </div>
          <div className="lg:col-span-2">
            <SelectField
              {...register("gapAfter")}
              label={t("Rest time")}
              error={errors.gapAfter}
              required
              options={serviceGaps.map((item) => ({
                label: item.formattedTime,
                value: item.interval,
              }))}
            />
          </div>
          <div className="lg:col-span-2">
            <TextAreaField
              {...register("description")}
              label={t("Description")}
              error={errors.description}
              optional
              rows={6}
            />
          </div>
        </div>
      </FormSection>
    </form>
  );
};
