import classNames from "classnames";
import { PropsWithoutRef } from "react";

export type BadgeVariant =
  | "success"
  | "warning"
  | "warningSecondary"
  | "secondary"
  | "primary";

const variantClassNames: Record<BadgeVariant, string> = {
  success: "bg-green-100 text-green-800",
  warning: "bg-yellow-100 text-yellow-800",
  warningSecondary: "bg-orange-100 text-orange-800",
  secondary: "bg-gray-200 text-gray-800",
  primary: "bg-indigo-100 text-indigo-800",
};

export interface BadgeProps
  extends Omit<PropsWithoutRef<JSX.IntrinsicElements["span"]>, "className"> {
  variant?: BadgeVariant;
}

export const Badge = ({
  children,
  variant = "success",
  ...props
}: BadgeProps) => (
  <span
    {...props}
    className={classNames(
      "inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium",
      variantClassNames[variant]
    )}
  >
    {children}
  </span>
);
