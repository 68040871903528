import { useUser } from "hooks/use-user";
import { useTranslation } from "react-i18next";

import { Link } from "react-router-dom";
import { Ping } from "./Ping";

export const InvitationsLink = () => {
  const { data: userResponse } = useUser();

  const { t } = useTranslation();

  if (!userResponse?.data?.data?.hasPendingInvitations) {
    return null;
  }

  return (
    <div className="flex">
      <Link
        to="/teams/invitations"
        className="text-sm font-medium text-gray-900"
      >
        {t("Team Invitations")}
      </Link>
      <Ping />
    </div>
  );
};
