import { AppContext, contextState, reducer } from "context";
import { BrowserRouter as Router, Redirect, Route } from "react-router-dom";
import { RouteSwitch } from "components/RouteSwitch";
import { Notification } from "components/Notification";
import { Profile } from "domain/profile";
import { useReducer } from "react";
import { useQueryErrorResetBoundary } from "react-query";
import { ErrorBoundary as ReactErrorBoundary } from "react-error-boundary";
import { ErrorBoundary } from "components/ErrorBoundary";
import { Teams } from "domain/teams";
import { Clients } from "domain/clients";
import { Transactions } from "domain/transactions";
import { Calendar } from "domain/calendar";
import { OnlineAppointments } from "domain/online-appointments";
import { LayoutRoute } from "./LayoutRoute";
import { Services } from "domain/services";
import { Schedule } from "domain/schedule";

export const Container = () => {
  const [state, dispatch] = useReducer(reducer, contextState);

  const { reset } = useQueryErrorResetBoundary();

  return (
    <Router>
      <ReactErrorBoundary
        onReset={reset}
        fallbackRender={(props) => <ErrorBoundary {...props} />}
      >
        <AppContext.Provider
          value={{
            ...state,
            dispatch,
          }}
        >
          <RouteSwitch>
            <Route path="/online-appointment" component={OnlineAppointments} />
            <LayoutRoute path="/profile">
              <Profile />
            </LayoutRoute>
            <LayoutRoute path="/teams">
              <Teams />
            </LayoutRoute>
            <LayoutRoute path="/clients">
              <Clients />
            </LayoutRoute>
            <LayoutRoute path="/calendar">
              <Calendar />
            </LayoutRoute>
            <LayoutRoute path="/transactions">
              <Transactions />
            </LayoutRoute>
            <LayoutRoute path="/services">
              <Services />
            </LayoutRoute>
            <LayoutRoute path="/schedule">
              <Schedule />
            </LayoutRoute>
            <LayoutRoute exact path="/">
              <Redirect to="/calendar" />
            </LayoutRoute>
          </RouteSwitch>
          <Notification />
        </AppContext.Provider>
      </ReactErrorBoundary>
    </Router>
  );
};
