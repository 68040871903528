import { PropsWithoutRef, ReactNode } from "react";
import { useTranslation } from "react-i18next";

export const DescriptionListItem = ({
  title,
  description,
}: {
  title: ReactNode;
  description: ReactNode;
}) => {
  const { t } = useTranslation();

  return (
    <div className="px-4 py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
      <dt className="text-sm font-medium text-gray-500">{title}</dt>
      <dd className="mt-1 text-sm text-gray-900 break-words sm:mt-0 sm:col-span-2">
        {description || <span className="text-gray-500">{t("N/A")}</span>}
      </dd>
    </div>
  );
};

export const DescriptionList = ({
  children,
  ...props
}: Omit<PropsWithoutRef<JSX.IntrinsicElements["div"]>, "className">) => (
  <div {...props} className="border-t border-gray-200">
    <dl className="sm:divide-y sm:divide-gray-200">{children}</dl>
  </div>
);
