import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { Button } from "components/Button";
import { FormSection } from "components/FormSection";
import { useContextService } from "hooks/use-context-service";
import { showNotification as showNotificationService } from "services/notification";
import { useMutation } from "react-query";
import { queryClient } from "query";
import { FileField } from "components/FileField";
import { Avatar } from "components/Avatar";
import { useMemo } from "react";
import { pipe } from "fp-ts/lib/function";
import {
  useFileMaxSize,
  useFileMimes,
  useFileRequired,
} from "hooks/use-validation-input";
import { updateLogo, removeLogo } from "../services/team";
import { ServiceReturnType } from "types";
import { useTeam, TEAM_QUERY } from "../hooks/use-team";
import { useUser } from "hooks/use-user";

const allowedMimes = ["JPG", "PNG"];

const maxFileSize = 1 * 1024 * 1024;

const useFormSchema = () =>
  z.object({
    logo: pipe(
      z.instanceof(FileList),
      useFileRequired(),
      useFileMimes(allowedMimes),
      useFileMaxSize(maxFileSize)
    ),
  });

type FormSchema = z.infer<ReturnType<typeof useFormSchema>>;

export const LogoForm = () => {
  const { t } = useTranslation();

  const { data: userResponse } = useUser();

  const { data: teamResponse } = useTeam(
    userResponse?.data?.data?.currentTeam.id
  );

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<FormSchema>({
    resolver: zodResolver(useFormSchema()),
  });

  const showNotification = useContextService(showNotificationService);

  const onMutationSuccess =
    (message: string) =>
    (response: ServiceReturnType<typeof updateLogo | typeof removeLogo>) => {
      queryClient.setQueryData(
        [TEAM_QUERY, userResponse?.data?.data?.currentTeam.id],
        response
      );

      showNotification({
        title: t("Successfully updated"),
        message,
      });

      reset();
    };

  const updateLogoMutation = useMutation(useContextService(updateLogo), {
    onSuccess: onMutationSuccess("Your team logo was updated."),
  });

  const removeLogoMutation = useMutation(useContextService(removeLogo), {
    onSuccess: onMutationSuccess("Your team logo was removed."),
  });

  const selectedFiles = watch("logo");

  const previewImage = useMemo(
    () =>
      selectedFiles?.length ? URL.createObjectURL(selectedFiles[0]) : null,
    [selectedFiles]
  );

  return (
    <form
      onSubmit={handleSubmit((data) =>
        updateLogoMutation.mutateAsync({
          id: userResponse?.data?.data?.currentTeam.id!,
          data: { file: data.logo[0] },
        })
      )}
    >
      <FormSection
        title={t("Team logo")}
        description={t("Here you can update your team logo.")}
        footer={
          <>
            {teamResponse?.data?.data?.logo && (
              <Button
                variant="white"
                disabled={isSubmitting || removeLogoMutation.isLoading}
                loading={isSubmitting || removeLogoMutation.isLoading}
                onClick={() =>
                  removeLogoMutation.mutate(
                    userResponse?.data?.data?.currentTeam.id!
                  )
                }
              >
                {t("Remove Logo")}
              </Button>
            )}
            <Button type="submit" disabled={isSubmitting}>
              {t("Save")}
            </Button>
          </>
        }
      >
        <FileField
          {...register("logo")}
          label={t("Logo")}
          error={errors.logo}
          mimes={allowedMimes}
          maxFileSize={maxFileSize}
          preview={
            <Avatar
              size="xl"
              name={previewImage ? t("Logo") : teamResponse?.data?.data?.name}
              src={previewImage || teamResponse?.data?.data?.logo}
            />
          }
          onDrop={(files) => {
            setValue("logo", files);
          }}
        />
      </FormSection>
    </form>
  );
};
