import { useTranslation } from "react-i18next";
import { FormSection } from "components/FormSection";
import { useContextService } from "hooks/use-context-service";
import { showNotification as showNotificationService } from "services/notification";
import { useMutation, useQuery } from "react-query";
import { queryClient } from "query";
import {
  getInvitations,
  acceptInvitation,
  declineInvitation,
} from "../services/invitation";
import { TextButton } from "components/TextButton";
import { Button } from "components/Button";
import { InvitationItem } from "domain/teams/components/InvitationItem";
import { USER_QUERY } from "hooks/use-user";
import { ServiceReturnType } from "types";

const INVITATIONS_QUERY = "invitations";

const Invitation = ({
  invitation,
}: {
  invitation: App.Domain.API.Invitations.Resources.InvitationResource;
}) => {
  const { t } = useTranslation();

  const showNotification = useContextService(showNotificationService);

  const acceptInvitationMutation = useMutation(useContextService(acceptInvitation), {
    onSuccess: (response) => {
      queryClient.setQueryData(USER_QUERY, response);

      queryClient.setQueryData(INVITATIONS_QUERY, (old): ServiceReturnType<typeof getInvitations> => {
        const oldCache = old as ServiceReturnType<typeof getInvitations>;

        return {
          ...oldCache,
          data: {
            data: oldCache!.data!.data.filter(
              (item) => item.id !== invitation.id
            ),
          },
        };
      });

      showNotification({
        title: t("Successfully accepted"),
        message: t("You are part of the team now."),
      });
    },
  });

  const declineInvitationMutation = useMutation(useContextService(declineInvitation), {
    onSuccess: (response) => {
      queryClient.setQueryData(INVITATIONS_QUERY, response);

      showNotification({
        title: t("Successfully declined"),
        message: t("You refused to join the team."),
      });
    },
  });

  return (
    <InvitationItem
      actions={
        <>
          <Button
            size="small"
            variant="white"
            disabled={acceptInvitationMutation.isLoading}
            loading={acceptInvitationMutation.isLoading}
            onClick={() => acceptInvitationMutation.mutate(invitation.id)}
          >
            {t("Accept invitation")}
          </Button>
          <TextButton
            variant="danger"
            disabled={declineInvitationMutation.isLoading}
            loading={declineInvitationMutation.isLoading}
            onClick={() => declineInvitationMutation.mutate(invitation.id)}
          >
            {t("Decline")}
          </TextButton>
        </>
      }
    >
      {invitation.teamName}
    </InvitationItem>
  );
};

export const ManageInvitations = () => {
  const { t } = useTranslation();

  const { data: invitationsResponse } = useQuery(
    INVITATIONS_QUERY,
    useContextService(getInvitations)
  );

  return (
    <FormSection
      title={t("My invitations")}
      description={t("Here you can accept or decline your team invitations.")}
    >
      {invitationsResponse?.data?.data &&
      invitationsResponse?.data?.data.length > 0 ? (
        <div className="-my-4 divide-y">
          {invitationsResponse?.data?.data?.map((item, index) => (
            <Invitation key={index} invitation={item} />
          ))}
        </div>
      ) : (
        <p className="text-sm text-gray-700">
          {t("There are no pending invitations.")}
        </p>
      )}
    </FormSection>
  );
};
