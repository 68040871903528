import { Button } from "components/Button";
import { RadioGroupField } from "components/RadioGroupField";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import currency from "currency.js";
import { useTeam } from "../../../hooks/use-team";
import { ActionsWrapper } from "../../../components/ActionsWrapper";
import { Header } from "../../../components/Header";

export const ServiceForm = ({
  nextStep,
  previousStep,
}: {
  nextStep: () => void;
  previousStep: () => void;
}) => {
  const { control, watch } = useFormContext();

  const { t } = useTranslation();

  const serviceId = watch("serviceId");

  const { data: teamResponse } = useTeam();

  return (
    <>
      <Header
        title={t("Select Service")}
        description={t("You will be provided with the selected service.")}
      />
      <div className="mt-4">
        <Controller
          name="serviceId"
          control={control}
          render={({ field }) => (
            <RadioGroupField
              label={t("Service")}
              options={
                teamResponse?.data?.data?.services.map((item) => ({
                  name: item.name,
                  description: `${currency(item.price, {
                    fromCents: true,
                    symbol: "€",
                  }).format()} ${
                    item.description ? `- ${item.description}` : ""
                  }`,
                  value: item.id,
                })) || []
              }
              {...field}
            />
          )}
        />
      </div>
      <ActionsWrapper>
        <Button type="button" variant="white" onClick={previousStep}>
          {t("Back")}
        </Button>
        <Button type="button" disabled={!serviceId} onClick={nextStep}>
          {t("Next step")}
        </Button>
      </ActionsWrapper>
    </>
  );
};
