import { Context, ResponsePayload } from "types";

export const getAppointments =
  ({ apiClient }: Context) =>
  (data?: App.Domain.API.Appointments.Resources.RetrieveAppointmentsResource) =>
    apiClient<App.Domain.API.Appointments.Resources.AppointmentsResource>({
      url: "/appointments",
      method: "GET",
      params: data,
    });

export const getAppointment =
  ({ apiClient }: Context) =>
  (id: string) =>
    apiClient<App.Domain.API.Appointments.Resources.AppointmentsResource>({
      url: `/appointments/${id}`,
      method: "GET",
    });

export const createAppointment =
  ({ apiClient }: Context) =>
  (data: App.Domain.API.Appointments.Resources.CreateAppointmentResource) =>
    apiClient<
      ResponsePayload<App.Domain.API.Appointments.Resources.AppointmentResource | null>
    >({
      url: "/appointments",
      method: "POST",
      data,
    });

export const validateCollisions =
  ({ apiClient }: Context) =>
  (data: App.Domain.API.Appointments.Resources.CreateAppointmentResource) =>
    apiClient({
      url: "/appointments/validate-collisions",
      method: "POST",
      data,
    });

export const updateAppointment =
  ({ apiClient }: Context) =>
  ({
    id,
    data,
  }: {
    id: string;
    data: App.Domain.API.Appointments.Resources.SaveAppointmentResource;
  }) =>
    apiClient<
      ResponsePayload<App.Domain.API.Appointments.Resources.AppointmentResource>
    >({
      url: `/appointments/${id}`,
      method: "PUT",
      params: data,
    });

export const confirmAppointment =
  ({ apiClient }: Context) =>
  (id: string) =>
    apiClient({
      url: `/appointments/${id}/confirm`,
      method: "PUT",
    });

export const completeAppointment =
  ({ apiClient }: Context) =>
  (id: string) =>
    apiClient({
      url: `/appointments/${id}/complete`,
      method: "PUT",
    });

export const deleteAppointment =
  ({ apiClient }: Context) =>
  (id: string) =>
    apiClient({
      url: `/appointments/${id}`,
      method: "DELETE",
    });

export const deleteAllAppointments =
  ({ apiClient }: Context) =>
  ({ id, batchId }: { id: string; batchId: string }) =>
    apiClient({
      url: `/appointments/${id}/all`,
      method: "DELETE",
    });
