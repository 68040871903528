import { PropsWithoutRef } from "react";

export interface NegateCardPaddingProps
  extends Omit<PropsWithoutRef<JSX.IntrinsicElements["div"]>, "className"> {}

export const NegateCardPadding = ({
  children,
  ...props
}: NegateCardPaddingProps) => (
  <div {...props} className="-mx-4 -my-5 sm:-m-6">
    {children}
  </div>
);

export const NegateHorizontalCardPadding = ({
  children,
  ...props
}: NegateCardPaddingProps) => (
  <div {...props} className="-mx-4 sm:-mx-6">
    {children}
  </div>
);
