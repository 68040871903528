import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Button } from "components/Button";
import { FormSection } from "components/FormSection";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { TextField } from "components/TextField";
import { pipe } from "fp-ts/lib/function";
import {
  useStringMax,
  useStringMin,
  useStringRequired,
} from "hooks/use-validation-input";
import { useContextService } from "hooks/use-context-service";
import { showNotification as showNotificationService } from "services/notification";
import { useMutation } from "react-query";
import { updatePassword } from "../services/profile";
import { Unauthorized } from "errors/Unauthorized";

const useFormSchema = () => {
  const { t } = useTranslation();

  return z
    .object({
      oldPassword: pipe(z.string(), useStringRequired(), useStringMax(250)),
      password: pipe(
        z.string(),
        useStringRequired(),
        useStringMin(6),
        useStringMax(250)
      ),
      passwordConfirmation: pipe(
        z.string(),
        useStringRequired(),
        useStringMin(6),
        useStringMax(250)
      ),
    })
    .refine((data) => data.password === data.passwordConfirmation, {
      message: t("Passwords don't match"),
      path: ["passwordConfirmation"],
    });
};

type FormSchema = z.infer<ReturnType<typeof useFormSchema>>;

export const PasswordForm = () => {
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    setError,
    reset,
    setFocus,
    formState: { errors, isSubmitting },
  } = useForm<FormSchema>({
    resolver: zodResolver(useFormSchema()),
  });

  const showNotification = useContextService(showNotificationService);

  const updateProfileMutation = useMutation(useContextService(updatePassword), {
    onSuccess: () => {
      showNotification({
        title: t("Successfully updated"),
        message: t("Your password was updated."),
      });

      reset();
    },
    onError: (error: any) => {
      if (error instanceof Unauthorized) {
        reset();

        setError("oldPassword", {
          message: t("Incorrect password"),
        });

        setFocus("oldPassword");

        return new Promise(() => {});
      }
    },
  });

  return (
    <form
      onSubmit={handleSubmit((data) => updateProfileMutation.mutateAsync(data))}
    >
      <FormSection
        title={t("Password settings")}
        description={t("Set a new password for your account.")}
        footer={
          <Button type="submit" disabled={isSubmitting} loading={isSubmitting}>
            {t("Submit")}
          </Button>
        }
      >
        <div className="grid gap-4 lg:grid-cols-3">
          <TextField
            {...register("oldPassword")}
            label={t("Old Password")}
            error={errors.oldPassword}
            required
            type="password"
          />
          <TextField
            {...register("password")}
            label={t("New password")}
            error={errors.password}
            required
            type="password"
          />
          <TextField
            {...register("passwordConfirmation")}
            label={t("Repeat new password")}
            error={errors.passwordConfirmation}
            required
            type="password"
          />
        </div>
      </FormSection>
    </form>
  );
};
