import { Controller, useFormContext } from "react-hook-form";
import { useQuery } from "react-query";
import { useContextService } from "hooks/use-context-service";
import { getSlots } from "../services/online-appointment";
import { useTranslation } from "react-i18next";
import { useTeam } from "../hooks/use-team";
import { WizardSchema } from "../hooks/use-wizard-schema";
import { format } from "date-fns";
import { RadioGroupField } from "components/RadioGroupField";
import { FieldLabel } from "components/FieldLabel";

export const SlotsForm = () => {
  const { watch, control, setValue } = useFormContext<WizardSchema>();

  const { date, member, serviceId } = watch();

  const { data: teamResponse } = useTeam();

  const getSlotsService = useContextService(getSlots);

  const { data: slotsResponse } = useQuery(
    ["slots", teamResponse?.data?.data?.id, date, member.id, serviceId],
    () => {
      return getSlotsService({
        id: teamResponse?.data?.data?.id!,
        data: {
          date: date.toISOString(),
          memberId: member.id,
          serviceId: serviceId,
        },
      });
    },
    {
      onSuccess: (response) => {
        if (response.data?.data.length === 0) {
          setValue("slot", "");
        }
      },
      enabled: Boolean(date),
    }
  );

  const { t } = useTranslation();

  if (!slotsResponse) {
    return null;
  }

  if (slotsResponse?.data?.data.length === 0) {
    return (
      <p className="mt-6 text-sm text-gray-600">
        {t("There are no available slots for this day.")}
      </p>
    );
  }

  return (
    <div className="w-full">
      <FieldLabel htmlFor="slot" required>
        {t("Select Time")}
      </FieldLabel>
      <Controller
        name="slot"
        control={control}
        render={({ field }) => (
          <RadioGroupField
            label={t("Select Time")}
            options={
              slotsResponse?.data?.data
                .map((item) => {
                  const itemDate = new Date(item);

                  return {
                    name: format(itemDate, "MMM d - HH:mm"),
                    value: itemDate.toISOString(),
                  };
                }) || []
            }
            {...field}
          />
        )}
      />
    </div>
  );
};
