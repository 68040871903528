import { Card } from "components/Card";
import { NegateCardPadding } from "components/NegateCardPadding";
import { Table } from "components/Table";
import { TableCell } from "components/TableCell";
import { useContextService } from "hooks/use-context-service";
import { useUser } from "hooks/use-user";
import { showNotification as showNotificationService } from "services/notification";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import currency from "currency.js";
import { CellProps } from "react-table";
import { deleteService } from "../services/services";
import { LoadingTableHeader } from "components/LoadingTableHeader";
import { TextButton } from "components/TextButton";
import { PromptModalContent } from "components/PromptModalContent";
import { useModal } from "hooks/use-modal";
import { Modal } from "components/Modal";
import { Button } from "components/Button";
import { queryClient } from "query";
import { ServiceReturnType } from "types";
import { SERVICES_QUERY, useServices } from "hooks/use-services";
import { getServices } from "services/services";
import { parseInterval } from "utils/time";
import { ModalHeader } from "components/ModalHeader";
import { EditServiceForm } from "./EditServiceForm";

export const ServicesTable = () => {
  const { data: userResponse } = useUser();

  const showNotification = useContextService(showNotificationService);

  const { data: servicesResponse, isFetching } = useServices();

  const deleteServiceMutation = useMutation(useContextService(deleteService), {
    onMutate: async (id) => {
      queryClient.setQueryData(
        [SERVICES_QUERY, userResponse?.data?.data?.currentTeam.id!],
        (data): ServiceReturnType<typeof getServices> => {
          const oldData = data as ServiceReturnType<typeof getServices>;

          return {
            ...oldData,
            data: {
              ...oldData.data,
              data: oldData.data!.data.filter((item) => item.id !== id),
            },
          };
        }
      );

      closeDeleteModal();

      showNotification({
        title: t("Successfully deleted"),
        message: t("You deleted the service."),
      });
    },
  });

  const { t } = useTranslation();

  const onModalsClose = () => {
    setTimeout(() => {
      setSelectedService(undefined);
    }, 250);
  };

  const {
    modalVisible: deleteModalVisible,
    openModal: openDeleteModal,
    closeModal: closeDeleteModal,
  } = useModal({ onClose: onModalsClose });

  const {
    modalVisible: editModalVisible,
    openModal: openEditModal,
    closeModal: closeEditModal,
  } = useModal({ onClose: onModalsClose });

  const [selectedService, setSelectedService] =
    useState<App.Domain.API.Services.Resources.ServiceResource>();

  const openServiceDelete = useCallback(
    (service: App.Domain.API.Services.Resources.ServiceResource) => {
      setSelectedService(service);

      openDeleteModal();
    },
    [openDeleteModal]
  );

  const openServiceEdit = useCallback(
    (service: App.Domain.API.Services.Resources.ServiceResource) => {
      setSelectedService(service);

      openEditModal();
    },
    [openEditModal]
  );

  const columns = useMemo(
    () => [
      {
        Header: t("ID"),
        Cell: ({
          row,
        }: CellProps<App.Domain.API.Services.Resources.ServiceResource>) => (
          <TableCell widthClassName="w-32" variant="secondary">
            {row.original.id}
          </TableCell>
        ),
      },
      {
        Header: t("Name"),
        Cell: ({
          row,
        }: CellProps<App.Domain.API.Services.Resources.ServiceResource>) => (
          <TableCell widthClassName="w-64">{row.original.name}</TableCell>
        ),
      },
      {
        Header: t("Description"),
        Cell: ({
          row,
        }: CellProps<App.Domain.API.Services.Resources.ServiceResource>) => (
          <TableCell widthClassName="w-64" variant="secondary">
            {row.original.description && (
              <span className="inline-block w-full truncate">
                {row.original.description}
              </span>
            )}
          </TableCell>
        ),
      },
      {
        Header: t("Price"),
        Cell: ({
          row,
        }: CellProps<App.Domain.API.Services.Resources.ServiceResource>) => (
          <TableCell widthClassName="w-48">
            {currency(row.original.price, {
              fromCents: true,
              symbol: "€",
            }).format()}
          </TableCell>
        ),
      },
      {
        Header: t("Duration"),
        Cell: ({
          row,
        }: CellProps<App.Domain.API.Services.Resources.ServiceResource>) => (
          <TableCell widthClassName="w-48" variant="secondary">
            {parseInterval(row.original.duration).formattedTime}
          </TableCell>
        ),
      },
      {
        id: "actions",
        Header: <LoadingTableHeader loading={isFetching} />,
        Cell: ({
          row,
        }: CellProps<App.Domain.API.Services.Resources.ServiceResource>) => (
          <TableCell widthClassName="w-40" variant="actions">
            <TextButton onClick={() => openServiceEdit(row.original)}>
              {t("Edit")}
            </TextButton>
            <TextButton
              variant="danger"
              onClick={() => openServiceDelete(row.original)}
            >
              {t("Delete")}
            </TextButton>
          </TableCell>
        ),
      },
    ],
    [t, isFetching, openServiceDelete, openServiceEdit]
  );

  return (
    <>
      <Modal visible={deleteModalVisible} onClose={closeDeleteModal}>
        <PromptModalContent
          title={t("Are you sure?")}
          description={t('You are about to delete "{{name}}".', {
            name: selectedService?.name,
          })}
          actions={
            <>
              <Button
                variant="white"
                onClick={closeDeleteModal}
                disabled={deleteServiceMutation.isLoading}
                loading={deleteServiceMutation.isLoading}
              >
                {t("Cancel")}
              </Button>
              <Button
                variant="danger"
                onClick={() =>
                  deleteServiceMutation.mutateAsync(selectedService?.id!)
                }
                disabled={deleteServiceMutation.isLoading}
                loading={deleteServiceMutation.isLoading}
                withSpinner
              >
                {t("Delete")}
              </Button>
            </>
          }
        />
      </Modal>
      <Modal visible={editModalVisible} onClose={closeEditModal}>
        <ModalHeader onClose={closeEditModal}>{t("Edit Service")}</ModalHeader>
        {selectedService && (
          <EditServiceForm
            service={selectedService}
            onSubmit={closeEditModal}
            onCancel={closeEditModal}
          />
        )}
      </Modal>
      <Card>
        <NegateCardPadding>
          <Table columns={columns} data={servicesResponse?.data?.data!} />
        </NegateCardPadding>
      </Card>
    </>
  );
};
