import { Card } from "components/Card";
import { LoadingTableHeader } from "components/LoadingTableHeader";
import { NegateCardPadding } from "components/NegateCardPadding";
import { Pagination } from "components/Pagination";
import { Table } from "components/Table";
import { TableCell } from "components/TableCell";
import { getTextButtonClassNames } from "components/TextButton";
import { usePagination } from "hooks/use-pagination";
import { useContextService } from "hooks/use-context-service";
import { useUser } from "hooks/use-user";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { Link, useHistory, useLocation } from "react-router-dom";
import { CellProps } from "react-table";
import { getClients } from "../services/clients";
import { HeadingWrapper } from "components/HeadingWrapper";
import { Heading } from "components/Heading";
import { getButtonClassNames } from "components/Button";
import { SelectField } from "components/SelectField";

export const CLIENTS_QUERY = "clients";

export const ClientsTable = () => {
  const { page, nextPage, previousPage } = usePagination();

  const getClientsService = useContextService(getClients);

  const { data: userResponse } = useUser();

  const location = useLocation();

  const history = useHistory();

  const queryParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  const filter = useMemo(() => {
    const filterQuery = queryParams.get("filter");

    const validFilters: App.Domain.API.Clients.Enums.ClientFilter[] = [
      "PENDING_PAYMENT_APPOINTMENTS",
      "PENDING_PROVIDER_CONFIRMATION_APPOINTMENTS",
      "PENDING_UNPAID_CLIENT_CONFIRMATION_APPOINTMENTS",
      "PENDING_PAID_CLIENT_CONFIRMATION_APPOINTMENTS",
      "CONFIRMED_APPOINTMENTS",
      "COMPLETED_APPOINTMENTS",
    ];

    return filterQuery &&
      validFilters.includes(
        filterQuery as App.Domain.API.Clients.Enums.ClientFilter
      )
      ? (filterQuery as App.Domain.API.Clients.Enums.ClientFilter)
      : "";
  }, [queryParams]);

  const {
    data: clientsResponse,
    isFetching,
    isPreviousData,
  } = useQuery(
    [CLIENTS_QUERY, userResponse?.data?.data?.currentTeam.id!, page, filter],
    () => getClientsService({ page, searchTerm: null, filter: filter || null }),
    {
      keepPreviousData: true,
    }
  );

  const { t } = useTranslation();

  const columns = useMemo(
    () => [
      {
        Header: t("ID"),
        Cell: ({
          row,
        }: CellProps<App.Domain.API.Clients.Resources.ClientResource>) => (
          <TableCell widthClassName="w-32" variant="secondary">
            {row.original.id}
          </TableCell>
        ),
      },
      {
        Header: t("First Name"),
        Cell: ({
          row,
        }: CellProps<App.Domain.API.Clients.Resources.ClientResource>) => (
          <TableCell widthClassName="w-48">{row.original.firstName}</TableCell>
        ),
      },
      {
        width: 5,
        Header: t("Last Name"),
        Cell: ({
          row,
        }: CellProps<App.Domain.API.Clients.Resources.ClientResource>) => (
          <TableCell widthClassName="w-48">{row.original.lastName}</TableCell>
        ),
      },
      {
        Header: t("Email"),
        Cell: ({
          row,
        }: CellProps<App.Domain.API.Clients.Resources.ClientResource>) => (
          <TableCell widthClassName="w-64" variant="secondary">
            {row.original.email}
          </TableCell>
        ),
      },
      {
        Header: t("Phone"),
        Cell: ({
          row,
        }: CellProps<App.Domain.API.Clients.Resources.ClientResource>) => (
          <TableCell widthClassName="w-64" variant="secondary">
            {row.original.phone}
          </TableCell>
        ),
      },
      {
        id: "actions",
        Header: <LoadingTableHeader loading={isFetching} />,
        Cell: ({
          row,
        }: CellProps<App.Domain.API.Clients.Resources.ClientResource>) => (
          <TableCell widthClassName="w-40" variant="actions">
            <Link
              to={`/clients/${row.original.id}`}
              className={getTextButtonClassNames()}
            >
              {t("Details")}
            </Link>
          </TableCell>
        ),
      },
    ],
    [t, isFetching]
  );

  const filterOptions: {
    label: string;
    value: App.Domain.API.Clients.Enums.ClientFilter | "";
  }[] = [
    {
      label: t("All users").toString(),
      value: "",
    },
    {
      label: t("With pending payment appointments").toString(),
      value: "PENDING_PAYMENT_APPOINTMENTS",
    },
    {
      label: t("With pending provider confirmation appointments").toString(),
      value: "PENDING_PROVIDER_CONFIRMATION_APPOINTMENTS",
    },
    {
      label: t(
        "With pending client confirmation appointments (Unpaid)"
      ).toString(),
      value: "PENDING_UNPAID_CLIENT_CONFIRMATION_APPOINTMENTS",
    },
    {
      label: t(
        "With pending client confirmation appointments (Paid)"
      ).toString(),
      value: "PENDING_PAID_CLIENT_CONFIRMATION_APPOINTMENTS",
    },
    {
      label: t("With confirmed appointments").toString(),
      value: "CONFIRMED_APPOINTMENTS",
    },
    {
      label: t("With completed appointments").toString(),
      value: "COMPLETED_APPOINTMENTS",
    },
  ];

  return (
    <>
      <HeadingWrapper>
        <Heading>{t("Clients")}</Heading>

        <div className="flex flex-col-reverse mt-4 space-x-0 space-y-4 space-y-reverse sm:space-y-0 sm:flex-row sm:space-x-4 sm:mt-0">
          <div className="flex flex-col sm:w-96">
            <SelectField
              name="filter"
              value={filter}
              onChange={(e) => {
                queryParams.set("page", "1");

                queryParams.set("filter", e.target.value);

                history.push({
                  search: "?" + queryParams.toString(),
                });
              }}
              options={filterOptions}
            />
          </div>
          <div className="flex-1 w-px border-r border-gray-300" />
          <Link to="/clients/create" className={getButtonClassNames()}>
            {t("Create")}
          </Link>
        </div>
      </HeadingWrapper>
      <Card>
        <NegateCardPadding>
          <Table columns={columns} data={clientsResponse?.data?.data!} />
          <Pagination
            page={page}
            isPreviousData={isPreviousData}
            hasMorePages={clientsResponse?.data?.meta.hasMorePages}
            loading={isFetching}
            onNext={nextPage}
            onPrevious={previousPage}
          />
        </NegateCardPadding>
      </Card>
    </>
  );
};
