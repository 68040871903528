import { z } from "zod";
import { pipe } from "fp-ts/lib/function";
import {
  useStringMax,
  useStringRequired,
  useValidEmail,
} from "hooks/use-validation-input";
import { nullableString } from "utils/zod";

export const useWizardSchema = () =>
  z.object({
    member: z.object({
      id: pipe(z.string(), useStringRequired()),
      workingDays: z.array(z.number()),
    }),
    serviceId: pipe(z.string(), useStringRequired()),
    date: z.date(),
    firstName: pipe(z.string(), useStringMax(50), useStringRequired()),
    lastName: pipe(z.string(), useStringMax(50), useStringRequired()),
    email: pipe(
      z.string(),
      useStringMax(100),
      useValidEmail(),
      useStringRequired()
    ),
    phone: pipe(z.string(), useStringMax(100)).or(nullableString),
    slot: pipe(z.string(), useStringRequired()),
  });

export type WizardSchema = z.infer<ReturnType<typeof useWizardSchema>>;
