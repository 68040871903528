import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useLocation } from "react-router-dom";
import { SelectField } from "./SelectField";

interface TabProps {
  name: string;
  href: string;
}

const Tab = ({ href, name }: TabProps) => {
  const location = useLocation();

  const isCurrent = location.pathname === href;

  return (
    <Link
      to={href}
      className={classNames(
        isCurrent
          ? "border-indigo-500 text-indigo-600"
          : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
        "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
      )}
      aria-current={isCurrent ? "page" : undefined}
    >
      {name}
    </Link>
  );
};

export interface TabsProps {
  tabs: TabProps[];
}

export const Tabs = ({ tabs }: TabsProps) => {
  const { t } = useTranslation();

  const history = useHistory();

  const location = useLocation();

  return (
    <div>
      <div className="px-4 py-6 border-b sm:px-6 sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          {t("Select a tab")}
        </label>
        <SelectField
          name="tabs"
          options={tabs.map((item) => ({ label: item.name, value: item.href }))}
          onChange={(e) => history.push(e.target.value)}
          value={location.pathname}
        />
      </div>
      <div className="hidden sm:block">
        <div className="px-4 border-b border-gray-200 sm:px-6">
          <nav className="flex -mb-px space-x-8" aria-label={t("Tabs")}>
            {tabs.map((tab, index) => (
              <Tab key={index} {...tab} />
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
};
