import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { pipe } from "fp-ts/lib/function";
import { Button } from "components/Button";
import { FormSection } from "components/FormSection";
import { TextField } from "components/TextField";
import {
  useStringMax,
  useStringRequired,
  useValidEmail,
} from "hooks/use-validation-input";
import { useContextService } from "hooks/use-context-service";
import { showNotification as showNotificationService } from "services/notification";
import { useUser } from "hooks/use-user";
import { useMutation } from "react-query";
import { inviteMember } from "../services/team";
import { queryClient } from "query";
import { ValidationFailed } from "errors/ValidationFailed";
import { TEAM_QUERY, useTeam } from "../hooks/use-team";
import { FieldLabel } from "components/FieldLabel";
import { RadioGroupField } from "components/RadioGroupField";
import { FieldError } from "components/FieldError";
import { roleEnum, useRoles } from "../hooks/use-roles";

const useFormSchema = () =>
  z.object({
    email: pipe(
      z.string(),
      useValidEmail(),
      useStringMax(50),
      useStringRequired()
    ),
    role: roleEnum,
  });

type FormSchema = z.infer<ReturnType<typeof useFormSchema>>;

export const InviteMemberForm = () => {
  const { t } = useTranslation();

  const { data: userResponse } = useUser();

  const { data: teamResponse } = useTeam(
    userResponse?.data?.data?.currentTeam.id
  );

  const {
    register,
    handleSubmit,
    reset,
    setError,
    setFocus,
    control,
    formState: { errors, isSubmitting },
  } = useForm<FormSchema>({
    resolver: zodResolver(useFormSchema()),
  });

  const showNotification = useContextService(showNotificationService);

  const inviteMemberMutation = useMutation(useContextService(inviteMember), {
    onSuccess: (response) => {
      queryClient.setQueryData(
        [TEAM_QUERY, teamResponse?.data?.data?.id],
        response
      );

      showNotification({
        title: t("Successfully invited"),
        message: t("You invited an user."),
      });

      reset();
    },
    onError: (error) => {
      if (error instanceof ValidationFailed) {
        reset();

        setError("email", {
          message: error.getMessages()?.email[0] || t("Something went wrong"),
        });

        setFocus("email");

        return new Promise(() => {});
      }
    },
  });

  const roles = useRoles();

  return (
    <form
      onSubmit={handleSubmit((data) =>
        inviteMemberMutation.mutateAsync({
          data,
          id: userResponse?.data?.data?.currentTeam.id!,
        })
      )}
    >
      <FormSection
        title={t("Invite team member")}
        description={t(
          "Add a new team member to your team, allowing them to collaborate with you."
        )}
        footer={
          <Button type="submit" disabled={isSubmitting} loading={isSubmitting}>
            {t("Invite")}
          </Button>
        }
      >
        <div className="max-w-xl">
          <p className="text-sm text-gray-700">
            {t(
              "Choose a role and provide the email address of the person you would like to invite."
            )}
          </p>
        </div>
        <div className="grid gap-4 lg:grid-cols-3">
          <div className="lg:col-span-2">
            <FieldLabel required htmlFor="role">
              {t("Role")}
            </FieldLabel>
            <Controller
              name="role"
              control={control}
              render={({ field }) => (
                <RadioGroupField
                  label={t("Role")}
                  options={[
                    {
                      name: roles.labels.MEMBER,
                      value: roleEnum.Enum.MEMBER,
                      description: roles.descriptions.MEMBER,
                    },
                    {
                      name: roles.labels.ADMIN,
                      value: roleEnum.Enum.ADMIN,
                      description: roles.descriptions.ADMIN,
                    },
                  ]}
                  {...field}
                />
              )}
            />
            {errors.role && (
              <FieldError>{t("Please select a role")}</FieldError>
            )}
          </div>
          <div className="lg:col-span-2">
            <TextField
              {...register("email")}
              label={t("Email")}
              error={errors.email}
              required
            />
          </div>
        </div>
      </FormSection>
    </form>
  );
};
