import { Context, ResponsePayload } from "types";

export const getTeam =
  ({ apiClient }: Context) =>
  (id: string) =>
    apiClient<
      ResponsePayload<App.Domain.API.OnlineAppointments.Resources.TeamResource>
    >({
      url: `/online-appointment/${id}`,
      method: "GET",
    });

export const getSlots =
  ({ apiClient }: Context) =>
  ({
    id,
    data,
  }: {
    id: string;
    data: App.Domain.API.OnlineAppointments.Resources.RetrieveSlotsResource;
  }) =>
    apiClient<App.Domain.API.OnlineAppointments.Resources.SlotsResource>({
      url: `/online-appointment/${id}/slots`,
      method: "GET",
      params: data,
    });
