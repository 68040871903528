import { RouteSwitch } from "components/RouteSwitch";
import { Route, useRouteMatch } from "react-router-dom";
import { CreateAppointment } from "../domain/create";
import { AppointmentEdit } from "../domain/edit";
import { AppointmentPayment } from "../domain/pay";
import { AppointmentConfirm } from "../domain/confirm";

export const Container = () => {
  const match = useRouteMatch();

  return (
    <RouteSwitch>
      <Route path={match.path + "/pay/:id"}>
        <AppointmentPayment />
      </Route>
      <Route path={match.path + "/edit/:id/:appointmentId"}>
        <AppointmentEdit />
      </Route>
      <Route path={match.path + "/confirm/:id"}>
        <AppointmentConfirm />
      </Route>
      <Route path={match.path + "/:id"}>
        <CreateAppointment />
      </Route>
    </RouteSwitch>
  );
};
